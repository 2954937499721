<app-header2></app-header2>

<div class="row pt-28 pl-3">
    <app-goback></app-goback>
 </div>

<!-- external-html.component.html -->
<div class="px-3 pt-6" [innerHTML]="externalHtml"></div>


<app-footersection></app-footersection>

