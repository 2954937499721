
<div class="container-fluid section3-gradient-style ">
    
    <div class="row py-6">
        <div class="col-md-12">
            <span class="flex  justify-center soulsWedHeadingFont">Choose suitable vendors at your budget</span>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <span class="soulsWedSubheadingFont flex justify-center">Get the best rates from Event Managers, Photographers, Makeup artists & much, much more!</span>
        </div>
    </div>

    <div class="row py-6">

        <div class="col-md-4">
            <div class="flex justify-center">
                <mat-card>
                    <mat-card-content>
                        <img mat-card-image src="assets\images\Main Images\Vendors\img1.jpg">
                        <div>
                            <h2>Wedding Planner,&nbsp;Oceanus Events</h2>
                            <h4>Sydney,Australia</h4>
                            <h4>6 Reviews</h4>
                            <h4><b>>from AUD 150 onwards</b></h4>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
         </div>

         <div class="col-md-4">
            <div class="flex justify-center">
                <mat-card>
                    <mat-card-content>
                        <img mat-card-image src="assets\images\Main Images\Vendors\img2.jpg">
                        <div>
                            <h2>Videographers, &nbsp; Piccaso Studio</h2>
                            <h4>Singapore</h4>
                            <h4>6 Reviews</h4>
                            <h4><b>from SGD 200 onwards</b></h4>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
         </div>

         <div class="col-md-4">
            <div class="flex justify-center">
                <mat-card>
                    <mat-card-content>
                        <img mat-card-image src="assets\images\Main Images\Vendors\img3.jpg">
                        <div>
                            <h2>Decorators,&nbsp;DeAmigos Designers</h2>
                            <h4>Italy</h4>
                            <h4>7 Reviews</h4>
                            <h4><b>from EUR 500 onwards</b></h4>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
         </div>

         
     </div>

 </div>

       

<!--------------------------------------------------------------------------------------->
