import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-deleteconfirmationmodal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './deleteconfirmationmodal.component.html',
  styleUrl: './deleteconfirmationmodal.component.css'
})
export class DeleteconfirmationmodalComponent 
{
  @Output() deleteConfirmed = new EventEmitter<void>();
  isVisible = false;

  openModal() 
  {
    this.isVisible = true;
  }

  closeModal() {
    this.isVisible = false;
  }

  confirmDelete() {
    this.deleteConfirmed.emit();
    this.closeModal();
  }

}
