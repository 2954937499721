import { Component, OnInit } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { ListingSidebarComponent } from './listing-sidebar/listing-sidebar.component';
import { SearchComponent } from '../search/search.component';
import { CommonModule } from '@angular/common';
import { VendorsummarydisplayComponent } from './vendorsummarydisplay/vendorsummarydisplay.component';
import { GobackComponent } from '../goback/goback.component';


@Component({
  selector: 'app-vendormgmt',
  standalone: true,
  imports: [GobackComponent,Header2Component,FootersectionComponent,ListingSidebarComponent,SearchComponent,CommonModule,VendorsummarydisplayComponent],
  templateUrl: './vendormgmt.component.html',
  styleUrl: './vendormgmt.component.css'
})


export class VendormgmtComponent
{

  

}
