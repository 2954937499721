import { Component,OnInit, ViewChild} from '@angular/core';
import { ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';

//--- import project components -----
import { Header2Component } from '../header2/header2.component';
import { ImagesliderComponent} from './imageslider/imageslider.component';
import { VendorslistingComponent } from './vendorslisting/vendorslisting.component';
import { WeddingstorieslistingComponent } from './weddingstorieslisting/weddingstorieslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { HttpClientModule } from '@angular/common/http';
import { FilterDataServiceService } from '../../services/filter-data-service.service';
import { CountryData } from '../../models/datamodels';
import { MasterdataserviceService } from '../../services/masterdataservice.service';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { ImgcarouselComponent } from './imgcarousel/imgcarousel.component';
import { SearchComponent } from '../search/search.component';
import { SearchmobileComponent } from '../searchmobile/searchmobile.component';
import { HotelslistinghomeComponent } from './hotelslistinghome/hotelslistinghome.component';
import { VenueslistinghomeComponent } from './venueslistinghome/venueslistinghome.component';
import { CharterslistinghomeComponent } from './charterslistinghome/charterslistinghome.component';
import { PhotographerslistinghomeComponent } from './photographerslistinghome/photographerslistinghome.component';
import { MakeupartistslistinghomeComponent } from './makeupartistslistinghome/makeupartistslistinghome.component';
import { DecoratorslistinghomeComponent } from './decoratorslistinghome/decoratorslistinghome.component';
import { SakhiserviceComponent } from '../sakhiservice/sakhiservice.component';
import { AccordianComponent } from '../accordian/accordian.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [AccordianComponent,SakhiserviceComponent,CommonModule,DecoratorslistinghomeComponent,MakeupartistslistinghomeComponent,SearchComponent,HttpClientModule,Header2Component,ImagesliderComponent,VenueslistinghomeComponent,HotelslistinghomeComponent,VendorslistingComponent,WeddingstorieslistingComponent,FootersectionComponent,ImgcarouselComponent,SearchmobileComponent,CharterslistinghomeComponent,PhotographerslistinghomeComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})


export class HomeComponent implements OnInit 
{
  @ViewChild('homeContainer', { static: true }) homeContainer?: ElementRef;

  countries:CountryData[]|null=null;
  showSearch:boolean=false;
  searchButtonText:string='Search Vendors';

  constructor(private environmentDataService:EnvironmentDataServiceService,private masterDataService:MasterdataserviceService)
  {

  };

  ngOnInit(): void 
  {
      this.initializeComponent();
  }

  initializeComponent()
  {
    this.masterDataService.getCountries().subscribe
    (data=>
      {
        this.countries=data;

        this.environmentDataService.changeCountryData(this.countries[0]);
  
      }
    );
  }

  handleVisibilityChange() 
  {
    if (this?.homeContainer?.nativeElement.offsetParent !== null) 
      {
        // The component is now visible
        this.initializeComponent();
      }
  }

  enableSearch()
  {
    console.log('Enable Search Clicked');
    this.showSearch=!this.showSearch;

    if(this.showSearch)
      this.searchButtonText="Hide search panel"
    else
      this.searchButtonText="Search Vendors"
    
  }

}
