import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import {MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';
import { MakeupartistslistingComponent } from './makeupartistslisting/makeupartistslisting.component';


@Component({
  selector: 'app-makeupartists',
  standalone: true,
  imports: [GobackComponent,Header2Component,MakeupartistslistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './makeupartists.component.html',
  styleUrl: './makeupartists.component.css'
})

export class MakeupartistsComponent 
{

}
