import { Component,OnInit } from '@angular/core';
import { CategoryData, CountryData, SearchData, VendorSummaryData,VendorID, CurrencyData,ExchangeRateData } from '../../../models/datamodels';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { UserServiceService } from '../../../services/user-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from '../../usermgmt/sign-in/sign-in.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-makeupartistslistinghome',
  standalone: true,
  imports: [MatCardModule,CommonModule,RouterModule],
  templateUrl: './makeupartistslistinghome.component.html',
  styleUrl: './makeupartistslistinghome.component.css'
})
export class MakeupartistslistinghomeComponent 
implements OnInit
{

  vendorsListOriginal:VendorSummaryData[]=[];
  vendorsList:VendorSummaryData[]=[];
  serverpath= environment.middletierhost+'/uploads';  //---serverpath for images----   
  countriesList:CountryData[] =[];  
  currentIndex: number = 0;    
  favourites:VendorID[]=[];  
  iduser=0;  

  fxList:ExchangeRateData[]=[];
  selectedCurrency:CurrencyData|null=null;



  constructor(private router:Router,public dialog: MatDialog,private vendorDataService:VendorDataServiceService , private environmentDataService : EnvironmentDataServiceService,private masterDataService:MasterdataserviceService,private userDataService:UserServiceService){}

  ngOnInit(): void 
  {
      this.environmentDataService.currentCurrency.subscribe(data=>
      {
            this.selectedCurrency=data;
  
            this.masterDataService.getExchangeRates().subscribe(data=>
            {
              this.fxList=data;
              console.log('Exchange rates fetched :',this.fxList);
            })
        });
        
        this.vendorDataService.getMakeupArtists().subscribe
        (data=>
        {
            this.vendorsListOriginal=data;       
            console.log('Make-up artists list revieved with number ' , data.length);
            console.log('Make-up artists list copied with number ' , this.vendorsListOriginal.length);

            this.updateDisplayedVendors();
      
        }) 

        this.masterDataService.getCountries().subscribe
        (
          data=>
            {
              this.countriesList=data;
            }
        )
  
        //--------- get user favourites -------------
  
        this.environmentDataService.currentUser.subscribe(user=>
        {
            if (user)
            {
                this.iduser=user.iduser;
  
                this.userDataService.getUserFavourites(this.iduser).subscribe
                (data=>
                 {
                    this.favourites=data;
                })
            }
         })
  }


  updateDisplayedVendors(): void 
  {
    //this.vendorsList = this.vendorsListOriginal.slice(this.currentIndex, this.currentIndex + 3);
    this.vendorsList = this.vendorsListOriginal.slice(0,3);
  }

  getCountryName(idcountry: number): string 
  {
    const country = this.countriesList.find(c => c.idcountry === idcountry);
    return country ? country.countryName : '';
  }

  nextSlide(): void 
  {
    if (this.currentIndex + 3 < this.vendorsListOriginal.length) 
    {
      this.currentIndex += 3;
      this.updateDisplayedVendors();
    }
  }

  previousSlide(): void 
  {
    if (this.currentIndex - 3 >= 0) 
    {
      this.currentIndex -= 3;
      this.updateDisplayedVendors();
    }
  }

  //============== Function to updateselected country ============================
  onVendorSelect(selected: VendorSummaryData): void 
  {
  
    this.vendorDataService.changeData(selected);
    
    this.environmentDataService.changeCountryData(this.countriesList.find(c=>c.idcountry=selected.idcountry));
    
        
       
  }

  getStars(rating:string):string
  {
    if (rating=='5 star')
      return '*****'
      else if (rating=='4 star')
      return '****'
      else if (rating=='3 star')
        return '***'
      else if (rating=='2 star')
        return '**'
      else if (rating=='1 star')
        return '*'
      else
        return '';
  }

  toggleFavourites(idvendor:number)
  {


    //-------- Ask to sign in if not signed in --------------------

    this.environmentDataService.currentUser.subscribe(user=>
      {
        if (user)
        {
            this.iduser=user.iduser;
        }
        else
        {
          //-------- If not logged in, ask for user to log-in ------------------
          const dialogRef = this.dialog.open(SignInComponent, 
          {
            width: '300px'
          });
      
          dialogRef.afterClosed().subscribe(result => 
          {
            this.router.navigate(['/home']);
            console.log('The dialog was closed');
          });

        }
      }
    )

    if(this.iduser>0)
    {
        //--------------- Add or remove user favourites as applicable -------------------
    if(!this.favourites.find(c=>c.idvendor==idvendor))
      {
        //this.favourites.push(idvendor);
        this.userDataService.addUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
      else
      {
        //this.favourites=this.favourites.filter(n=>n!=idvendor)
        //this.favourites.push(idvendor);
        this.userDataService.deleteUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
    }

    

    
  }

  isFavourite(idvendor:number):boolean
  {
    if(this.favourites.find(c=>c.idvendor==idvendor))
      return true;
    else
    return false;
  }

  convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
     // console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

        const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


      //    console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

       //     console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }
}

