import { Component,OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { TransactionData} from '../../../models/datamodels';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from '../../usermgmt/sign-in/sign-in.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-transactionslisting',
  standalone: true,
  imports: [MatCardModule,CommonModule,RouterModule],
  templateUrl: './transactionslisting.component.html',
  styleUrl: './transactionslisting.component.css'
})
export class TransactionslistingComponent implements OnInit
{

  transactionsListOriginal:TransactionData[]=[];
  transactionsList:TransactionData[]=[];

  constructor(private vendordataservice:VendorDataServiceService,private masterDataService:MasterdataserviceService,private router:Router ) {}

  ngOnInit(): void 
  {
      this.vendordataservice.getTransactions().subscribe(data=>
        {
          this.transactionsListOriginal=data;
          this.updateDisplayedVendors();
        }

      )
  }

  updateDisplayedVendors(): void 
  {
    this.transactionsList=this.transactionsListOriginal;
  }


}
