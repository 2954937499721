import { Component } from '@angular/core';
import { Header2Component } from '../../header2/header2.component';
import { FootersectionComponent } from '../../footersection/footersection.component';
import {MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../../goback/goback.component';
import {ManagevendorcalendardetailsComponent} from '../managevendorcalendardetails/managevendorcalendardetails.component'

@Component({
  selector: 'app-managevendorcalendar',
  standalone: true,
  imports: [GobackComponent,Header2Component,FootersectionComponent,MatCardModule,ManagevendorcalendardetailsComponent],
  templateUrl: './managevendorcalendar.component.html',
  styleUrl: './managevendorcalendar.component.css'
})

export class ManagevendorcalendarComponent 
{

}
