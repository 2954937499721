import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule,Router,NavigationEnd } from '@angular/router';
import { FilterDataServiceService} from '../../services/filter-data-service.service';
import { CategoryData, CountryData, SearchData } from '../../models/datamodels';
import { MasterdataserviceService } from '../../services/masterdataservice.service';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule,FormsModule,RouterModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.css'
})


export class SearchComponent implements OnInit
{

  
  countries:CountryData[]=[];
  categories:CategoryData[]=[];

  
  selectedCountry: CountryData|null=null;
  selectedCategory: CategoryData|null=null;
  startDate: Date|null= null;
  endDate: Date|null = null;
  guests:number|null=0;

  errorMessage:string="";


  constructor(private router: Router,private filterDataService:FilterDataServiceService,private masterDataService:MasterdataserviceService,private environmentDataService:EnvironmentDataServiceService) 
  {}
  
  

  ngOnInit(): void 
  {
    
    //------- Get master list of countries and set default country in drop down --------------
    
    
    this.masterDataService.getCountries().subscribe
    (data=>
      {
        this.countries=data;

     
        if(!this.selectedCountry)
        {
          this.environmentDataService.changeCountryData(this.countries[0]);
        }

        //console.log(this.countries);
        if(this.selectedCountry)
        {
            this.environmentDataService.changeCountryData(this.selectedCountry);
        } 
     
        this.environmentDataService.currentCountry.subscribe(data=>
        {
          if(data)
          {
             //this.selectedCategory=data;
             this.selectedCountry=<CountryData>this.countries.find(c=>c.idcountry===data.idcountry);
           }
         
       })
        
      }
    );
    
   
    
      
     //------- Get master list of categories and set default category in drop down --------------  
    //this.categories=this.masterDataService.getCategories();
    this.masterDataService.getCategories().subscribe
    (data=>
      {
        this.categories=data;

        
        if(!this.selectedCategory)
        {
          this.environmentDataService.changeCategoryData(this.categories[0]);
        }

        if(this.selectedCategory)
          {
              this.environmentDataService.changeCategoryData(this.selectedCategory);
          } 

        this.environmentDataService.currentCategory.subscribe(data=>
          {
            if(data)
              {
                //this.selectedCategory=data;
                this.selectedCategory=<CategoryData>this.categories.find(c=>c.idcategory===data.idcategory);
              }
            
          })
        
      }
     
    );

  
   

    
    
    this.filterDataService.currentData.subscribe(data=>
    { 
      console.log('Filter data refresh recieved')
        
        if(data)
        {
          console.log('Filter data refresh recieved , category is :',data?.category?.categoryName);

            this.selectedCountry=data?.country;
            this.selectedCategory=data?.category;
            this.startDate=data?.startDate;
            this.endDate=data?.endDate;
            this.guests=data?.guests;

            console.log('Filter data refresh updated , category is :',this?.selectedCategory?.categoryName);

        }
    })


    
  
    
  }

 
  search() 
  {

    this.errorMessage="";

    console.log('Searching for:', this.selectedCountry, this.selectedCategory, this.startDate, this.endDate);
    // Add search logic here

    if (!this.selectedCountry)
    {
       this.errorMessage="Please select country";
       return;
    }

    if (!this.selectedCategory)
    {
        this.errorMessage="Please select category";
        return;
    }

    if ((!this.startDate) ||(!this.endDate))
    {
      this.errorMessage="Please select start and end dates";
      return;
    }

    const todayDate:Date = new Date();
    console.log(todayDate);

    if(this.startDate<todayDate)
      {
        this.errorMessage="Start date should be a future date";
        return;
      }

    if(this.startDate > this.endDate)
      {
        this.errorMessage="Invalid Dates. End date should be greater than or equal to start date";
        return;
      }

    if((!this.guests)||(this.guests<=0))
      {
        this.errorMessage="Please enter number of guests !!!";
        return;
      }
      
  

    const data : SearchData=
    {
      country: this.selectedCountry,
      category: this.selectedCategory,
      startDate: this.startDate,
      endDate:this.endDate,
      guests:this.guests

    };

    
    console.log('data copied is:', data.country,data.category,data.startDate,data.endDate),data.guests;

     this.filterDataService.changeData(data);

     // Navigate to /vendor after operations
    this.router.navigate(['/vendor']);

  }

  //============== Function to updateselected country ============================
  onCountrySelect(selected: CountryData): void 
  {
    this.selectedCountry = selected;

    console.log('Selected Country:', this.selectedCountry.countryName,this.selectedCountry.countryCode,this.selectedCountry.idcountry);
    // You can also call other functions here or perform further actions based on the selected country

    this.environmentDataService.changeCountryData(selected);
  }

    //============== Function to compare 2 country objects ============================
    compareCountries(c1: CountryData, c2: CountryData): boolean 
  {
    return c1 && c2 ? c1.idcountry === c2.idcountry : c1 === c2;
  }

  
  //============== Function to updateselected category ============================
  onCategorySelect(selected: CategoryData): void 
  {
    this.selectedCategory = selected;
    this.environmentDataService.changeCategoryData(selected);

    //console.log('Selected Country:', this.selectedCountry.countryName,this.selectedCountry.countryCode,this.selectedCountry.idcountry);
    // You can also call other functions here or perform further actions based on the selected country
  }

    //============== Function to compare 2 country objects ============================
   compareCategories(c1: CategoryData, c2: CategoryData): boolean 
  {
    return c1 && c2 ? c1.idcategory === c2.idcategory : c1 === c2;
  }

  onStartDateChange()
  {
    // If the selected end date is before the new start date, reset the end date
    if (this.endDate && this.startDate && this.endDate < this.startDate) 
    {
      this.endDate = null;

    }
  }

}
