
<div class="container" *ngIf="isVisible">
  <div class="row">
    <div class="col-md-10">
      <div class="modal" >
        <div class="modal-content">
          <div class="modal-header">
            <h5>Confirm Deletion</h5>
            <span class="close" (click)="closeModal()">&times;</span>
          </div>
          <div class="modal-body">
            Are you sure you want to delete this vendor? Please note this deletion cannot be reversed later
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
            <button class="btn btn-danger" (click)="confirmDelete()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  
  
  
