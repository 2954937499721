import { Component,OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { RazorpayErrorResponse,RazorpayPaymentResponse } from '../../models/datamodels';
import { VendorDataServiceService } from '../../services/vendor-data-service.service';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from '../usermgmt/sign-in/sign-in.component';
import { Router } from '@angular/router';
import { GobackComponent } from '../goback/goback.component';

declare var Razorpay:any;

@Component({
  selector: 'app-sakhiservice',
  standalone: true,
  imports: [MatCardModule,CommonModule,DecimalPipe,SignInComponent,GobackComponent],
  templateUrl: './sakhiservice.component.html',
  styleUrl: './sakhiservice.component.css',
  providers: [DecimalPipe]
})

export class SakhiserviceComponent implements OnInit
{

  titleText:string='Sakhi Service';
  currency:string='INR';
  idvendor:number=0;
  iduser:number=0;
  vendorname:string='';
  username:string='';
  useremail:string='';
  transactiondate:string='';
  finalamount:number=0;
  isOnPage:boolean=false;



  constructor(private router:Router,public dialog: MatDialog,private vendorDataService:VendorDataServiceService,private decimalPipe: DecimalPipe,private environmentDataService:EnvironmentDataServiceService)
  {}

  ngOnInit(): void 
  {
      
  }

  makePayment(amount:number)
  {

      this.environmentDataService.currentUser.subscribe(user=>
        {
          if (user)
          {
              this.iduser=user.iduser;
              this.username=user.username;
              this.useremail=user.email;

              this.isOnPage=true;

              this.invokeRazorPay(amount);
          }
          else
          {
            if(!this.isOnPage)
            {
               //alert('Log in dialog invoked from ngoninit');
              //-------- If not logged in, ask for user to log-in ------------------
              const dialogRef = this.dialog.open(SignInComponent, 
              {
                width: '300px'
              });
          
              dialogRef.afterClosed().subscribe(result => 
              {
                this.router.navigate(['/home']);
                console.log('The dialog was closed');
              });

            }
            
          }
        })
          
    }

    invokeRazorPay(amount:number)
    {

      if(amount>0)
        {
  
            this.finalamount=amount*100*1.18;
            this.formatNumber();
  
            const successCallBack = (paymentId: RazorpayPaymentResponse)=>
            {
                console.log('Payment successful with payment id :',paymentId.razorpay_payment_id);
                this.transactiondate=new Date().toISOString();
  
              this.vendorDataService.postTransaction(
                  {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.finalamount/100,vendorname:'Sakhi Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:this.currency,bookingstart:'',bookingend:''
                    
                  });
                
            }
  
            const RazorpayOptions=
            {
              description: "Soulswed Payment",
              currency:this.currency,
              amount:this.finalamount,
              name:'SoulsWed',
              key:'rzp_test_cpXOU6uqzQHCc6',
              //key:'rzp_live_dxvVlB5dXdaJNO',    // live key
              image:'../../assets/images/Logo/Faveicon.png',
           
              prefill:
                {
                    name:'',
                    email:'',
                    phone:''
                },
              theme:
                {
                  color:'#ebe834'   //#f37254'
                },
              handler: (response: RazorpayPaymentResponse) => 
                {
                  console.log('Payment success event:', response);
                  successCallBack(response);
                },
                
              modal:
                {
                  ondismiss:()=>
                    {
                      console.log('dismissed')
                    }
                }
            }
    
            
    
            const failureCallBack = (error: RazorpayErrorResponse) => 
              {
                  console.log('Payment failed');
                 // console.log(error.error);
              };
    
            ///Razorpay.open(RazorpayOptions,successCallBack,failureCallBack);
            console.log('Called Razor pay for payment ....')
  
            const rzp = new Razorpay(RazorpayOptions);
  
         /*   rzp.on('payment.success', (response: RazorpayPaymentResponse) => {
              console.log('Payment success event:', response);
              successCallBack(response);
            });*/
  
            rzp.on('payment.error', (response: RazorpayErrorResponse) => {
              console.log('Payment error event:', response);
              failureCallBack(response);
            });
  
            rzp.open();
  
          
        }
        else
        {
          alert('No amount to pay')
        }
      }


    formatNumber() 
    {
      this.finalamount = parseFloat(this.decimalPipe.transform(this.finalamount, '1.0-0')!.replace(/,/g, ''));
    }

}
