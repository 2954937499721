import { Component,OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserServiceService } from '../../../services/user-service.service';
import { NgModule,ViewChild,AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { HttpErrorResponse } from '@angular/common/http';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { RouterModule,Router,NavigationEnd } from '@angular/router';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { CommonModule } from '@angular/common';
import { User } from '@angular/fire/auth';
import { AuthServiceService } from '../../../services/auth-service.service';


@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [MatInputModule,MatButtonModule, MatFormFieldModule,MatDialogModule,MatIconModule,FormsModule,RouterModule,CommonModule],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.css'
})

export class SignInComponent implements OnInit
{
  username: string = '';
  password: string = '';
  usertype: string=''
  errorMessage:string="";
  showPassword: boolean = false;

 
  loggedIn: boolean=false;


  usertypeList:string[]=['Customer','Vendor','Admin'];
  user: User | null = null;

 @ViewChild(AlertDialogComponent) alertDialog: AlertDialogComponent= new AlertDialogComponent();

  constructor(private authService: AuthServiceService,private userService:UserServiceService, public dialogRef: MatDialogRef<SignInComponent>,private environmentDataService:EnvironmentDataServiceService,private router:Router)
  {

  }

  ngOnInit(): void 
  {
      if(this.usertypeList.length>0)
      {
          this.usertype=this.usertypeList[0];
      }

    
  }


  
  onSubmit() 
  {
    this.userService.login(this.username, this.password,this.usertype).subscribe(
    {
      next:(response)=>
        {
          if(response.message=='Login Successful')
          {
              this.errorMessage="";
              this.environmentDataService.changeUserData(response.data);
              this.loggedIn=true;
              this.router.navigate(['/home']);
              this.dialogRef.close();
          }
          else
          {
            this.errorMessage='Invalid Credentials';
            this.clearform();
           
          }
        },
        error: (error: HttpErrorResponse) => 
        {
          console.error('Error logging in:', error);
          alert('An error occurred. Please try again later.');
        }
    }
    )
   
    
    
  }

  clearform()
  {
    this.username="";
    this.password="";
   
  }

  signInWithGoogle() 
  {
    this.authService.signInWithGoogle().then(result => 
    {
      console.log(result);
      this.user = result.user;

      //----------- See if existing user ------------
      this.userService.signInWithGoogle(this.user.displayName!, this.user.email!,'').subscribe(
        {
          next:(response)=>
            {
              if(response.message=='Login Successful')
              {
                  this.errorMessage="";
                  this.environmentDataService.changeUserData(response.data);
                  this.loggedIn=true;
                  this.router.navigate(['/home']);
                  this.dialogRef.close();
              }
              else
              {
                this.errorMessage='Login Failed';
                this.clearform();
               
              }
            },
            error: (error: HttpErrorResponse) => 
            {
              console.error('Error logging in:', error);
              alert('An error occurred. Please try again later.');
            }
        }
        )


     // this.username = this.user.displayName || '';
      //alert('Logged in as :'+this.username+" : "+this.user.email);
      this.dialogRef.close();


      // Handle successful sign-in here
    }).catch(error => 
      {
      console.error('Error during Google sign-in:', error);
      this.errorMessage = error.message;
    });

    
  }

  signInWithFacebook() 
  {
    this.authService.signInWithFacebook().then(result => 
      {
        console.log(result);
        this.user = result.user;
  
        //----------- See if existing user ------------
        this.userService.signInWithFacebook(this.user.displayName!, this.user.email!,'').subscribe(
          {
            next:(response)=>
              {
                if(response.message=='Login Successful')
                {
                    this.errorMessage="";
                    this.environmentDataService.changeUserData(response.data);
                    this.loggedIn=true;
                    this.router.navigate(['/home']);
                    this.dialogRef.close();
                }
                else
                {
                  this.errorMessage='Login Failed';
                  this.clearform();
                 
                }
              },
              error: (error: HttpErrorResponse) => 
              {
                console.error('Error logging in:', error);
                alert('An error occurred. Please try again later.');
              }
          }
          )
  
  
        this.username = this.user.displayName || '';
        alert('Logged in as :'+this.username+" : "+this.user.email);
        this.dialogRef.close();
  
  
        // Handle successful sign-in here
      }).catch(error => 
        {
        console.error('Error during Facebook sign-in:', error);
        this.errorMessage = error.message;
      });
  }

  signInWithWhatsApp() {
    this.userService.signInWithWhatsApp();
  }

  signInWithSmsOtp() {
    this.userService.signInWithSmsOtp();
  }

  closeDialog() 
  {
    this.dialogRef.close();
  }

  registerUser()
  {
    this.router.navigate(['/register']);
    this.dialogRef.close();
  }

  forgotPassword()
  {
    this.router.navigate(['/forgot-password']);
    this.dialogRef.close();
  }

  togglePasswordVisibility() 
  {
    this.showPassword=!this.showPassword;
  }

}
