import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { CatererslistingComponent } from './catererslisting/catererslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-caterers',
  standalone: true,
  imports: [GobackComponent,Header2Component,CatererslistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './caterers.component.html',
  styleUrl: './caterers.component.css'
})
export class CaterersComponent {

}
