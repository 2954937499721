import { Component } from '@angular/core';

//----- import angular material -------
import{MatCardModule} from '@angular/material/card'

@Component({
  selector: 'app-weddingstorieslisting',
  standalone: true,
  imports: [MatCardModule],
  templateUrl: './weddingstorieslisting.component.html',
  styleUrl: './weddingstorieslisting.component.css'
})
export class WeddingstorieslistingComponent {

}
