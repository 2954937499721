import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { UserfavouriteslistingComponent } from './userfavouriteslisting/userfavouriteslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import{MatCardModule} from '@angular/material/card';
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-userfavourites',
  standalone: true,
  imports: [Header2Component,UserfavouriteslistingComponent, FootersectionComponent,MatCardModule,GobackComponent],
  templateUrl: './userfavourites.component.html',
  styleUrl: './userfavourites.component.css'
})
export class UserfavouritesComponent {

}
