<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

</head>

<!-- For large screens -->
<div class="d-none d-md-block row flex justify-center items-bottom footer-gradient-style">  

    <div class="row pt-6">

        
        <div class="col-md-1"></div>
        
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12 pb-3 d-flex justify-center soulsWedFooterFont"> <b>Categories</b></div>
                <div class="col-md-6 clickable soulsWedFooterFont text-center" (click)="goToHotels()"> Rooms</div>
                <div class="col-md-6 clickable soulsWedFooterFont text-center" (click)="goToPhotograhers()"> Photographers</div>
                <div class="col-md-6 clickable pt-1 soulsWedFooterFont text-center" (click)="goToVenues()"> Venues</div>
                <div class="col-md-6 clickable pt-1 soulsWedFooterFont text-center" (click)="goToDecorators()"> Decorators</div>
                <div class="col-md-6 clickable pt-1 soulsWedFooterFont text-center" (click)="goToPlanners()"> Planners</div>
                <div class="col-md-6 clickable pt-1 soulsWedFooterFont text-center" (click)="goToMakeupArtists()"> Makeup Artists  </div>
                <div class="col-md-6 clickable pt-1 soulsWedFooterFont text-center" (click)="goToCharters()"> Charters</div>
            </div>
        </div>


        <div class="col-md-2">
                <div class="col-md-12 pb-3 soulsWedFooterFont text-center"><b> Services</b></div>
                <div class="col-md-12 clickable soulsWedFooterFont text-center" (click)="goToSakhiService()"> Sakhi Service</div>
               <!-- <div class="col-md-12 pt-1 clickable soulsWedFooterFont text-center" (click)="registerVendor()"> Register as Vendor</div>-->
                <div class="col-md-12 pt-1 clickable soulsWedFooterFont text-center" (click)="goToUserFavourites()"> View Favourites</div>
                
        </div>


        <div class="col-md-2">
            <div class="col-md-12 pb-3 soulsWedFooterFont text-center" ><b> Documents</b></div>
            <div class="col-md-12 clickable soulsWedFooterFont  text-center" (click)='gotoTermsandConditions()'> Terms & Conditions</div>
            <div class="col-md-12 pt-1 clickable soulsWedFooterFont text-center" (click)='gotoPrivacyPolicy()'> Privacy Policy</div>
            <div class="col-md-12 pt-1 clickable soulsWedFooterFont text-center" (click)='gotoRefundPolicy()'> Refund Policy</div> 
            <div class="col-md-12 pt-1 clickable soulsWedFooterFont text-center" (click)='gotoUserdeletionPolicy()'> User Deletion Policy</div>      
        </div>

        
       
        <div class="col-md-2">
            <div class="col-md-12 pb-3 text-center" > <b>Contact us</b></div>            
            <div class="col-md-12 flex justify-center">
                <a href="mailto:info@soulswed.com"><i class="fas fa-envelope fa-lg email-icon text-center"></i></a>  
            </div>
            <div class="col-md-12 pt-4 flex justify-center">
                <a href="https://wa.me/919445266640?text=Hello%20there!%20I%20would%20like%20to%20inquire%20about..."><b><i class="text-center fab fa-whatsapp fa-lg whatsapp-icon"></i></b> </a>  
            </div>
        </div>
    </div>

    
    <div class="col-md-1"></div>

    <div class="row pt-6">
        <div class="col-md-2"></div>
        <div class="col-md-2"></div>
        <div class="col-md-1"></div>
        <div class="col-md-2">
            <div class="flex justify-center">
                <p>Copyright &#64; 2024 by SoulsWed</p>  
            </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2">
            
        </div>
    </div>

</div>


<!-- For smaller screens -->
<div class="d-block d-md-none row flex justify-center items-bottom footer-gradient-style">  

    <div class="row pt-4">
        <div class=col-1></div>
        <div class="col-7">
            <div class="row clickable soulsWedFooterFont" (click)='gotoPrivacyPolicy()'> Privacy Policy</div>
            <div class="row clickable soulsWedFooterFont pt-1" (click)='gotoRefundPolicy()'> Refund Policy</div>
            <div class="row clickable soulsWedFooterFont pt-1" (click)='gotoTermsandConditions()'> Terms & Conditions</div>
            <div class="row clickable soulsWedFooterFont pt-1" (click)='gotoUserdeletionPolicy()'> User Deletion Policy</div>
        </div>

        <div class=col-2></div>
        <div class="col-2">
            <div class="row">
                <a href="mailto:info@soulswed.com"><i class="fas fa-envelope fa-2x email-icon"></i></a>
            </div>
            <div class="row pt-3">
                <a href="https://wa.me/919445266640?text=Hello%20there!%20I%20would%20like%20to%20inquire%20about..."><i class="fab fa-whatsapp fa-2x whatsapp-icon"></i> </a>  
            </div>
            
        </div>

      
    </div>




    <div class="row pt-6">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div class="flex justify-center soulsWedFooterFont">
                <p>Copyright &#64; 2024 by SoulsWed</p>  
            </div>
        </div>
        <div class="col-md-2"> </div>
    </div>

</div>

  


