<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
</head>


<!-- For larger screens-->
<div class="d-none d-md-block">

    <div class="container-fluid negative-margin-top">

        <div class="row">
            <div class="col-md-1">
                <div class="row pt-3 pl-3">
                    <app-goback></app-goback>
                 </div>

                

            </div>
            
            <div class="col-md-10 pt-3">
                <span class="flex justify-center soulsWedHeadingFont">Receipt</span>
            </div>
           

        </div>
    
    
        <div class="row position-relative pt-3" id="content-to-print" >        

                        
            <div class=" d-none d-md-block">
                <div class="row" >
                    <div class="col-md-3"></div>

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6 border-1  py-2">Receipt No</div>
                            <div class="col-md-6 border-1   py-2">{{currentTransaction?.idtransaction}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 border-1  py-2">Vendor Booked</div>
                            <div class="col-md-6 border-1  py-2">{{currentTransaction?.vendorname}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 border-1 py-2">Amount</div>
                            <div class="col-md-6 border-1 py-2">{{currentTransaction?.currency}}&nbsp;{{currentTransaction?.amount|number:'1.0-0'}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 border-1 py-2">Customer Name</div>
                            <div class="col-md-6 border-1  py-2">{{currentTransaction?.username}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 border-1 py-2">Booking Start Date</div>
                            <div class="col-md-6 border-1  py-2">{{begdate}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 border-1 py-2">Booking End Date</div>
                            <div class="col-md-6 border-1  py-2">{{enddate}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 border-1 py-2">Customer Email</div>
                            <div class="col-md-6 border-1 py-2">{{currentTransaction?.useremail}}</div>
                        </div>

                       
                        <div class="row">
                            <div class="col-md-6 border-1 py-2">Transaction Date</div>
                            <div class="col-md-6 border-1  py-2">{{transactiondate}}</div>
                        </div>

                       

                        <!--
                        <div class="row">
                            <div class="col-md-6 border-2 py-2">Transaction Date and Time</div>
                            <div class="col-md-6 border-2 py-2">{{currentTransaction?.transactiondate}}</div>
                        </div> 
                        -->

                        <div class="row py-3">
                            <p>A copy of this receipt will be sent to the above email id.</p>
                        </div>

                        

                    </div>
                    
                    <div class="col-md-3"></div>
                   
                </div>
             </div>
         </div>



        
    </div>

</div>

<!-- For smaller screens-->
<div class="d-block d-md-none">

    <div class="container-fluid negative-margin-top">

        <div class="row">
            <div class="col-1">
                <div class="row pt-3 pl-3">
                    <app-goback></app-goback>
                 </div>

                

            </div>
            
            <div class="col-10 pt-3">
                <span class="flex justify-center soulsWedHeadingFont">Receipt</span>
            </div>
           

        </div>
    
    
        <div class="row position-relative pt-3" id="content-to-print" >        

                        
            <div class=" d-block d-md-none">
                <div class="row" >
                   
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 border-1  py-2">Receipt No</div>
                            <div class="col-6 border-1   py-2">{{currentTransaction?.idtransaction}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 border-1  py-2">Vendor Booked</div>
                            <div class="col-6 border-1  py-2">{{currentTransaction?.vendorname}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 border-1 py-2">Amount</div>
                            <div class="col-6 border-1 py-2">{{currentTransaction?.currency}}&nbsp;{{currentTransaction?.amount|number:'1.0-0'}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 border-1 py-2">Booking Start Date</div>
                            <div class="col-6 border-1  py-2">{{begdate}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 border-1 py-2">Booking End Date</div>
                            <div class="col-6 border-1  py-2">{{enddate}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 border-1 py-2">Customer Name</div>
                            <div class="col-6 border-1  py-2">{{currentTransaction?.username}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 border-1 py-2">Customer Email</div>
                            <div class="col-6 border-1 py-2">{{currentTransaction?.useremail}}</div>
                        </div>

                        
                        <div class="row">
                            <div class="col-6 border-1 py-2">Transaction Date</div>
                            <div class="col-6 border-1  py-2">{{transactiondate}}</div>
                        </div>

                        

                        <!--
                        <div class="row">
                            <div class="col-md-6 border-2 py-2">Transaction Date and Time</div>
                            <div class="col-md-6 border-2 py-2">{{currentTransaction?.transactiondate}}</div>
                        </div> 
                        -->

                        <div class="row py-3">
                            <p>A copy of this receipt will be sent to the above email id.</p>
                        </div>

                        

                    </div>
                    
                    <div class="col-md-3"></div>
                   
                </div>
             </div>
         </div>



        
    </div>

</div>




        

    


    



     

    


       
