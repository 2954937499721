import { Injectable } from '@angular/core';
import { Auth, FacebookAuthProvider, GoogleAuthProvider, signInWithPopup, UserCredential } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})

export class AuthServiceService 
{

  constructor(private auth: Auth) {}

  signInWithGoogle(): Promise<UserCredential> 
  {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(this.auth, provider);
  }

  signInWithFacebook(): Promise<UserCredential> 
  {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(this.auth, provider);
  }

}
