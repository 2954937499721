<div class="background-gradient-style" #homeContainer (visibilityChange)="handleVisibilityChange()">
    <app-header2></app-header2>

    

   

    <div class="d-block d-md-none pt-14 pb-4 ">


        <div class="row ml-24 pt-2 mt-4">
          <div class="col-12 flex justify-center">
              <button class="button-style-4" (click)="enableSearch()" >{{searchButtonText}}&nbsp;&nbsp;<i class="fas fa-search light-icon"></i></button>
          </div>
        </div>
        
        <div *ngIf="showSearch">
          <app-searchmobile></app-searchmobile>
        </div>
        
      </div>

      <app-imageslider></app-imageslider>

    
  <!-- Venues listing for home page ----------------------->

  <div class="row">
    <div class="d-block d-md-none negative-margin-top-venues">
      <!--  <app-searchmobile></app-searchmobile> -->
        <app-venueslistinghome></app-venueslistinghome>
      </div>
    
     <div class="d-none d-md-block negative-margin-top">
      <app-venueslistinghome></app-venueslistinghome>
     </div>
  </div>

    


      <!-- Rooms listing for home page ---------------------

  <div class="row pt-28">
    <div class="d-block d-md-none negative-margin-top-search">
      
        <app-hotelslistinghome></app-hotelslistinghome>
      </div>
    
     <div class="d-none d-md-block negative-margin-top">
      <app-hotelslistinghome></app-hotelslistinghome>
     </div>
  </div> 
  -->

    <!-- Charters listing for home page ---------------------

    <div class="row pt-28">
      <div class="d-block d-md-none negative-margin-top-search">
        
          <app-charterslistinghome></app-charterslistinghome>
        </div>
      
       <div class="d-none d-md-block negative-margin-top">
        <app-charterslistinghome></app-charterslistinghome>
       </div>
    </div>

  -->

    <!-- Photographers listing for home page ----------------------->

    <div class="row pt-28">
      <div class="d-block d-md-none negative-margin-top-search">
        <!--  <app-searchmobile></app-searchmobile> -->
          <app-photographerslistinghome></app-photographerslistinghome>
        </div>
      
       <div class="d-none d-md-block negative-margin-top">
        <app-photographerslistinghome></app-photographerslistinghome>
       </div>
    </div>

      <!-- Makeup artists listing for home page ----------------------->

      <div class="row pt-28">
        <div class="d-block d-md-none negative-margin-top-search">
          <!--  <app-searchmobile></app-searchmobile> -->
            <app-makeupartistslistinghome></app-makeupartistslistinghome>
          </div>
        
         <div class="d-none d-md-block negative-margin-top">
          <app-makeupartistslistinghome></app-makeupartistslistinghome>
         </div>
      </div>


      <!-- Decorators listing for home page ----------------------->

      <div class="row pt-28">
        <div class="d-block d-md-none negative-margin-top-search">
          <!--  <app-searchmobile></app-searchmobile> -->
            <app-decoratorslistinghome></app-decoratorslistinghome>
          </div>
        
         <div class="d-none d-md-block negative-margin-top">
          <app-decoratorslistinghome></app-decoratorslistinghome>
         </div>
      </div>

      <!-- Accordian for home page ---------------------

      <div class="row pt-28">
        <div class="d-block d-md-none negative-margin-top-search">
    
            <app-accordian></app-accordian>
          </div>
        
         <div class="d-none d-md-block negative-margin-top">
          <app-accordian></app-accordian>
         </div>
      </div>

-->
      



      <!-- Sakhi service for home page ----------------------->

      <div class="row pt-28">
        <div class="d-block d-md-none negative-margin-top-search">
          <!--  <app-searchmobile></app-searchmobile> -->
            <app-sakhiservice></app-sakhiservice>
          </div>
        
         <div class="d-none d-md-block negative-margin-top">
          <app-sakhiservice></app-sakhiservice>
         </div>
      </div>




      



    



    

 

    

 
  

<!--    <app-weddingvenueslisting></app-weddingvenueslisting> -->

 <!--   <app-vendorslisting></app-vendorslisting> -->

    <app-footersection></app-footersection>

</div>

