import { Component,OnInit } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { UserfavouriteslistingComponent } from '../userfavourites/userfavouriteslisting/userfavouriteslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { UserslistingComponent } from '../usermgmt/userslisting/userslisting.component';
import { Router } from '@angular/router';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';

@Component({
  selector: 'app-manageusers',
  standalone: true,
  imports: [Header2Component,UserfavouriteslistingComponent, FootersectionComponent,UserslistingComponent],
  templateUrl: './manageusers.component.html',
  styleUrl: './manageusers.component.css'
})

export class ManageusersComponent implements OnInit
{
  constructor(private router:Router,private envDataService:EnvironmentDataServiceService){}

  ngOnInit(): void 
  {
      this.envDataService.currentUser.subscribe(data=>
        {
            if(data?.usertype != "Admin")
            {
              this.router.navigate(['/home']);
            }
        })
  }

}
