<div id="desktop-carousel" class="d-none d-md-block carousel slide pl-3 pr-1 pt-2" data-bs-ride="carousel" data-bs-pause="hover">  

  <div class="carousel-inner " style="margin-top: -30px;">

    <div class="carousel-item carousel-item-desktop active ">
      <img class="d-block w-100 pt-14 img-fluid img-enhanced " src="../../assets/images/Main Images/Slider Images/img1.jpg" alt="First slide" >

     
      <div class="carousel-caption d-flex justify-content-center align-items-center h-100 d-none d-md-none">
        <div><!-- class=" bg-slate-800 bg-opacity-35">-->
          
          <div class="row">
            
            <div class="col-md-12 flex justify-center py-2 d-none d-md-block">            
              <span class="text-white text-4xl font-sans px-10">Create your Dream Wedding</span>
            </div>
          </div>

          

          <div class="col-md-12 flex justify-center py-2 d-none d-md-block">
            <span class="text-white text-2xl font-sans px-10">Because your special moments deserve the best ...</span>
          </div>

           <div class="col-md-12 flex justify-center py-2">
            <app-search></app-search>
          </div>

        </div>

      </div> 

      
      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg">
         
          <path fill="rgb(245,191,66)" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
          
        </svg>
      </div>
     
      
      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg2">
          
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
        </svg>
      </div> 

      <div class="bottom-curve">
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg3">
         
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
        </svg>
      </div>

      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg4">
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
        </svg>
      </div> 

      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg5">
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
        </svg>
      </div> 

    </div>
    
    
    <!----------------------------------------------------------------------------->

    <div class="carousel-item carousel-item-desktop">
      <img class="d-block w-100 img-enhanced" src="../../assets/images/Main Images/Slider Images/img2.jpg" alt="Second slide" style="margin-top: 80px;">
      <div class="carousel-caption d-flex justify-content-center align-items-center h-100 d-none d-md-none">
        <div> <!--class=" bg-slate-800 bg-opacity-35"-->

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-4xl font-sans px-10">Create your Dream Wedding</span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-2xl font-sans px-10">Because your special moments deserve the best ...</span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <app-search></app-search>
          </div>

        </div>
      </div>

      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg">
         
          <path fill="rgb(245,191,66)" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
          
        </svg>
      </div>
     
      
      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg2">
          
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
        </svg>
      </div> 

      <div class="bottom-curve">
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg3">
         
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
        </svg>
      </div>

      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg4">
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
        </svg>
      </div> 

      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg5">
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
        </svg>
      </div> 

    </div>

    <div class="carousel-item carousel-item-desktop ">
      <img class="d-block w-100 img-enhanced" src="../../assets/images/Main Images/Slider Images/img3.jpg" alt="Third slide" style="margin-top: -120px;">
      <div class="carousel-caption d-flex justify-content-center align-items-center h-100 d-none d-md-none">
        <div> <!--class=" bg-slate-800 bg-opacity-35"-->

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-4xl font-sans px-10">Create your Dream Wedding</span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-2xl font-sans px-10">Because your special moments deserve the best ...</span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <app-search></app-search>
          </div>
        </div>
      </div>


     <!-- Bottom Border Curve Gold -->
     <div >
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg">
       
        <path fill="rgb(245,191,66)" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
        
      </svg>
    </div>
   
    
    <div >
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg2">
        
        <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
      </svg>
    </div> 

    <div class="bottom-curve">
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg3">
       
        <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
      </svg>
    </div>

    <div >
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg4">
        <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
      </svg>
    </div> 

    <div >
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg5">
        <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,500 1000,0 1440,60 V500 H0 Z"></path>
      </svg>
    </div> 

    </div>

    

    

  </div>

</div>

<!-- For smaller screens -->
<div id="mobile-carousel" class="d-block d-md-none carousel slide border-0" data-bs-ride="carousel" data-bs-pause="hover">

  <div class="carousel-inner " style="margin-top: -30px;">

    
    <div class="carousel-item carousel-item-mobile active border-0 ">

      <img class="d-block w-100" src="../../assets/images/Main Images/Slider Images/img1.jpg" alt="Second slide" style="margin-top: 20px;">
      
      <div class="carousel-caption d-flex justify-content-start align-items-top h-100 d-none d-md-none">
        <div class="carousel-caption-mobile ">

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-lg font-sans px-10"></span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-lg font-sans px-10"></span>
          </div>

          <div class="col-md-12 flex justify-start pt-2">
            <span class="text-white text-lg font-sans">Create your Dream Wedding</span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-sm font-sans">Because your special moments deserve the best ...</span>
          </div>

       

        </div>

      </div> 

       <!-- Bottom Border Curve Gold -->
       <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg">
          <!-- <path fill="#DAA520" fill-opacity="1" d="M0,50 C360,260 1080,20 1440,0 V320 H0 Z"></path> -->
          <path fill=rgb(245,191,66) fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
        </svg>
      </div>

      <!-- Bottom Border Curve White -->
      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg2">
          
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
        </svg>
      </div> 

      <div class="bottom-curve">
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg3">
          <!-- <path fill="#DAA520" fill-opacity="1" d="M0,50 C360,260 1080,20 1440,0 V320 H0 Z"></path> -->
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
        </svg>
      </div>

      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg4">
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
        </svg>
      </div> 

      <div >
        <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg5">
          <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
        </svg>
      </div> 

    
    
    </div>
    
    <!----------------------------------------------------------------------------->

    <div class="carousel-item carousel-item-mobile bg-opacity-10">
     <!-- <img class="d-block w-100 " src="../../assets/images/Main Images/Slider Images/img2.jpg" alt="Second slide">-->
      <img class="d-block w-100 " src="../../assets/images/Main Images/Slider Images/img2.jpg" alt="Second slide" style="margin-top: 20px;">

      <div class="carousel-caption d-flex justify-content-center align-items-top pt-10 h-100 d-none d-md-none">
        <div class="bg-yellow-400 bg-opacity-0 carousel-caption-mobile">

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-lg font-sans px-10"></span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-lg font-sans px-10"></span>
          </div>

          <div class="col-md-12 flex justify-start pt-2">
            <span class="text-white text-lg font-sans">Create your Dream Wedding</span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-sm font-sans">Because your special moments deserve the best ...</span>
          </div>

        </div>

      </div> 

        <!-- Bottom Border Curve Gold -->
        <div >
          <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg">
            <!-- <path fill="#DAA520" fill-opacity="1" d="M0,50 C360,260 1080,20 1440,0 V320 H0 Z"></path> -->
            <path fill=rgb(245,191,66) fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
          </svg>
        </div>
  
        <!-- Bottom Border Curve White -->
        <div >
          <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg2">
            
            <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
          </svg>
        </div> 
  
        <div class="bottom-curve">
          <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg3">
            <!-- <path fill="#DAA520" fill-opacity="1" d="M0,50 C360,260 1080,20 1440,0 V320 H0 Z"></path> -->
            <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
          </svg>
        </div>
  
        <div >
          <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg4">
            <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
          </svg>
        </div> 
  
        <div >
          <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg5">
            <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
          </svg>
        </div> 


    </div>

    <div class="carousel-item carousel-item-mobile">
      <img class="d-block w-100 " src="../../assets/images/Main Images/Slider Images/img3.jpg" alt="Third slide" style="margin-top: 20px;">

      <div class="carousel-caption d-flex justify-content-center align-items-top pt-10 h-100 d-none d-md-none">
        <div class="carousel-caption-mobile">

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-lg font-sans px-10"></span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-lg font-sans px-10"></span>
          </div>

          <div class="col-md-12 flex justify-start pt-2">
            <span class="text-white text-lg font-sans">Create your Dream Wedding</span>
          </div>

          <div class="col-md-12 flex justify-center py-2">
            <span class="text-white text-sm font-sans">Because your special moments deserve the best ...</span>
          </div>

        </div>

      </div> 

     <!-- Bottom Border Curve Gold -->
     <div >
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg">
        <!-- <path fill="#DAA520" fill-opacity="1" d="M0,50 C360,260 1080,20 1440,0 V320 H0 Z"></path> -->
        <path fill="#dbe5f2" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
      </svg>
    </div>

    <!-- Bottom Border Curve White -->
    <div >
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg">
        <!-- <path fill="#DAA520" fill-opacity="1" d="M0,50 C360,260 1080,20 1440,0 V320 H0 Z"></path> -->
        <path fill=rgb(245,191,66) fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
      </svg>
    </div>

    <!-- Bottom Border Curve White -->
    <div >
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg2">
        
        <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
      </svg>
    </div> 

    <div class="bottom-curve">
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg3">
        <!-- <path fill="#DAA520" fill-opacity="1" d="M0,50 C360,260 1080,20 1440,0 V320 H0 Z"></path> -->
        <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
      </svg>
    </div>

    <div >
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg4">
        <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
      </svg>
    </div> 

    <div >
      <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg5">
        <path fill="#ffffff" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V380 H0 Z"></path>
      </svg>
    </div> 

   

  </div>

 



</div>





</div>


<!--------------------------------- Scripts Section--------------------------->

<!--
<script>
  //var myCarousel = document.querySelector('#carousel');
  var myCarousel = document.querySelector('carousel');


  var carousel = new bootstrap.Carousel(myCarousel, {
    interval: 3000,
    wrap: true,
    pause:"hover"
  });
</script>



<script>

document.addEventListener('DOMContentLoaded', function() {
  var desktopCarousel = document.getElementById('desktop-carousel');
  var mobileCarousel = document.getElementById('mobile-carousel');

  var desktopCarouselInstance = new bootstrap.Carousel(desktopCarousel, {
    interval: 3000,
    wrap: true
  });

  var mobileCarouselInstance = new bootstrap.Carousel(mobileCarousel, {
    interval: 3000,
    wrap: true
  });

  desktopCarousel.addEventListener('focusin', function() {
    desktopCarouselInstance.pause();
  });

  desktopCarousel.addEventListener('focusout', function() {
    desktopCarouselInstance.cycle();
  });

  mobileCarousel.addEventListener('focusin', function() {
    mobileCarouselInstance.pause();
  });

  mobileCarousel.addEventListener('focusout', function() {
    mobileCarouselInstance.cycle();
  });
});
</script>

-->

