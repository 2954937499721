
<!-- For larger screens -->

<div class="d-none d-md-block container-fluid flex ">

    <div class="row">
        <div class="col-md-12">
            <app-header2></app-header2>
         </div>
    </div>

    <div class="row pt-28">
        <app-goback></app-goback>
      </div>


     


    <div class="row">
        <div class="col-md-2 ">
            <app-listing-sidebar></app-listing-sidebar>
        </div>

        <div class="col-md-9 pt-3 mr-1">
            <div class="row ">
                <div class="col-md-12 pb-3  text-center background-search bg-opacity-75 ">
                    <app-search></app-search>
                </div>
            </div>

            <div class="row">
                <div class="col-md-11 py-2">
                    <app-vendorsummarydisplay></app-vendorsummarydisplay>
                </div>
            </div>

            <div>
                
            </div>

        </div>         
        
        <div class="row flex">
            <div class="col-md-12 pt-10">
                <app-footersection></app-footersection>
            </div>
        </div>

    
</div>


</div>



<!-- For smaller screens -->

<div class="d-block d-md-none container-fluid flex justify-start pt-16 px-4">

    <div class="row">
        <div class="col-12">
            <app-header2></app-header2>
         </div>
    </div>


    <div class="row">
        <div class="col-12">
            <div class="col-12 pb-3  text-center ">
                <app-search></app-search>
            </div>
        <div>
    </div>

    <!--
    <div class="row">

        <div class="col-12 ">
            <app-listing-sidebar></app-listing-sidebar>
        </div>
    </div>

    -->

    <div class="col-12">
            <app-vendorsummarydisplay></app-vendorsummarydisplay>
    </div>
    

     <div class="row">
        <div class="col-12">
            <app-footersection></app-footersection>
        </div>
      </div>

    
</div>


</div>



    






