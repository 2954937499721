import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {SearchData,SideBarFilters} from "../models/datamodels"

@Injectable({
  providedIn: 'root'
})

export class FilterDataServiceService 
{

  private dataSource = new BehaviorSubject<SearchData | null>(null);
  currentData = this.dataSource.asObservable();

  private sideBarDataSource=new BehaviorSubject<SideBarFilters|null>(null);
  currentSidebarData=this.sideBarDataSource.asObservable();



  constructor() { }

  changeData(data: SearchData) 
  {
    this.dataSource.next(data);
  }

  changeSidebarData(data: SideBarFilters) 
  {
    this.sideBarDataSource.next(data);
  }



}



