import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { UservendorcalendardetailsComponent } from './uservendorcalendardetails/uservendorcalendardetails.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-uservendorcalendar',
  standalone: true,
  imports: [GobackComponent,Header2Component,UservendorcalendardetailsComponent, FootersectionComponent,MatCardModule],
  templateUrl: './uservendorcalendar.component.html',
  styleUrl: './uservendorcalendar.component.css'
})
export class UservendorcalendarComponent 
{

}
