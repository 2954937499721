import { Component,OnInit } from '@angular/core';
import { GobackComponent } from '../../goback/goback.component';
import { Mailcontent, TransactionData } from '../../../models/datamodels';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { CommonModule,Location } from '@angular/common';


@Component({
  selector: 'app-viewreceiptdetails',
  standalone: true,
  imports: [GobackComponent,CommonModule],
  templateUrl: './viewreceiptdetails.component.html',
  styleUrl: './viewreceiptdetails.component.css'
})


export class ViewreceiptdetailsComponent implements OnInit
{
  currentTransaction:TransactionData|null=null;

  begdate:string|null=null;
  enddate:string|null=null;
  transactiondate:string|null=null;


  constructor(private vendorDataService:VendorDataServiceService){}

  ngOnInit(): void 
  {
      this.vendorDataService.currentTransaction.subscribe(data=>
      {
          this.currentTransaction=data;

          this.begdate=this.convertUTCToLocal(this.currentTransaction?.bookingstart!);
          this.enddate=this.convertUTCToLocal(this.currentTransaction?.bookingend!);
          this.transactiondate=this.convertUTCToLocal(this.currentTransaction?.transactiondate!);

          let  content='<h2> Receipt from SoulsWed</h2><br><br>';
          content=content+'<b> Receipt No: </b>'+this.currentTransaction?.idtransaction.toString();
          content=content+'<br><b> Vendor Booked: </b>'+this.currentTransaction?.vendorname;
          content=content+'<br><b> Amount: </b>'+this.currentTransaction?.currency+this.currentTransaction?.amount.toFixed(0).toString();
          content=content+'<br><b> Booking Start Date: </b>'+this.begdate;
          content=content+'<br><b> Booking End Date: </b>'+this.enddate;
          content=content+'<br><b> Customer Name: </b>'+this.currentTransaction?.username;
          content=content+'<br><b> Customer Email: </b>'+this.currentTransaction?.useremail;
          content=content+'<br><b> Transaction Date: </b>'+this.transactiondate;

          const mailContent:Mailcontent=
          {
              email:this.currentTransaction?.useremail!,
               htmlcontent:content
          }

          this.vendorDataService.sendReceiptMail(mailContent);
      })

      //const content = document.getElementById('content-to-print')!.innerHTML;

     
  }

   // Method to print the page
   printPage() 
   {
    window.print();
  }

  convertUTCToLocal(utcDateTime: string): string 
  {
    const utcDate = new Date(utcDateTime);
    return utcDate.toLocaleString(); // Converts back to the local time zone
  }

}
