import { Component ,OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet,NavigationEnd,Router } from '@angular/router';
import { filter } from 'rxjs';




import { HttpClientModule } from '@angular/common/http';

//-------- Material Components Module ------
import { MaterialModule } from './material.module';

// ------ Import project modules ------

import { HomeComponent } from './home/home.component';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet,MaterialModule,HomeComponent,HttpClientModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})

export class AppComponent implements OnInit
{
  title = 'soulswed';


  constructor(private router: Router) { }  // Inject Router service
 
  //--------- This is used to always start a new page at the top ---------------------
  ngOnInit(): void
  {

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        window.scrollTo(0, 0);
      });
    
  }
}

export class AppModule { }
