import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { CharterslistingComponent } from './charterslisting/charterslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import {MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';


@Component({
  selector: 'app-charters',
  standalone: true,
  imports: [GobackComponent,Header2Component,CharterslistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './charters.component.html',
  styleUrl: './charters.component.css'
})
export class ChartersComponent {

}
