import { Component, OnInit } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { ActivatedRoute } from '@angular/router';
import { UserServiceService } from '../../services/user-service.service';



@Component({
  selector: 'app-verifyemail',
  standalone: true,
  imports: [Header2Component, FootersectionComponent],
  templateUrl: './verifyemail.component.html',
  styleUrl: './verifyemail.component.css'
})

export class VerifyemailComponent implements OnInit
{

  message:string='';

  constructor(private route: ActivatedRoute,private userservice:UserServiceService) {}

  ngOnInit(): void 
  {
    this.route.queryParams.subscribe(params => 
    {
      const token = params['token'];

      if (token) 
      {
        
        this.userservice.verifyEmail(token).subscribe(data=>
        {
          this.message=data.message;
        }
        )
      } 
      else 
      {
        this.message = 'Invalid verification link.';
      }
    });
  }

 

}
