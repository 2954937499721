import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { UserServiceService } from '../../services/user-service.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-forgotpassword',
  standalone: true,
  imports: [Header2Component,FootersectionComponent,FormsModule],
  templateUrl: './forgotpassword.component.html',
  styleUrl: './forgotpassword.component.css'
})

export class ForgotpasswordComponent 
{
  message:string='';
  email:string='';

  constructor(private userservice:UserServiceService){};

  sendResetEmail()
  {
    if (this.email.length<=0)
    {
      alert('Please enter email id');
    }
    else
    {
      this.userservice.sendPasswordResetEmail(this.email).subscribe(data=>
      {
        alert(data.message);
      }
      )
    }
  } 
}
