

<div class="container-fluid ">
    
    <div class="row pt-4">
        <div class="col-md-12">
            <span class="flex  justify-center soulsWedHeadingFont">Our Wedding Stories</span>
        </div>
    </div>


    <div class="row py-6">

        <div class="col-md-4">

            <div class="flex justify-center">
                <mat-card>
                    <mat-card-content>
                        <img mat-card-image src="../../assets/images/Hotels/weddingstories3.jpg">
                        <div class="text-center">
                            <h2>Siddhi and Shaheen</h2>
                            <h4>Thanks a million for making our wedding a magical one. It is still such a memorable wedding in my entire family because of your perfect execution</h4>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            
        </div> 

        <div class="col-md-4">

            <div class="flex justify-center">
                <mat-card>
                    <mat-card-content>
                        <img mat-card-image src="../../assets/images/Hotels/weddingstories2.jpg">
                        <div class="text-center">
                            <h2>Sunitha and Raunak</h2>
                            <h4>Raunak and I come from two very different backgrounds. My roots are in Orissa, while I was brought up in America. Whereas, Raunak&#039;s life revolved mainly around Trivandrum and Cochin. So while planning our wedding, we knew we somehow wanted to incorporate everyone into this wedding</h4>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            
        </div> 

        <div class="col-md-4">

            <div class="flex justify-center">
                <mat-card>
                    <mat-card-content>
                        <img mat-card-image src="../../assets/images/Hotels/weddingstories.jpg">
                        <div class="text-center">
                            <h2>Julie and Robert</h2>
                            <h4>Being a bride is stressful enough, having a destination wedding just takes you to another level of complete madness! I&#039;m so happy I heard from you. An energetic team is full of fresh ideas!! Every minute detail was taken care of for my wedding and we really enjoyed the lack of stress. You definitely did a commendable Job !</h4>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            
        </div> 
          

     </div>

 </div>

       