import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { ViewreceiptdetailsComponent } from './viewreceiptdetails/viewreceiptdetails.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-viewreceipt',
  standalone: true,
  imports: [ Header2Component,ViewreceiptdetailsComponent,FootersectionComponent,GobackComponent],
  templateUrl: './viewreceipt.component.html',
  styleUrl: './viewreceipt.component.css'
})
export class ViewreceiptComponent {

}
