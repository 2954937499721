import { Injectable } from '@angular/core';
import { UserData,UserFavourites,VendorID } from '../models/datamodels';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class UserServiceService 
{

  private apiUrl = environment.middletierhost;

  constructor(private http: HttpClient) {}

  createUser(user: UserData): Observable<any> {
    return this.http.post(`${this.apiUrl}/users`, user);
  }

  getUsers(): Observable<UserData[]> {
    return this.http.get<UserData[]>(`${this.apiUrl}/users`);
  }

  getUser(id: string|null): Observable<UserData> 
  {
     return this.http.get<UserData>(`${this.apiUrl}/users/${id}`);
  }

  updateUser(id: number, user: UserData): Observable<any> {
    return this.http.put(`${this.apiUrl}/users/${id}`, user);
  }

  deleteUser(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/users/${id}`);
  }

  login(username: string, password: string,usertype:string): Observable<any> 
  {
    return this.http.post(`${this.apiUrl}/users/login`, { username, password,usertype });
  }

  signInWithGoogle(username: string, email: string,usertype:string): Observable<any> 
  {
    return this.http.post(`${this.apiUrl}/users/googlelogin`, { username, email,usertype });
  }


  signInWithFacebook(username: string, email: string,usertype:string): Observable<any> 
  {
    return this.http.post(`${this.apiUrl}/users/facebooklogin`, { username, email,usertype });
  }

  signInWithWhatsApp() 
  {

  }

  signInWithSmsOtp() 
  {
 
  }


  //------------Add User Favourites -----------------------
  addUserFavourites(userFavourite: UserFavourites): Observable<any> 
  {
    return this.http.post(`${this.apiUrl}/users/userfavourites`, userFavourite);
  }

  //------------Remove User Favourites -----------------------
  deleteUserFavourites(userFavourite: UserFavourites): Observable<any> 
  {
    const options = 
    {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: userFavourite
    };

    return this.http.delete(`${this.apiUrl}/users/userfavourites`, options);
  }

  //-----------Get User Favoutites -----------------
   getUserFavourites(iduser:number): Observable<VendorID[]>
   {
 
     console.log('Called get user favourites');
       
     
     //this.apiUrl = `${this.apiUrl}/users/userfavourites`+iduser.toString(); 
     console.log('In getuserfavourites: url is '+this.apiUrl)
 
     return this.http.get<VendorID[]>(`${this.apiUrl}/users/userfavourites/`+iduser.toString());
 
   }

   verifyEmail(token: string): Observable<any> 
   {
        return this.http.get<UserData>(`${this.apiUrl}/users/verify-email?token=${token}`);
   }

   sendPasswordResetEmail(email:string): Observable<any> 
   {
        console.log('Email to be sent :',email);
        return this.http.post(`${this.apiUrl}/users/forgot-password`,{ email: email });
   }

   resetPassword(token:string,newPassword:string): Observable<any> 
   {
        
        return this.http.post(`${this.apiUrl}/users/reset-password`,{ token: token, newPassword: newPassword });
   }

   
}
