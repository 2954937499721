<app-header2></app-header2>

<div class="container mt-3 pt-32 text-yellow-900">
  <div class="flex justify-center">
    <h1><b>List Your Hotel</b></h1>
  </div>

    <form>

      <!-- Select country and category -->
      <div class="row  m-3 p-3 flex items-center custom-border">

          <!-- Country Drop Down --------------->
           <div class="col-md-6">
              <label for="country" class="form-label">Country *</label>
                 <select class="form-select" id="country">
                  <!-- Options will be populated here -->
                </select>
          </div>
  
          <!-- Category Drop Down --------------->
          <div class="col-md-6">
               <label for="category" class="form-label">Hotel Category *</label>
                 <select class="form-select" id="category">
                  <option value="3-Star">3-Star</option>
                  <option value="4-Star">4-Star</option>
                  <option value="5-Star">5-Star</option>
                  <option value="5-star plus">5-star plus</option> 
                  <option value="not rated">Not Rated</option>               
                </select>
          </div>

      </div>
      
              
      <!-- Section 2: Vendor Details -->
      <div class=" row custom-border m-3 p-3 ">
        <div class="pt-1 pb-2 flex justify-center">
          <h2><b>Hotel Details</b></h2>
        </div>
  
        <div>
          <label for="vendorName" class="form-label">Hotel Name *</label>
          <input type="text" class="form-control" id="vendorName">
        </div>
  
         <!-- Select email and mobile -->
         <div class="row pt-3">
  
          <!---------- Email --------------->
          <div class="col-md-5">
              <label for="email" class="form-label">Email ID *</label>
              <input type="email" class="form-control" id="email">
              <small id="emailHelp" class="form-text text-muted"><i>Your email-id will be your user id for Soulswed</i></small>
          </div>

          <!---------- Empty --------------->
          <div class="col-md-1">

          </div>
    
  
           <!-- Country Drop Down --------------->
           <div class="col-md-2">
              <label for="countrycode" class="form-label">Phone Country Code *</label>
              <select class="form-select" id="countrycode">
                <!-- Options will be populated here -->
              </select>
          </div>
  
          <!---------- Phone Number --------------->
          <div class="col-md-4">
              <label for="phone" class="form-label">Phone Number *</label>
              <input type="text" class="form-control" id="phone">
          </div>
  
        </div>
  
        <!-------------------------- Address Fields-->
        
       <div class="row pt-3 ">
  
          <!---------- Addess Lines --------------->
          <div class="col-md-4">
            <label for="Addessline1" class="form-label">Addess Line 1 *</label>
            <input type="text" class="form-control" id="Addessline1">
          </div>
  
          <div class="col-md-4">
            <label for="Addessline2" class="form-label">Addess Line 2</label>
            <input type="text" class="form-control" id="Addessline2">
          </div>
  
          <div class="col-md-4">
            <label for="Addessline3" class="form-label">Addess Line 3</label>
            <input type="text" class="form-control" id="Addessline3">
          </div>
  
       </div>

      </div>
      
      <!-- Section 3: Service Details -->
      <div class=" row m-3 p-3 custom-border">
        <div class="pt-2 flex justify-center">
          <h2><b>Service Details</b></h2>
        </div>
        
        <!-------------------------- Service Description -------------------->
        <div class="row">
          <div class="col-md-12">
            <label for="servicedescription" class="form-label">Describe your service (max 500 characters) *</label>
            <input type="text" class="form-control" id="servicedescription">
          </div>
        </div> 

        <!-------------------------- Service Tagline -------------------->
        <div class="row pt-3">
          <div class="col-md-12">
            <label for="tagline" class="form-label">Your Tagline (This will appear in our listings)</label>
            <input type="text" class="form-control" id="tagline">
          </div>
        </div> 

        <!-- Starting price and price basis -->

        <div class="row pt-3">
          <div class="col-md-2">
            <div class="dropdown">
              <label for="dropdownMenuButton" class="form-label">Currency</label><br>
              <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ selectedCurrency }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" role="menu">
                  <li role="menuitem"><a class="dropdown-item" (click)="setCurrency('INR')">INR</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="setCurrency('USD')">USD</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="setCurrency('EUR')">EUR</a></li>
              </ul>
          </div>
          </div>
       

        <!-------------------------- Service Tagline -------------------->
        <div class="col-md-2">
            <label for="Price" class="form-label">Price</label>
            <input type="text" class="form-control" id="price">
        </div>

        <!-- Starting price and price basis -->

          <div class="col-md-2 pl-2">
            <div class="dropdown">
              <label for="pricebasis" class="form-label">Price Basis</label><br>
              <button class="dropdown-toggle" type="button" id="pricebasis" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ selectedPricebasis }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" role="menu">
                  <li role="menuitem"><a class="dropdown-item" (click)="setPricebasis('Per Day')">Per Day</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="setPricebasis('Per Person')">Per Person</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="setPricebasis('Per Engagement')">Per Engagement</a></li>
              </ul>
            </div> 
        </div>
      </div>

       <!-- Image and Video Upload -->

       <div class="row pt-10">

        <div class="col-md-5">
            <label for="images" class="form-label">Upload Images (max 6)</label>
            <input type="file" class="form-control" id="images" multiple accept="image/*">
        </div>

        <div class="col-md-2">
        </div>

        <div class="col-md-5">
            <label for="video" class="form-label">Upload Video</label>
            <input type="file" class="form-control" id="video" accept="video/*">
        </div>

     </div>
  


      <!------------------------- Amneties --------------------------------------------->

      <div class=" row m-2 pt-4 ">
        <div class="flex justify-center">
          <h2><b>Amenities</b></h2>
        </div>
      </div>

      <div class="row pt-3">

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input border" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Restaurant
            </label>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Bar
            </label>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Wi-Fi
            </label>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
             Laundry
            </label>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
             Outdoor Activities
            </label>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Swimming Pool
            </label>
          </div>
        </div>


      </div>


      <div class="row pt-3">

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Gym
            </label>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Spa
            </label>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Doctor On Call
            </label>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Coffee Shop
            </label>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Business Centre
            </label>
          </div>
        </div>

        <div class="col-md-2 pb-4">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="exampleCheck">
            <label class="form-check-label" for="exampleCheck">
              Meeting Halls
            </label>
          </div>
        </div>


      </div>

    </div>
        

          
  
         

      <!-- Section 4 : Security Details -->
      <div class=" row custom-border m-3 p-3 ">
        <div class="pt-1 pb-2 flex justify-center">
          <h2><b>Security</b></h2>
        </div>


        <div class="row">
          <div class="col-md-5">
            <label for="password" class="form-label">Password</label>
            <input type="password" class="form-control" id="password">
          </div>

          <div class="col-md-2">

          </div>

          <div class="col-md-5">
            <label for="confirmPassword" class="form-label">Confirm Password</label>
            <input type="password" class="form-control" id="confirmPassword">
          </div>

        </div>
      </div>
      

      <!----------------------------Buttons--------------------------->
    
      <div class="row m-3 p-3 "> 
        <div class="col-md-4">
        </div>

        <div class="col-md-4">
          <button class="border px-5 py-2 bg-yellow-900 text-white" type="submit" >Register</button>
        </div>

        <div class="col-md-4">
          <button class="border px-5 py-2 bg-yellow-600 text-white" type="button"  onclick="resetForm()">Reset</button>
        </div>

        <div class="col-md-4">
        </div>
      </div>
      
      




    </form>
  </div>

<app-footersection></app-footersection>
  