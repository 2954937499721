<div class="container-flux mt-5">
    <div class="custom-carousel">
      <carousel [interval]="4000" [pauseOnFocus]="false" [noWrap]="false">
        <slide *ngFor="let slide of slides; let index = index" [active]="index === 0">
          <img [src]="slide.image" [alt]="slide.text" class="d-block w-100 custom-image slide-in">
          <div class="carousel-caption">
            <h3>{{ slide.text }}</h3>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
  