import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-goback',
  standalone: true,
  imports: [],
  templateUrl: './goback.component.html',
  styleUrl: './goback.component.css'
})

export class GobackComponent 
{
  constructor(private location: Location){}

  goBack(): void 
  {

    this.location.back();
    
 }

}
