<div class="custom-alert-backdrop" *ngIf="visible">
  <div class="custom-alert-dialog">
    <h1>{{ title }}</h1>
    <p>{{ message }}</p>
    <button (click)="close()">OK</button>
  </div>
</div>



