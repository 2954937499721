import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { WeddingvenueslistingComponent } from './weddingvenueslisting/weddingvenueslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import {MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-venues',
  standalone: true,
  imports: [GobackComponent,Header2Component,WeddingvenueslistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './venues.component.html',
  styleUrl: './venues.component.css'
})

export class VenuesComponent {

}
