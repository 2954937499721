import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { PlannerslistingComponent } from './plannerslisting/plannerslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import {MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';


@Component({
  selector: 'app-planners',
  standalone: true,
  imports: [GobackComponent,Header2Component,PlannerslistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './planners.component.html',
  styleUrl: './planners.component.css'
})
export class PlannersComponent {

}
