import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { SakhiserviceComponent } from '../sakhiservice/sakhiservice.component';
import { MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-sakhiservicedisplay',
  standalone: true,
  imports: [GobackComponent,Header2Component,SakhiserviceComponent, FootersectionComponent,MatCardModule],
  templateUrl: './sakhiservicedisplay.component.html',
  styleUrl: './sakhiservicedisplay.component.css'
})

export class SakhiservicedisplayComponent 
{

}
