import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})

export class AlertDialogComponent 
{

  @Input() title: string = 'Alert';
  @Input() message: string = '';
  visible: boolean = false;

  constructor(){}

  show(title: string, message: string): void 
  {
    this.title = title;
    this.message = message;
    this.visible = true;
  }

  close(): void 
  {
    this.visible = false;
  }

  

}
