import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import {UserServiceService} from '../../../services/user-service.service'
import { UserData,CountryData,phoneCountryCode } from '../../../models/datamodels'
import {Header2Component} from '../../header2/header2.component'
import { FootersectionComponent} from '../../footersection/footersection.component'
import { CommonModule,Location } from '@angular/common';
import { AbstractControl } from '@angular/forms';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { Router } from '@angular/router';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edituser',
  standalone: true,
  imports: [ReactiveFormsModule,Header2Component,FootersectionComponent,CommonModule],
  templateUrl: './edituser.component.html',
  styleUrl: './edituser.component.css'
})

export class EdituserComponent implements OnInit
{

  userForm!: FormGroup;
  countries :CountryData[] = [];
  phoneCountryCodes :phoneCountryCode[]=[];
  
  idUser:string|null=null;
  idUserAdded=0;

  user:UserData|null=null;

  usertypeList:string[]=['Customer','Vendor'];

  isAdmin:boolean=false;
 

  @Output() userCreated = new EventEmitter<UserData>();

  constructor(private location:Location,private route:ActivatedRoute,private router:Router,private fb: FormBuilder, private userService: UserServiceService,private masterdataservice:MasterdataserviceService,private envDataService:EnvironmentDataServiceService){}

  ngOnInit(): void 
  {
    this.idUser=this.route.snapshot.paramMap.get('iduser');

    console.log('Id user recieved for edit is :',this.idUser?.trim());

    
    this.userForm = this.fb.group({
      usertype: ['', [Validators.required,Validators.maxLength(99)]],
      username: ['',[ Validators.required,Validators.maxLength(99)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator]],
      confirmPassword: ['', [Validators.required]],
      idcountry: [],
     // phonecountrycode: ['', Validators.required],
      //phonenumber:['', [Validators.pattern('[0-9]{10,11}$')]]
      phonecountrycode: [],
      phonenumber:[]
    },{validator:this.confirmPasswordValidator});

    //---------- Get masterlist of countries -------------------
    this.masterdataservice.getCountries().subscribe(data=>
      {
        this.countries=data;
        //this.userForm.get('idcountry')?.setValue(this.countries.find(c=>c.idcountry==this.user?.idcountry));
      }
    )

    this.envDataService.currentUser.subscribe(data=>
      {
        if(data?.usertype=='Admin')
          this.usertypeList.push('Admin');
      }
    )

        
    this.phoneCountryCodes=this.masterdataservice.getCountryPhoneCodes();

    //------------ Get the user data ------------------------------

    this.userService.getUser(this.idUser).subscribe(data=>
      {
        this.user=data;

        console.log('User data recieved :',this.user);

        this.userForm.get('usertype')?.setValue(this.user.usertype);
        this.userForm.get('username')?.setValue(this.user.username);
        this.userForm.get('email')?.setValue(this.user.email);
        this.userForm.get('password')?.setValue(this.user.password);
        this.userForm.get('confirmPassword')?.setValue(this.user.password);
        this.userForm.get('idcountry')?.setValue(this.user.idcountry);
        this.userForm.get('phonecountrycode')?.setValue(this.user.phonecountrycode);
        this.userForm.get('phonenumber')?.setValue(this.user.phonenumber);


    
      })


  }


    //------------ Password validator ------------------------------
    passwordValidator(control: AbstractControl): { [key: string]: any } | null 
    {
      const valid = /^(?=.*\d)(?=.*[\W_])(?!.*['"]).{8,}$/.test(control.value);
      return valid ? null : { invalidPassword: 'Please enter password in the correct format' };
    }
  
    confirmPasswordValidator(group: FormGroup): { [key: string]: any } | null 
    {
      let pass = group?.get('password')?.value;
      let confirmPass = group?.get('confirmPassword')?.value;
  
      console.log('Passwd :',pass);
      console.log('confirmPass:',confirmPass);
      console.log('Result:',pass === confirmPass)
      return pass === confirmPass ? null : { notSame: true };
    }
  
  
    onSubmit(): void 
    {
      //if (this.userForm.valid)
      //{
        console.log('Form Submitted with values:', this.userForm.value);
    
        const userDetails=this.createUserDetails();
  
        console.log("User Details : " , userDetails);
  
        
        //--------- Call the service to add user -----------------
        this.userService.updateUser(userDetails.iduser,userDetails).subscribe(
          {
           
   
            next: (response:any) => 
            {
              this.idUserAdded=response.data;
              console.log('New Userid:',this.idUserAdded);
              //alert('User added with id :'+this.idUserAdded);
              alert('User updated successfully');
              this.resetForm();
      
            }
            ,
            error: (error:any) => 
            {
              console.error('There was an error:',error.data);
              alert('There was an error')
              
            }
      });
  
      
        
  
    
      //}
      //else
      //{
       // console.log('Form is not valid');
       // alert('Please enter all mandatory fields in the right format')
      //}
    }
  
    createUserDetails()
    {
      return<UserData>
      {
          iduser:this.user?.iduser,
          username:this.userForm.get('username')?.value,
          password:this.userForm.get('password')?.value,
          email:this.userForm.get('email')?.value,
          idcountry:this.userForm.get('idcountry')?.value,
          phonecountrycode:this.userForm.get('phonecountrycode')?.value,
          phonenumber:this.userForm.get('phonenumber')?.value,
          usertype:this.userForm.get('usertype')?.value,
          usersource:'System'
      }
  
    }
  
  
    onBlur(field: string) 
    {
      const control = this.userForm.get(field);
  
      if (control) 
      {
        control.markAsTouched();
        console.log(field,' marked as touched')
      }
    }
  
    resetForm() 
    {
      this.router.navigate(['/manageusers']);
    }
  
  
    //-------------- Getters ------------------
  
    get username()
    {
      return this.userForm.get('username');
    }
  
    get password()
    {
      return this.userForm.get('password');
    }
  
    get confirmPassword()
    {
      return this.userForm.get('confirmPassword');
    }
  
    get email()
    {
      return this.userForm.get('email');
    }
  
    get  idcountry()
    {
      return this.userForm.get('idcountry');
    }
  
    get phonecountrycode()
    {
      return this.userForm.get('phonecountrycode');
    }
  
  
    get phonenumber()
    {
      return this.userForm.get('phonenumber');
    }
  
    get usertype()
    {
      return this.userForm.get('usertype');
    }


}
