

<div class="modal-overlay" (click)="onCancel()">
    <div class="modal-container" (click)="$event.stopPropagation()" >
      <div class="modal-header" >
        <h4 class="modal-title soulsWedHeadingFont" >Booking</h4>
        <button class="close-button" (click)="onCancel()">&times;</button>
      </div>

      <div class="modal-body">

        <div class="row">
            <div class="col-md-6">
              <label for="startDate" class="form-label soulsWedNormalFont">Start Date</label>
              <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="startDateString"  [min]="mindate">
            </div>

            <div class="col-md-6">
                <label for="endDate" class="form-label soulsWedNormalFont">End Date</label>
                <input type="date" class="form-control" id="endDate" [(ngModel)]="endDateString" [min]="startDateString">
            </div>
          </div>

        <div class="row pt-1">
          <div class="col-md-3"></div>
          <div class="col-md-8 pt-2">
            <button class="border button-style-5" id="cancelbutton" (click)="checkAvailability()">Check Availability</button>
            <div class="col-md-1"></div>
          </div>
        </div>
        <div class="row pt-2">
          <p>{{availMessage}}</p>
        </div>
        
        

      </div>
       
        <div *ngIf="isAvailable">
          <form>
            <div class="form-group pt-4">
              <label for="baseprice">Base Price ({{currency}}) {{pricebasis}}</label>
              <input type="number" class="form-control" id="baseprice" [(ngModel)]="baseprice" name="baseprice" (ngModelChange)="formatNumber();calculateTotals()">
            </div>
            <div class="form-group">
              <label for="guests">Guests</label>
              <input type="number" class="form-control" id="guests" [(ngModel)]="guests" name="guests" (ngModelChange)="calculateTotals()">
            </div>
            <div class="form-group">
              <label for="days">Days</label>
              <input type="number" class="form-control" id="days" [(ngModel)]="days" name="days" (ngModelChange)="calculateTotals()" readonly>
            </div>
            <div class="form-group">
              <label for="tax">Tax (%)</label>
              <input type="number" class="form-control" id="tax" [(ngModel)]="tax" name="tax" (ngModelChange)="calculateTotals()">
            </div>
            <div class="form-group">
            <h3>Total Before Tax ({{currency}}): {{ totalBeforeTax | number:'1.1-1'  }}</h3>
            </div>
  
            <div class="form-group">
             <h3>Total After Tax ({{currency}}) : {{ totalAfterTax | number:'1.1-1' }}</h3>
            </div>
  
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
              <button type="button" class="btn btn-primary" (click)="onPay()">Pay</button>
            </div>
  
          </form>
        </div>

        
      </div>
      
   
  </div>
  