<head>
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap" rel="stylesheet">


</head>

<!--<div class="d-none d-md-block container-fluid header pt-2 pb-2 " > -->
<div class="large-screen-div container-fluid header pt-2 pb-2 " >

    <div class="scroll-container">
        <div class="scroll-content py-1">
          <!-- Your content here -->
          
         <!-- <span style="color: rgb(21, 21, 196);font-size: 19px;" >Coming soon :&nbsp;</span> <span style="color: rgb(21, 21, 196);font-size: 19px;">&nbsp;&nbsp; Booking of hotel rooms, private chartered flights, cruise liners, decorators & around 30 more vendor categories. </span>-->
          <span style="color: rgb(238,116,41);font-size: 19px;" >Coming soon :&nbsp;</span> <span style="color: rgb(238,116,41);font-size: 19px;">&nbsp;&nbsp; Booking of hotel rooms, private chartered flights, cruise liners, decorators & around 30 more vendor categories. </span>
        </div>
    </div> 

    <div class="header-gradient-style row flex items-center">

        

        <!--Column for Logo -------->
        <div class="col-md-2">
            <div class="flex justify-start place-items-end clickable">
                 <!-- <button  routerLink="/" class=" soulsWedTitleFont flex justify-items-center">SoulsWed</button>-->
                <img routerLink="/" src="../../assets/images/Logo/Logo2.jpeg" >
                <!--<span class="clickable soulsWedTitleFont" routerLink="/">SoulsWed</span>-->
    
            </div>
       
        </div>

        <div class="col-md-5 justify-start" >
            <div class="flex justify-start items-center ">
               <!-- <button (click)="goToHotels()" class="menu-button-gradient-style mx-2 ">Rooms</button> -->

               <!-- <button (click)="goToHotels()" class="menu-button-gradient-style mx-2">Hotels</button>-->
                <button (click)="goToVenues()" class="menu-button-gradient-style mx-2">Venues</button>
                <button (click)="goToPlanners()" class="menu-button-gradient-style mx-2">Planners</button>
               <!--  <button (click)="goToCharters()" class="menu-button-gradient-style mx-2">Charters</button>-->
                <button (click)="goToPhotograhers()" class="menu-button-gradient-style mx-2">Photographers</button>
                <button (click)="goToDecorators()" class="menu-button-gradient-style mx-2">Decorators</button>
                
                
                <div class="dropdown mx-2" >
                    <button class="menu-button-gradient-style dropdown-toggle" type="button" id="otherServicesDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Other Services
                    </button>
                    <div class="dropdown-menu" aria-labelledby="otherServicesDropdown">
                        <button class="dropdown-item" (click)="goToMakeupArtists()">Makeup Artists</button>
                        <button class="dropdown-item" (click)="goToSakhiService()">Sakhi Service</button>

                        <!--<button class="dropdown-item" (click)="goToCaterers()">Caterers</button>-->

                        

                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-1"></div>

        <div class="col-md-4 d-none d-md-block flex items-center" >  
        
                    <div class="soulsWedNormalFont flex justify-end items-center">

                        <div class=" flex justify-end items-center">
                          
                            <div class="flex justify-center items-center">
                                <div class="clickable " [matMenuTriggerFor]="userMenu" *ngIf="userName !== 'Sign in'">
                                  <!--<span class="material-icons user-icon text-srh-orange">person</span> -->
                                  <span class="soulsWedNormalFont" [attr.title]="userName">{{ userName.length > 6 ? (userName.substr(0, 4) + '..') : userName }}</span>
                                 <!--<span class="soulsWedNormalFont">{{userName}}</span> -->
                                  <span class="material-icons user-icon">arrow_drop_down</span>
                                  
                                </div>
                                <div class="clickable" (click)="openSignInDialog()" *ngIf="userName === 'Sign in'">
                                 <!-- <span class="material-icons user-icon text-srh-orange">person</span>-->
                                  <span class="soulsWedNormalFont" [attr.title]="userName">{{userName}}</span>
                                </div>
                              </div>
                              
                              <mat-menu #userMenu="matMenu" class="p-1">
                                <button class="dropdown-item px-1">{{userName}}</button>
                                 <hr>
                                <div *ngIf="isCustomer">
                                    <button class="soulswedmenuitem"  mat-menu-item (click)="goToUserFavourites()">View Favourites</button>
                                    <button class="soulswedmenuitem px-1"  mat-menu-item (click)="goToChangePassword()">Change Password</button>
                                </div>
                                
                                <div *ngIf="isVendor">
                                    <button  routerLink="/registervendor" class="soulswedmenuitem px-1">Add a new business</button>
                                    <br><button class="p-1 soulswedmenuitem" (click)="goToUserVendors()">Manage your business</button>
                                    <br><button class="p-1 soulswedmenuitem" (click)="goToVendorCalendar()">Manage vendor calendar</button>
                                    <hr>
                                    <button class="soulswedmenuitem px-1"  mat-menu-item (click)="goToUserFavourites()">View Favourites</button>
                                    <button class="soulswedmenuitem px-1"  mat-menu-item (click)="goToChangePassword()">Change Password</button>
                                </div>


                                <div *ngIf="isAdmin">
                                    <button class=" px-1 soulswedmenuitem" mat-menu-item (click)="registerUser()">Add User</button>
                                    <br><button class="p-1 soulswedmenuitem" mat-menu-item routerLink="/manageusers">Manage Users</button>                                   
                                    <br><button class="p-1 soulswedmenuitem" mat-menu-item routerLink="/registervendor">Add Vendor</button>
                                    <br><button class="p-1 soulswedmenuitem" mat-menu-item (click)="goToUserVendors()">Manage Vendors</button>
                                    <br><button class="p-1 soulswedmenuitem" mat-menu-item (click)="goToVendorCalendar()">Manage Vendor Calendar</button>
                                    <br><button class="p-1 soulswedmenuitem" mat-menu-item routerLink="/viewtransactions">View Transactions</button>
                                    <hr>
                                    <button class="p-1 soulswedmenuitem"  mat-menu-item (click)="goToUserFavourites()">View Favourites</button>
                                    <button class="soulswedmenuitem px-1"  mat-menu-item (click)="goToChangePassword()">Change Password</button>

                                </div>

                                <hr>

                                <button class="p-1 soulswedmenuitem" mat-menu-item (click)="logOff()">Log Off</button>
                                
                                

                               
                                <!--<br><button class="p-1 soulswedmenuitem" mat-menu-item (click)="changePassword()">Change Password</button> -->
                              </mat-menu>
                              
                          
                         <!--
                         <div class="soulsWedNormalFont flex items-center pl-6">
                         
                           <span class="material-icons text-srh-orange">
                                store
                            </span> 

                                <button  routerLink="/registervendor">&nbsp;Register as vendor</button>
                         </div> 
                         -->

                         <div class="flex justify-center items-center pl-6">
                            
                            <select class="form-select rounded-select" id="currency" [(ngModel)]="selectedCurrencyData" (ngModelChange)="onCurrencySelected($event)">
                                <option *ngFor="let currency of currencyList" [ngValue]="currency" [title]="currency.currencyname">{{currency.currencycode}}&nbsp;</option>
                              <!--  <option *ngFor="let currency of currencyList" [ngValue]="currency">{{currency.currencyname}}-{{currency.currencycode}}&nbsp;</option> -->
                            </select>

                            
                           
                        </div>

                        </div>
        
                    </div>                    
                            
                </div>
        
            </div>

            
              

</div>

<!--  For smaller screens -->

<!--<div class="d-block d-md-none container-fluid header pt-1 " > -->
 <div class="small-screen-div container-fluid header mb-2 " >

    <div class="scroll-container mb-2">
        <div class="scroll-content">
          <!-- Your content here -->
          <span style="color: black;font-size: 14px;" >Coming soon :&nbsp;</span> <span style="color: rgb(5, 5, 85);font-size: 14px;">&nbsp;&nbsp; Booking of hotel rooms, private chartered flights, cruise liners, decorators & around 30 more vendor categories. </span>
          
        </div>
    </div>

    <div class="header-gradient-style row flex items-center">

        <div class="row">

            <!--Column for Logo -------->
            <div class="col-5">
                    <div class="flex justify-start place-items-center clickable" >
                          <img routerLink="/" src="../../assets/images/Logo/Logo2.jpeg" >
                    </div>
            </div>

            <!----------------------   Sign in ------------------->
            <div class="col-3 flex justify-end">
                <div class="soulsWedNormalFontMobile flex justify-end items-center">

                    <div class=" flex justify-center items-center">
                      
                        <div class="flex justify-center items-center">
                            <div class="clickable" [matMenuTriggerFor]="userMenu2" *ngIf="userName !== 'Sign in'">
                             <!-- <span class="material-icons user-icon text-srh-orange">person</span> -->
                            <!--  <span class="soulsWedNormalFont dropdown">{{userName}}</span>-->
                            <span class="soulsWedNormalFontMobile" [attr.title]="userName">{{ userName.length > 6 ? (userName.substr(0, 4) + '..') : userName }}</span>
                            
                              <span class="material-icons user-icon">arrow_drop_down</span>
                            </div>

                            <div class="clickable" (click)="openSignInDialog()" *ngIf="userName === 'Sign in'">
                             <!-- <span class="material-icons user-icon text-srh-orange">person</span>-->
                              <span class="soulsWedNormalFontMobile ">{{userName}}</span>
                              
                            </div>  
                        </div>
                          
                        <mat-menu #userMenu2="matMenu" class="p-1">
                            <button class="dropdown-item px-1">{{userName}}</button>
                            <hr>

                            <div *ngIf="isVendor">
                                <button  routerLink="/registervendor" class="dropdown-item">Add a new business</button>
                                <br><button class="soulswedmenuitem" (click)="goToUserVendors()">Manage your business</button>
                                <br><button class="soulswedmenuitem" (click)="goToVendorCalendar()">Manage vendor calendar</button>
                                <br><button class="soulswedmenuitem" (click)="goToUserFavourites()">View Favourites</button>
                                <button class="p-1 soulswedmenuitem"  mat-menu-item (click)="goToChangePassword()">Change Password</button>
                            </div>

                            <div *ngIf="isCustomer">
                                
                                <button class="p-1 soulswedmenuitem"  mat-menu-item (click)="goToUserFavourites()">View Favourites</button>
                                <button class="p-1 soulswedmenuitem"  mat-menu-item (click)="goToChangePassword()">Change Password</button>

                            </div>
                            
                            <div *ngIf="isAdmin">
                                <button class="p-1 soulswedmenuitem" mat-menu-item (click)="registerUser()">Add User</button>
                                <br><button class="p-1 soulswedmenuitem" mat-menu-item routerLink="/manageusers">Manage Users</button>                                   
                                <br><button class="p-1 soulswedmenuitem" mat-menu-item routerLink="/registervendor">Add Vendor</button>
                                <br><button class="p-1 soulswedmenuitem" mat-menu-item (click)="goToUserVendors()">Manage Vendors</button>
                                <br><button class="p-1 soulswedmenuitem" mat-menu-item (click)="goToUserVendors()">Manage Vendors</button>
                                <br><button class="p-1 soulswedmenuitem" mat-menu-item (click)="goToVendorCalendar()">Manage Vendor Calendar</button>
                                <br><button class="p-1 soulswedmenuitem" mat-menu-item routerLink="/viewtransactions">View Transactions</button>
                                <hr>
                                
                                <button class="p-1 soulswedmenuitem"  mat-menu-item (click)="goToUserFavourites()">View Favourites</button>
                                <button class="p-1 soulswedmenuitem"  mat-menu-item (click)="goToChangePassword()">Change Password</button>
                            </div>

                            <hr>
                            <button class="p-1 soulswedmenuitem" mat-menu-item (click)="logOff()">Log Off</button>

                        </mat-menu>      
                    </div>
                </div>
            </div>

            <div class="col-3 d-flex flex-column justify-content-center">

                <select class="form-select rounded-select-mobile" id="currency" [(ngModel)]="selectedCurrencyData" (ngModelChange)="onCurrencySelected($event)">
                    <option *ngFor="let currency of currencyList" [ngValue]="currency" [title]="currency.currencyname">{{currency.currencycode}}&nbsp;</option>
                </select>

                <!--
                <button class="currency-button-style-mobile" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ selectedCurrency }}
                </button>
                -->

            </div>

           

            <!--      Drop Down Menu -->
            <div class="col-1 flex justify-end ">
                
                <button class=" navbar-toggler soulsWedToggler" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="navbar-toggler-icon soulsWedMenuFont"></span>
                </button>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
             
                 <!--   <li><a class="dropdown-item clickable" (click)="goToHotels()">Rooms</a></li>-->
                    <li><a class="dropdown-item clickable" (click)="goToVenues()">Venues</a></li>
                    <li><a class="dropdown-item clickable" (click)="goToPlanners()">Planners</a></li>
                 <!--   <li><a class="dropdown-item clickable" (click)="goToCharters()">Charters</a></li>-->
                    <li><a class="dropdown-item clickable" (click)="goToPhotograhers()">Photographers</a></li>
                    <li><a class="dropdown-item clickable" (click)="goToDecorators()">Decorators</a>
                    <li><a class="dropdown-item clickable" (click)="goToMakeupArtists()">Makeup Artists</a>
                    <hr>  
                    <li><a class="dropdown-item clickable" (click)="goToSakhiService()">Sakhi Service</a> 

                     <hr>

                    <!-- <li><a class="dropdown-item clickable" (click)="goToCaterers()">Caterers</a>                
                    <button  routerLink="/registervendor" class="dropdown-item">Register as vendor</button>
                     -->
                    
                </ul>

            </div>

             

        </div>

    </div> 

   
</div>


       
        

       <!----------------------------------------------------------------------------------->

       <!--
        <div class="col-md-1"></div>

        <div class="col-md-4  px-2 d-none d-md-block flex items-center" >  
        
                    <div class="soulsWedNormalFont flex justify-end items-center">

                        <div class=" flex justify-center items-center">
                          
                            <div class="flex justify-center items-center">
                                <div class="clickable" [matMenuTriggerFor]="userMenu" *ngIf="userName !== 'Sign in'">
                                  <span class="material-icons user-icon text-srh-orange">person</span>
                                  <span class="soulsWedNormalFont">{{userName}}</span>
                                </div>
                                <div class="clickable" (click)="openSignInDialog()" *ngIf="userName === 'Sign in'">
                                  <span class="material-icons user-icon text-srh-orange">person</span>
                                  <span class="soulsWedNormalFont">{{userName}}</span>
                                </div>
                              </div>
                              
                              <mat-menu #userMenu="matMenu" class="p-2">
                                <button class="p-1 soulswedmenuitem" mat-menu-item (click)="logOff()">Log Off</button>
                                
                              </mat-menu>
                              
                          

                         <div class="soulsWedNormalFont flex items-center pl-6">
                            <span class="material-icons text-srh-orange">
                                store
                            </span>         
                                <button  routerLink="/registervendor">&nbsp;Register as vendor</button>
                         </div> 

                         <div class="flex justify-center items-center pl-6">
                            <button class="currency-button-style" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ selectedCurrency }}
                            </button>
                           
                        </div>

                        </div>
        
                    </div>                    
                            
                </div>
        
            </div>

</div>

-->



        