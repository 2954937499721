import { Component,OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { UserData,CountryData} from '../../../models/datamodels';
import { UserServiceService } from '../../../services/user-service.service';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from '../../usermgmt/sign-in/sign-in.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-userslisting',
  standalone: true,
  imports: [MatCardModule,CommonModule,RouterModule],
  templateUrl: './userslisting.component.html',
  styleUrl: './userslisting.component.css'
})

export class UserslistingComponent implements OnInit
{
  
  usersListOriginal:UserData[]=[];
  usersList:UserData[]=[];  
  countriesList:CountryData[] =[];  
  
  constructor(private userdataservice:UserServiceService,private masterDataService:MasterdataserviceService,private router:Router ) {}

  ngOnInit(): void 
  {
    this.userdataservice.getUsers().subscribe(data=>
    {
        this.usersListOriginal=data;
        this.updateDisplayedVendors();        
    })

    //---------- Get countries list -------------------

    this.masterDataService.getCountries().subscribe
    (
      data=>
        {
          this.countriesList=data;
        }
    )
      
  }

  updateDisplayedVendors(): void 
  {
    this.usersList=this.usersListOriginal;
  }

  editUser(user:UserData)
  {
    console.log('Edit called for user:',user);
    this.router.navigate(['/edituser',user.iduser])
  }

  deleteUser(user:UserData)
  {
    console.log('Delete called for user:',user);

    this.userdataservice.deleteUser(user.iduser).subscribe(
      {
       

        next: (response:any) => 
        {
          
          console.log('Rows affected:',response.data);
          alert('User deleted');

          this.userdataservice.getUsers().subscribe(data=>
          {
                this.usersListOriginal=data;
                this.updateDisplayedVendors();        
          })
          
  
        }
        ,
        error: (error:any) => 
        {
          console.error('There was an error:',error.data);
          alert('There was an error')
          
        }
  });
    
  }
}
