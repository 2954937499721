
<div class="container-flux justify-center pt-2">

    <div *ngIf="filteredVendorsList.length; else noDataTemplate" class="vendor-card">
        
        <div class="pb-2"></div>
        <span class="font-medium"> Options Available : {{filteredVendorsList.length}}</span>
        <div class="pb-2"></div>

        <!-- Print Side filter values 
        <br><br>
        <span class="pb-2 font-medium"> Max Price : {{maxPrice}}</span>
        <span class="pb-2 font-medium"> &nbsp;&nbsp;Show Three Star : {{threeStar}}</span>
        <span class="pb-2 font-medium"> &nbsp;&nbsp;Show Four Star : {{fourStar}}</span>
        <span class="pb-2 font-medium"> &nbsp;&nbsp;Show Five Star : {{fiveStar}}</span>
        <span class="pb-2 font-medium"> &nbsp;&nbsp;Show lessthan500 : {{lessthan500}}</span>
        <span class="pb-2 font-medium"> &nbsp;&nbsp;Show between500and1000 : {{between500and1000}}</span>
        <span class="pb-2 font-medium"> &nbsp;&nbsp;Show between1000nd2000 : {{between1000nd2000}}</span>
        <span class="pb-2 font-medium"> &nbsp;&nbsp;Show greaterthan2000 : {{greaterthan2000}}</span>
        <br><br>
       -->


        <div *ngFor="let vendor of filteredVendorsList" class="row border justify-center items-center py-3">

            <div class="row justify-center items-center py-3">
    
                <div class="col-md-6">
                    <img [src]="serverpath + vendor.imagespath">  
                 
                         
                    <!-- code to get images from file 
                        <img src="../../assets/images/Hotels/hilton-istanbul-bomonti-1-600.jpg">  
                    http://localhost:3000/images/22/pexels-lukas-577210.jpg
                    -->         
                </div>
    
                <div class="col-md-4 flex items-center">
    
                    <div>
                        <h2>{{ vendor.vendorname }} <span *ngIf="vendor.vendorrating">({{ vendor.vendorrating }})</span></h2>
                        <p><b>Address:</b> {{ vendor.cityname }}</p>
                        <p *ngIf="isHotelRatingApplicable"><b>Rating:</b> {{ vendor.vendorrating }}</p>
                        <p *ngIf="isCapacityApplicable"><b>Capacity:</b> {{vendor.capacity}}</p>
                        <p>{{vendor.description}}</p>
                    </div>
                    
                </div>
    
                <div class="col-md-2 flex items-center justify-center">
    
                    <div>
                        
                        <!--
                        <h3><b>{{vendor.currency}} : {{ vendor.startingprice }} {{ vendor.pricebasis }}</b></h3>
                        -->
                        <h3><b>{{currencyCode}} : {{ convertCurrency(vendor.currency,currencyCode,vendor.startingprice) | number:'1.0-0'}} {{ vendor.pricebasis }}</b></h3>

                        <div class="row justify-center button-style-2">
                            <button routerLink="/vendordetails"  class="p-1"   (click)=" onVendorSelect(vendor)">View Details</button>
                        </div>
                       
                    </div>
                    
                </div>
    
            </div>

     

    </div>     


</div>

    <!-- Template to show if the list is empty 
    <ng-template #noDataTemplate>
        <div class="alert alert-warning" role="alert">
            No Options Available for the Selected Criteria
        </div>
    </ng-template>-->

    <!-- Template to show if the list is empty -->
    <ng-template #noDataTemplate>
        <div>
            No Options Available for the Selected Criteria
            <img src="../../../assets/images/Main Images/Old Unused/slider-01-1.png">
        </div>
    </ng-template>