

<div class="container ">

  <!-- For larger screens-->
  <div class=" d-none d-md-block row flex justify-center mr-3">

    <div class="row">

   

    <!-- Country Dropdown -->
    <div class="col-md-2 ">
      <label for="countrySelect" class="form-label soulsWedSearchFont">Country</label>
      <select class="form-select" id="countrySelect" [(ngModel)]="selectedCountry" (ngModelChange)="onCountrySelect($event)" [compareWith]="compareCountries">
        <option *ngFor="let country of countries" [ngValue]="country">{{ country.countryName}}</option>
      </select>
    </div>
    
    <!-- Category Dropdown -->
    <div class="col-md-3">
      <label for="categorySelect" class="form-label soulsWedSearchFont">Category</label>
      <select class="form-select" id="categorySelect" [(ngModel)]="selectedCategory" (ngModelChange)="onCategorySelect($event)" [compareWith]="compareCategories">
        <option *ngFor="let category of categories" [ngValue]="category">{{ category.categoryName }}</option>
      </select>
    </div>
    
    <!-- Date Range Picker -->
    <div class="col-md-3">
      <label for="dateRangeStart" class="form-label soulsWedSearchFont">Date Range</label>
      <div > <!--class="d-flex"-->
        <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="startDate" (change)="onStartDateChange()">
        <input type="date" class="form-control" id="endDate" [(ngModel)]="endDate" [min]="startDate">
      </div>
    </div>

   
    
    <!-- Guests -->
    <div class="col-md-2 ">
      <label for="guests" class="form-label soulsWedSearchFont">Guests</label>
      <input type="number" class="form-control text-right" id="guests" [(ngModel)]="guests" min="1">
    </div>
    
    <!-- Search Button -->
    <div class="col-md-2 d-flex align-items-center">
     <!-- <button class="bg-yellow-600 w-100 py-2 text-white" (click)="search()">Search</button>-->
      <button class="button-style-1" (click)="search()">Search</button>
    </div>
  </div>

  <div class="row">

    <div class="col-md-2"></div>

    <div class="col-md-8 text-red-800 mb-0 pb-0 bg-orange-200">
      {{errorMessage}}
    </div>

    <div class="col-md-2"></div>

  </div>

  </div>



  <!-- For smaller screens-->
  <div class=" d-block d-md-none row flex justify-center py-1" style="text-align: center">

    <div class="row">

        <!-- Country Dropdown -->
        <div class="col-8" >
            <label for="countrySelect" class="form-label soulsWedSearchFontSmall" >Country</label>
            <select class="form-select rounded-select" id="countrySelect" [(ngModel)]="selectedCountry" (ngModelChange)="onCountrySelect($event)" [compareWith]="compareCountries">
                <option *ngFor="let country of countries" [ngValue]="country">{{ country.countryName}}</option>
            </select>
        </div>

        <!-- Guests -->
      <div class="col-4">
        <label for="guests" class="form-label soulsWedSearchFontSmall ">Guests</label>
        <input type="number" class="form-control text-right rounded-select" id="guests" [(ngModel)]="guests" min="1">
      </div>
    
       
    </div>

    

  <div class="row pt-4">
       <!-- Category Dropdown -->
     <div class="col-12">
      <label for="categorySelect" class="form-label soulsWedSearchFontSmall">Category</label>
      <select class="form-select rounded-select" id="categorySelect" [(ngModel)]="selectedCategory" (ngModelChange)="onCategorySelect($event)" [compareWith]="compareCategories">
          <option *ngFor="let category of categories" [ngValue]="category">{{ category.categoryName }}</option>
      </select>
     </div>
  </div>

  <div class="row pt-4">
       <!-- Date Range Picker -->
      <div class="col-12">
          <label for="dateRangeStart" class="form-label soulsWedSearchFontSmall">Date Range</label>
         <div class="row">
            <div class="col-12"><input type="date" class="form-control me-2 rounded-select" id="startDate" [(ngModel)]="startDate" (change)="onStartDateChange()"></div>
            <div class="col-12 pt-1"> <input type="date" class="form-control rounded-select" id="endDate" [(ngModel)]="endDate" [min]="startDate"></div>
        </div>
      </div>
  <div>

  <div class="row pt-4">
       <!-- Search Button -->
    <div class="col-12 d-flex align-items-end justify-center">
      <!-- <button class="bg-yellow-600 w-100 py-2 text-white" (click)="search()">Search</button>-->
       <button class="button-style-2" (click)="search()">Search</button>
     </div>
  </div>


  
   
    
   


  <div class="row">

    <div class="col-md-2"></div>

    <div class="col-md-8 text-red-800 mb-0 pb-0 bg-orange-200">
      {{errorMessage}}
    </div>

    <div class="col-md-2"></div>

  </div>

</div>

</div>
