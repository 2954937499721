<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

<div class="container-fluid ">
    
    <div class="row px-4 position-relative pb-2" >        

        <div class=" d-none d-md-block pt-3 " >

            <div class="col-md-12">
                <span class="flex justify-center soulsWedHeadingFont">Wedding Categories</span>
            </div>
            
            <div class="row py-6" style="height: 100px; ">

                <div class="col-1"></div>

                <div class="col-md-5 border-1"> 

                    <div class="row clickable" (click)="goToHotels()" style="height: 100px;background-color:rgb(246, 211, 86)">
                        <div class="col-md-6 d-flex align-items-center justify-content-center " > 
                            <h1>Rooms</h1>
                        </div>
        
                        <div class="col-md-6 d-flex align-items-center justify-content-end" >
                            <img src="../../assets/images/Accordian/Hotel.jpg" class="clickable rounded-left negative-margin" >
                        </div>
                    </div>
                    

                </div>

                <div class="col-md-5 border-1 ml-2">

                    <div class="row clickable" (click)="goToVenues()"  style="height: 100px;background-color:#EED9C4">
                        <div class="col-md-7 d-flex align-items-center justify-content-center">
                            <h1>Venues</h1>
                        </div>
        
                        <div class="col-md-5 d-flex align-items-center justify-content-end" >
                            <img src="../../assets/images/Accordian/Venue.jpg" class="clickable rounded-left negative-margin" >
                        </div>
                    </div>
                    

                </div>

                <div class="col-1"></div>

                

            </div>


        </div>

        <div class=" d-none d-md-block " >
            
            <div class="row pt-8" style="height: 100px; ">

                <div class="col-1"></div>
                
                <div class="col-md-5 border-1">

                    <div class="row clickable" (click)="goToPhotograhers()" style="height: 100px;background-color:rgb(192, 242, 247)"> <!-- #dbe5f2-->
                        <div class="col-md-8 d-flex align-items-center justify-content-center"> 
                            <h1>Photographers</h1>
                        </div>
        
                        <div class="col-md-4 d-flex align-items-center justify-content-end" >
                            <img src="../../assets/images/Accordian/Photographers.jpg" class="clickable rounded-left negative-margin" >
                        </div>
                    </div>
                    

                </div>

          

                <div class="col-md-5 border-1 ml-2">

                    <div class="row clickable" (click)="goToMakeupArtists()" style="height: 100px;background-color:rgb(246, 211, 86)">
                        <div class="col-md-7 d-flex align-items-center justify-content-center">
                            <h1>Makeup Artists</h1>
                        </div>
        
                        <div class="col-md-5 d-flex align-items-center justify-content-end" >
                            <img src="../../assets/images/Accordian/Makeupartists.jpg" class="clickable rounded-left negative-margin" >
                        </div>
                    </div>
                    

                </div>

                <div class="col-1"></div>
                

            </div>


        </div>

        <div class=" d-none d-md-block " >
            
            <div class="row pt-10" style="height: 100px; ">

                <div class="col-1"></div>

                <div class="col-md-5 border-1">

                    <div class="row clickable" (click)="goToPlanners()" style="height: 100px;background-color:#EED9C4">
                        <div class="col-md-7 d-flex align-items-center justify-content-center">
                            <h1>Planners</h1>
                        </div>
        
                        <div class="col-md-5 d-flex align-items-center justify-content-end" >
                            <img src="../../assets/images/Accordian/Planners.jpg" class="clickable rounded-left negative-margin" >
                        </div>
                    </div>
                    

                </div>

          
 
                <div class="col-md-5 border-1 pl-2 ml-2">

                    <div class="row clickable" (click)="goToCharters()" style="height: 100px;background-color:rgb(237, 155, 105)">
                        <div class="col-md-7 d-flex align-items-center justify-content-center">
                            <h1>Charters</h1>
                        </div>
        
                        <div class="col-md-5 d-flex align-items-center justify-content-end" >
                            <img src="../../assets/images/Accordian/Charters.jpg" class="clickable rounded-left negative-margin" >
                        </div>
                    </div>
                    

                </div>

                <div class="col-1"></div>

            </div>


        </div>


     <!--   For Smaller Screens -->

     <div class=" d-block d-md-none pt-3 " >

        <div class="col-md-12">
            <span class="flex justify-center soulsWedHeadingFont">Wedding Categories</span>
        </div>
        
        <div class="row py-6" style="height: 100px; ">            
            <div class="col-12 border-1"> 

                <div class="row clickable" (click)="goToHotels()" style="height: 100px;background-color:rgb(246, 211, 86)">
                    <div class="col-7 d-flex align-items-center justify-content-center " > 
                        <h1>Rooms</h1>
                    </div>
    
                    <div class="col-5 d-flex align-items-center justify-content-end" >
                        <img src="../../assets/images/Accordian/Hotel.jpg" class="clickable rounded-left negative-margin fill-height" >
                    </div>
                </div>
                
            </div>
        </div>

        <div class="row py-6 pt-8" style="height: 100px; "> <!-- rgba(246, 244, 183, 0.953) -->
            <div class="col-12 border-1"> 
                <div class="row clickable" (click)="goToVenues()"  style="height: 100px;background-color:#EED9C4">
                    <div class="col-7 d-flex align-items-center justify-content-center">
                        <h1>Venues</h1>
                    </div>
    
                    <div class="col-5 d-flex align-items-center justify-content-end" >
                        <img src="../../assets/images/Accordian/Venue.jpg" class="clickable rounded-left negative-margin fill-height" >
                    </div>
                </div>

            </div>
           
        </div>


    </div>

    <div class=" d-block d-md-none " >
        
        <div class="row pt-10" style="height: 100px; ">

            
            <div class="col-12 border-1">

                <div class="row clickable" (click)="goToPhotograhers()" style="height: 100px;background-color:rgb(192, 242, 247)">
                    <div class="col-7 d-flex align-items-center justify-content-center">
                        <h1>Photographers</h1>
                    </div>
    
                    <div class="col-5 d-flex align-items-center justify-content-end" >
                        <img src="../../assets/images/Accordian/Photographers.jpg" class="clickable rounded-left negative-margin fill-height" >
                    </div>
                </div>
            
            </div>
        </div>
      
        <div class="row pt-12" style="height: 100px; ">

            <div class="col-12 border-1 ">

                <div class="row clickable" (click)="goToMakeupArtists()" style="height: 100px;background-color:rgb(246, 211, 86)">
                    <div class="col-7 d-flex align-items-center justify-content-center">
                        <h1>Makeup Artists</h1>
                    </div>
    
                    <div class="col-5 d-flex align-items-center justify-content-end" >
                        <img src="../../assets/images/Accordian/Makeupartists.jpg" class="clickable rounded-left negative-margin fill-height" >
                    </div>
                </div>
                

            </div>

            
        </div>


    </div>

    <div class=" d-block d-md-none " >
        
        <div class="row pt-14" style="height: 100px; ">

            
            <div class="col-12 border-1">

                <div class="row clickable" (click)="goToPlanners()" style="height: 100px;background-color:#EED9C4">
                    <div class="col-7 d-flex align-items-center justify-content-center">
                        <h1>Planners</h1>
                    </div>
    
                    <div class="col-5 d-flex align-items-center justify-content-end" >
                        <img src="../../assets/images/Accordian/Planners.jpg" class="clickable rounded-left negative-margin fill-height" >
                    </div>
                </div>
                

            </div>

      </div>

      <div class="row pt-16" style="height: 100px; ">

            <div class="col-12 border-1 ">

                <div class="row clickable" (click)="goToCharters()" style="height: 100px;background-color:rgb(237, 155, 105)">
                    <div class="col-7 d-flex align-items-center justify-content-center">
                        <h1>Charters</h1>
                    </div>
    
                    <div class="col-5 d-flex align-items-center justify-content-end" >
                        <img src="../../assets/images/Accordian/Charters.jpg" class="clickable rounded-left negative-margin fill-height" >
                    </div>
                </div>
                

            </div>

           

        </div>


    </div>
     

    

 </div>
