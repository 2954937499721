import { Component } from '@angular/core';
import {Header2Component} from '../header2/header2.component'
import { FootersectionComponent } from '../footersection/footersection.component';


@Component({
  selector: 'app-hotelregistration',
  standalone: true,
  imports: [Header2Component,FootersectionComponent],
  templateUrl: './hotelregistration.component.html',
  styleUrl: './hotelregistration.component.css'
})

export class HotelregistrationComponent {
  selectedCurrency: string = 'INR'; // Default currency
  selectedPricebasis: String='Per Day';

  isRestaurentChecked:boolean=false;

  setCurrency(currency: string): void {
    this.selectedCurrency = currency;
  }

  setPricebasis(pricebasis: string): void {
    this.selectedPricebasis = pricebasis;
  }


}
