
    
   <app-header2></app-header2>

   <div class=" d-block d-md-none">
      <div class="row pt-16 pb-3">
         <img mat-card-image src="../../assets/images/PageImages/planners.jpg">
      </div>
   </div>

   <div class=" d-none d-md-block">
      <div class="row pt-28 pl-5 pb-3">
         <img mat-card-image src="../../assets/images/PageImages/planners.jpg">
      </div>
   </div>

   <app-managevendorcalendardetails></app-managevendorcalendardetails>

   <div class="row p-5"></div>

   <app-footersection></app-footersection>