import { Component,OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Header2Component } from '../header2/header2.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-privacypolicy',
  standalone: true,
  imports: [Header2Component,FootersectionComponent,GobackComponent],
  templateUrl: './privacypolicy.component.html',
  styleUrl: './privacypolicy.component.css'
})
export class PrivacypolicyComponent implements OnInit
{
  externalHtml: string | null = null;

constructor(private http:HttpClient)
{}

ngOnInit(): void 
{
  this.loadExternalHtml();
}

  loadExternalHtml(): void 
  {
    this.http.get("../../assets/policydocuments/privacypolicy.html", { responseType: 'text' })
    .subscribe(data => {
        this.externalHtml = data;
      });

   }


}
