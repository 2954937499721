import { Component } from '@angular/core';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-imgcarousel',
  standalone: true,
  imports: [CarouselModule,CommonModule],
  templateUrl: './imgcarousel.component.html',
  styleUrl: './imgcarousel.component.css'
})
export class ImgcarouselComponent 
{

  slides = 
  [
    { image: '../../assets/images/Main Images/Slider Images/img1.png', text: 'First Slide' },
    { image: '../../assets/images/Main Images/Slider Images/img2.jpg', text: 'Second Slide' },
    { image: '../../assets/images/Main Images/Slider Images/img3.jpg', text: 'Third Slide' },
    { image: '../../assets/images/Main Images/Slider Images/img4.jpg', text: 'Fourth Slide' }
  ]

}
