import { Component,OnInit } from '@angular/core';
import { CategoryData, CountryData, SearchData, VendorSummaryData } from '../../models/datamodels';
import { VendorDataServiceService } from '../../services/vendor-data-service.service';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { CommonModule } from '@angular/common';
import{MatCardModule} from '@angular/material/card'
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-searchmobile',
  standalone: true,
  imports: [MatCardModule,CommonModule,RouterModule,SearchComponent],
  templateUrl: './searchmobile.component.html',
  styleUrl: './searchmobile.component.css'
})
export class SearchmobileComponent implements OnInit
{
  vendorsListOriginal:VendorSummaryData[]=[];
  vendorsList:VendorSummaryData[]=[];
  serverpath= environment.middletierhost+'/uploads';  //---serverpath for images----      
  currentIndex: number = 0;        

  constructor(private vendorDataService:VendorDataServiceService){}

  ngOnInit(): void 
  {
      this.vendorDataService.getHotels().subscribe
      (data=>
      {
        this.vendorsListOriginal=data;       
        console.log('Hotels list revieved with number ' , data.length);
        console.log('Hotels list copied with number ' , this.vendorsListOriginal.length);

        this.updateDisplayedVendors();
        
      }) 
  }

  updateDisplayedVendors(): void 
  {
    //this.vendorsList = this.vendorsListOriginal.slice(this.currentIndex, this.currentIndex + 3);
    this.vendorsList = this.vendorsListOriginal;
  }

  nextSlide(): void 
  {
    if (this.currentIndex + 3 < this.vendorsListOriginal.length) 
    {
      this.currentIndex += 3;
      this.updateDisplayedVendors();
    }
  }

  previousSlide(): void 
  {
    if (this.currentIndex - 3 >= 0) 
    {
      this.currentIndex -= 3;
      this.updateDisplayedVendors();
    }
  }

  //============== Function to updateselected country ============================
  onVendorSelect(selected: VendorSummaryData): void 
  {
  
    this.vendorDataService.changeData(selected);
    
  }

  getStars(rating:string):string
  {
    if (rating=='5 star')
      return '*****'
      else if (rating=='4 star')
      return '****'
      else if (rating=='3 star')
        return '***'
      else if (rating=='2 star')
        return '**'
      else if (rating=='1 star')
        return '*'
      else
        return '';
  }
}
