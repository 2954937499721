import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { DecimalPipe } from '@angular/common';
import { RazorpayErrorResponse,RazorpayPaymentResponse } from '../../../models/datamodels';
import { parse } from 'date-fns';
import { Router } from '@angular/router';


declare var Razorpay:any;

@Component({
  selector: 'app-paymodal',
  standalone: true,
  imports: [ReactiveFormsModule,FormsModule,CommonModule],
  templateUrl: './paymodal.component.html',
  styleUrl: './paymodal.component.css',
  providers: [DecimalPipe]
})




export class PaymodalComponent implements OnInit 
{

  @Input() data: any;
  @Output() action = new EventEmitter<{ action: string, data?: any }>();

  baseprice: number=0;
  guests: number=0;
  days: number=0;
  tax: number=0;
  totalBeforeTax: number=0;
  totalAfterTax: number=0;
  currency:string='USD';
  pricebasis:string='per guest';
  idvendor:number=0;
  iduser:number=0;
  vendorname:string='';
  username:string='';
  useremail:string='';
  transactiondate:string='';
  finalamount:number=0;

  mindate:string='';

  startDate:Date|null=null;
  startDateString:String|null=null;

  endDate:Date|null=null;
  endDateString:String|null=null;

  isAvailable:boolean=false;
  availMessage:String='';



  constructor(private vendorDataService:VendorDataServiceService,private decimalPipe: DecimalPipe,private router:Router){}

  ngOnInit(): void 
  {

    const today = new Date();
    this.mindate = today.toISOString().split('T')[0];

    this.baseprice = this.data.baseprice;
    this.guests = this.data.guests;
    this.days = this.data.days;
    this.tax = this.data.tax;
    this.pricebasis=this.data.pricebasis;
    this.currency=this.data.currency;
    this.iduser=this.data.iduser;
    this.idvendor=this.data.idvendor;
    this.vendorname=this.data.vendorname;
    this.username=this.data.username;
    this.useremail=this.data.useremail;

    this.calculateTotals();
  }

  calculateTotals() 
  {
    console.log('called calculateTotals()');
    console.log('pricebasis', this.pricebasis);


    if(this.pricebasis=='Per Day')
      {
         this.totalBeforeTax=this.baseprice*this.days;
      }

      if(this.pricebasis=='Per Person')
      {

          this.totalBeforeTax=this.baseprice*this.guests;
      }

      if(this.pricebasis=='Per Engagement')
      {
 
        this.totalBeforeTax=this.baseprice;
      }

   
    this.totalAfterTax = this.totalBeforeTax + (this.totalBeforeTax * this.tax / 100);
  }

  onPay() 
  {
    this.makePayment();
    
  }

  onCancel() {
    this.action.emit({ action: 'cancel' });
  }

  makePayment()
    {
    
      //const amount=this.totalAfterTax*100;

      this.finalamount=this.totalAfterTax*100;
      this.formatNumber();

      if(this.finalamount>0)
      {

          const successCallBack = (paymentId: RazorpayPaymentResponse)=>
          {
              console.log('Payment successful with payment id :',paymentId.razorpay_payment_id);

            this.vendorDataService.postTransaction(
                {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.totalAfterTax,vendorname:this.vendorname,username:this.username,useremail:this.useremail,transactiondate:'',currency:this.currency,bookingstart:'',bookingend:''
                  
              
                });

            // Close the Razorpay modal manually if needed
            //rzp.close();
            this.onCancel();
           this.router.navigate(['/viewreceipt']);
              
          }

          const RazorpayOptions=
          {
            description: "Soulswed Payment",
            currency:this.currency,
            amount:this.finalamount,
            name:'SoulsWed',
           // key:'rzp_test_cpXOU6uqzQHCc6',
           key:'rzp_live_dxvVlB5dXdaJNO',    // live key
            image:'../../../assets/images/Logo/Faveicon.png',
         
            prefill:
              {
                  name:'',
                  email:'',
                  phone:''
              },
            theme:
              {
                color:'#ebe834'   //#f37254'
              },
            handler: (response: RazorpayPaymentResponse) => 
              {
                console.log('Payment success event:', response);
                successCallBack(response);
              },
              
            modal:
              {
                ondismiss:()=>
                  {
                    console.log('dismissed')
                  }
              }
          }
  
          
  
          const failureCallBack = (error: RazorpayErrorResponse) => 
            {
                console.log('Payment failed');
               // console.log(error.error);
            };
  
          ///Razorpay.open(RazorpayOptions,successCallBack,failureCallBack);
          console.log('Called Razor pay for payment ....')

          const rzp = new Razorpay(RazorpayOptions);

       /*   rzp.on('payment.success', (response: RazorpayPaymentResponse) => {
            console.log('Payment success event:', response);
            successCallBack(response);
          });*/

          rzp.on('payment.error', (response: RazorpayErrorResponse) => {
            console.log('Payment error event:', response);
            failureCallBack(response);
          });

          rzp.open();

        
      }
      else
      {
        alert('No amount to pay')
      }
     
      
    }


    formatNumber() 
    {
      this.baseprice = parseFloat(this.decimalPipe.transform(this.baseprice, '1.0-0')!.replace(/,/g, ''));
      this.finalamount = parseFloat(this.decimalPipe.transform(this.finalamount, '1.0-0')!.replace(/,/g, ''));
    }

    checkAvailability()
    {
        if (!this.startDateString || !this.endDateString)
        {
          this.availMessage='Please select start and end dates';
          this.startDateString=null;
          this.endDateString=null;
          return;
        }


        this.startDate=parse(this.startDateString.toString(),"yyyy-MM-dd",new Date());
        this.endDate=parse(this.endDateString.toString(),"yyyy-MM-dd",new Date());

      

      console.log('Startdate :',this.startDate);
      console.log('Enddate:',this.endDate);

     this.startDate=this.convertToUTC(this.startDate);
     this.endDate=this.convertToUTC(this.endDate);
/*
      this.vendorDataService.checkVendorAvailability(this.idvendor,this.startDate,this.endDate).subscribe(data=>
      {
        if(data.length>0)
        {
          this.isAvailable=false;
          this.availMessage='No availability on specified dates';
        }
        else
        {
          this.isAvailable=true;
          this.days=this.calculateDaysBetween(this.startDate!,this.endDate!);
          this.availMessage='Dates are available. Please proceed with the payment';
          this.calculateTotals();
        }
      }
      )
*/

      
    }


    convertToUTC(date: Date): Date 
    {
      console.log('convertToUTC: date recieved :',date);

      /*
      // Check if the date is already a Date object
      if (!(date instanceof Date)) 
      {
          date = new Date(date); // Convert the string to a Date object
      }

      console.log('convertToUTC: date passed to UTC function :',date);

      

      // Convert to UTC by subtracting the local timezone offset
      const utcDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
     */

    // Convert the date to UTC
      const utcDate = new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      ));
    
      console.log('convertToUTC: UTC date formed :',utcDate);
      // Format the UTC date as a string if needed
      return utcDate;
    }

    calculateDaysBetween(startDate: Date, endDate: Date): number 
    {
      // Ensure the inputs are Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);
    
      // Calculate the difference in time (in milliseconds)
      const differenceInTime = end.getTime() - start.getTime();
    
      // Convert the difference from milliseconds to days
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    
      return differenceInDays+1;
    }

}

