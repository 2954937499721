import { Component } from '@angular/core';


//----- import angular material -------
import{MatCardModule} from '@angular/material/card'

@Component({
  selector: 'app-vendorslisting',
  standalone: true,
  imports: [MatCardModule],
  templateUrl: './vendorslisting.component.html',
  styleUrl: './vendorslisting.component.css'
})
export class VendorslistingComponent {

}
