import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { PhotographerslistingComponent } from './photographerslisting/photographerslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import {MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-photographers',
  standalone: true,
  imports: [GobackComponent,Header2Component,PhotographerslistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './photographers.component.html',
  styleUrl: './photographers.component.css'
})
export class PhotographersComponent {

}
