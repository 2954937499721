import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { UservendorlistingComponent } from '../home/uservendorlisting/uservendorlisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-uservendor',
  standalone: true,
  imports: [GobackComponent,Header2Component,UservendorlistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './uservendor.component.html',
  styleUrl: './uservendor.component.css'
})
export class UservendorComponent 
{

}
