import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accordian',
  standalone: true,
  imports: [MatCardModule,CommonModule],
  templateUrl: './accordian.component.html',
  styleUrl: './accordian.component.css'
})
export class AccordianComponent 
{
  constructor(private router:Router){}

  goToVenues()
  {

     this.router.navigate(['/venues']);

  }

  goToHotels()
  {
       this.router.navigate(['/hotels']);
  }

  goToPlanners()
  {
    this.router.navigate(['/planners']);
  }

  goToCaterers()
  {
    this.router.navigate(['/caterers']);
  }


  goToCharters()
  {
    this.router.navigate(['/charters']);
  }

  goToDecorators()
  {
    this.router.navigate(['/decorators']);
  }

  goToPhotograhers()
  {
    this.router.navigate(['/photographers']);
  }

  goToMakeupArtists()
  {
    this.router.navigate(['/makeupartists']);

  }
}
