import { Component, OnInit } from '@angular/core';
import { CategoryData, CountryData, SearchData, VendorSummaryData,ExchangeRateData, CurrencyData } from '../../../models/datamodels';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { FilterDataServiceService } from '../../../services/filter-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { differenceInDays } from 'date-fns';
import { environment } from '../../../environments/environment';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';


@Component({
  selector: 'app-vendorsummarydisplay',
  standalone: true,
  imports: [CommonModule,RouterModule],
  templateUrl: './vendorsummarydisplay.component.html',
  styleUrl: './vendorsummarydisplay.component.css'
})


export class VendorsummarydisplayComponent implements OnInit
{
  
  // ------ List of entities -----------------

  vendorsList:VendorSummaryData[]=[];
  countriesList:CountryData[]=[];
  categoriesList:CategoryData[]=[];

  filteredVendorsList:VendorSummaryData[]=[];

  fxList:ExchangeRateData[]=[];

  //---------serverpath -------------

  serverpath= environment.middletierhost+'/uploads';                     //'http://localhost:3000';


  //---------Main Filter values -----------------------
  selectedCountry:CountryData|null=null;
  selectedCategory:CategoryData|null=null;
  selectedCurrency:CurrencyData|null=null;
  startDateRange :Date|null=null;
  endDateRange:Date|null=null;
  days :number=0
  currencyCode:string='INR';

  //-------- Variables to check which sidebar filters are applicable -----

  isPriceRangeApplicable:boolean=true;
  isHotelRatingApplicable:boolean=true;
  isCapacityApplicable:boolean=true;

  //--------Sidebar filter values --------------------
  
  maxPrice:number=0;
  
  //------------ Variables for hotel ratings -------------------
  threeStar: boolean=true;
  fourStar:  boolean=true;
  fiveStar:  boolean=true;

  //------------ Variables for capacity -------------------
  lessthan500: boolean=true;
  between500and1000:  boolean=true;
  between1000nd2000:  boolean=true;
  greaterthan2000:  boolean=true;

  
  constructor(private vendorDataService:VendorDataServiceService,private filterDataService:FilterDataServiceService,private masterDataService:MasterdataserviceService,private environmentDataService : EnvironmentDataServiceService)
  {}

  ngOnInit(): void 
  {
    
    //this.vendorsList=this.vendorDataService.getVendors();
   // this.filteredVendorsList=this.vendorsList;

    //this.countriesList=this.masterDataService.getCountries();
    
    this.masterDataService.getCountries().subscribe
    (
      data=>this.countriesList=data
    );

    //this.categoriesList=this.masterDataService.getCategories();
    this.masterDataService.getCategories().subscribe
    (
     data=>this.categoriesList=data
    );

    this.environmentDataService.currentCurrency.subscribe(data=>
    {
      this.selectedCurrency=data;
      this.currencyCode=this.selectedCurrency?.currencycode!;

      this.masterDataService.getExchangeRates().subscribe(data=>
      {
          this.fxList=data;

          console.log('Exchange rates fetched :',this.fxList);
      });
    }
    )

    this.filterDataService.currentData.subscribe(data=>
    {
        if(data)
        {
             this.selectedCountry=data?.country;
             this.selectedCategory=data?.category;
             this.startDateRange=data?.startDate;
             this.endDateRange=data?.endDate;


             if(this.selectedCountry?.idcountry && this.selectedCategory?.idcategory)
            {
              this.vendorDataService.getVendors(this.selectedCountry?.idcountry,this.selectedCategory?.idcategory).subscribe
              (data=>
                {
                  this.vendorsList=data;
                  console.log('vendors list revieved with number ' , data.length);
                  console.log('vendors list copied with number ' , this.vendorsList.length);
                  this.filteredVendorsList=this.filterVendors();
                  console.log("filtered vendors list length ",this.filteredVendorsList.length)

                }
              )
            }
             

            
             if(this.startDateRange && this.endDateRange)
              {
                this.days=differenceInDays(this.endDateRange,this.startDateRange)+1;
              }

             console.log('Number of days is :',this.days)
  
        }
0   });

    this.filterDataService.currentSidebarData.subscribe(sidebardata=>
      {
        console.log('Sidebar data change recieved !!!');

        if(sidebardata)
        {
          this.maxPrice=sidebardata.maxPrice||0;
          
          this.threeStar=sidebardata.threeStar;
          this.fourStar=sidebardata.fourStar;
          this.fiveStar=sidebardata.fiveStar;

          this.lessthan500=sidebardata.lessthan500;
          this.between500and1000=sidebardata.between500and1000;
          this.between1000nd2000=sidebardata.between1000nd2000;
          this.greaterthan2000=sidebardata.greaterthan2000;

          this.isPriceRangeApplicable=sidebardata.showPriceRange;
          this.isHotelRatingApplicable=sidebardata.showRatingCheckboxes;
          this.isCapacityApplicable=sidebardata.showCapacityRanges;



          this.filteredVendorsList=this.filterVendors();

        }
      }
    )

    

  }

  filterVendors(): VendorSummaryData[]
   {
      let filteredList:VendorSummaryData[];
    
      
      if(this.selectedCountry && this.selectedCategory)
      {
        filteredList=this.vendorsList.filter
          (vendor => 
            vendor.idcountry === this.selectedCountry?.idcountry 
            && vendor.idcategory === this.selectedCategory?.idcategory
          );
      }
      else
          filteredList= this.vendorsList;

       console.log('filtered list after coountry category coount ',filteredList.length);

      //----------- Filter for max price ------------------
      console.log ('Max price :',this.maxPrice)

      if(this.isPriceRangeApplicable)
      {
            console.log ('Inside max price filter');
  
            filteredList=filteredList.filter
            (
              //vendor => vendor.startingprice <= this.maxPrice
              vendor => vendor.startingprice <= this.convertCurrency('INR',vendor.currency,this.maxPrice)
            )
          
      }

      
      console.log('filtered list after max price ',filteredList.length);
    
      //---------- Filter for hotel ratings ------------------

      if(this.isHotelRatingApplicable)
      {
          if(!this.threeStar)
          {
            filteredList=filteredList.filter
            (
              vendor => vendor.vendorrating != '3 Star'
            )
            
          }
        

          if(!this.fourStar)
            {
              filteredList=filteredList.filter
              (
                vendor => vendor.vendorrating != '4 Star'
              )
              
            }

            if(!this.fiveStar)
              {
                filteredList=filteredList.filter
                (
                  vendor => vendor.vendorrating != '5 star'
                )
                
              }
      }

      
      console.log('filtered list after star ratings count ',filteredList.length);

        //---------- Filter for capacity ------------------
        if(this.isCapacityApplicable)
        {

            if(!this.lessthan500)
            {
              filteredList=filteredList.filter
              (
                vendor => vendor.capacity >=500
              )

            }

            if(!this.between500and1000)
            {
                filteredList=filteredList.filter
                (
                  vendor => vendor.capacity <=500 || vendor.capacity >=1000
                )
  
            }

            if(!this.between1000nd2000)
            {
                  filteredList=filteredList.filter
                  (
                    vendor => vendor.capacity <=1000 || vendor.capacity >=2000
                  )
    
            }

            if(!this.greaterthan2000)
              {
                filteredList=filteredList.filter
                (
                  vendor => vendor.capacity <=2000
                )
  
              }
            
        }

        
       console.log('filtered list after capacity coount ',filteredList.length);



      return filteredList;
  }
  

  //============== Function to updateselected country ============================
  onVendorSelect(selected: VendorSummaryData): void 
  {
  
    this.vendorDataService.changeData(selected);
    
  }

  convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
     // console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

        const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


       //   console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

          //  console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }
    
}
