import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import {UserServiceService} from '../../../services/user-service.service'
import { UserData,CountryData,phoneCountryCode } from '../../../models/datamodels'
import {Header2Component} from '../../header2/header2.component'
import { FootersectionComponent} from '../../footersection/footersection.component'
import { CommonModule } from '@angular/common';
import { AbstractControl } from '@angular/forms';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { Router } from '@angular/router';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';

@Component({
  selector: 'app-add-user',
  standalone: true,
  imports: [ReactiveFormsModule,Header2Component,FootersectionComponent,CommonModule],
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.css'
})

export class AddUserComponent implements OnInit
{

  userForm!: FormGroup;
  countries :CountryData[] = [];
  phoneCountryCodes :phoneCountryCode[]=[];
  idUserAdded=0;
  showPassword:boolean=false;
  showConfirmPassword:boolean=false;

  usertypeList:string[]=['Customer','Vendor'];

  isAdmin:boolean=false;
 

  @Output() userCreated = new EventEmitter<UserData>();

  constructor(private router:Router,private fb: FormBuilder, private userService: UserServiceService,private masterdataservice:MasterdataserviceService,private envDataService:EnvironmentDataServiceService){}

  ngOnInit(): void 
  {
    //---------- Get masterlist of countries -------------------
    this.masterdataservice.getCountries().subscribe(data=>
      {
        this.countries=data;
      }
    )

    this.envDataService.currentUser.subscribe(data=>
      {
        if(data?.usertype=='Admin')
          this.usertypeList.push('Admin');
      }
    )

    

    
    this.phoneCountryCodes=this.masterdataservice.getCountryPhoneCodes();

    this.userForm = this.fb.group({
      usertype: ['', [Validators.required,Validators.maxLength(99)]],
      username: ['', [Validators.required,Validators.maxLength(99)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator]],
      confirmPassword: ['', [Validators.required]],
      idcountry: [],
     // phonecountrycode: ['', Validators.required],
      //phonenumber:['', [Validators.pattern('[0-9]{10,11}$')]]
      phonecountrycode: [],
      phonenumber:[]
    },{validator:this.confirmPasswordValidator});
  }

  //------------ Password validator ------------------------------
  passwordValidator(control: AbstractControl): { [key: string]: any } | null 
  {
    const valid = /^(?=.*\d)(?=.*[\W_])(?!.*['"]).{8,}$/.test(control.value);
    return valid ? null : { invalidPassword: 'Please enter password in the correct format' };
  }

  confirmPasswordValidator(group: FormGroup): { [key: string]: any } | null 
  {
    let pass = group?.get('password')?.value;
    let confirmPass = group?.get('confirmPassword')?.value;

    console.log('Passwd :',pass);
    console.log('confirmPass:',confirmPass);
    console.log('Result:',pass === confirmPass)
    return pass === confirmPass ? null : { notSame: true };
  }

  // Function to check form validity and debug
checkFormValidity() 
{
  console.log("Form Validity :",this.userForm.valid); // This should be true if the form is valid
  console.log("Form errors :",this.userForm.errors); // Check for any form-level errors
  //console.log("Form controls :",this.userForm.controls); // Check individual form control states

   // Check each control's state and errors
   Object.keys(this.userForm.controls).forEach(key => {
    const control = this.userForm.get(key);
    console.log(`Control: ${key}, Valid: ${control?.valid}, Errors: ${control?.errors}`);
  });

}

  onSubmit(): void 
  {
    this.checkFormValidity();

    console.log('Form Submitted with values:', this.userForm.value);

    if (this.userForm.valid)
    {
      //console.log('Form Submitted with values:', this.userForm.value);
  
      const userDetails=this.createUserDetails();

      console.log("User Details : " , userDetails);

      
      //--------- Call the service to add user -----------------
      this.userService.createUser(userDetails).subscribe(
        {
         
 
          next: (response:any) => 
          {
            this.idUserAdded=response.data;
            console.log('New Userid:',this.idUserAdded);
            //alert('User added with id :'+this.idUserAdded);
            alert('User added successfully');
            this.resetForm();
    
          }
          ,
          error: (error:any) => 
          {
            console.error('There was an error:',error.data);
            alert('Email id already registered')
            
          }
    });

    
    }
    else
    {
      console.log('Form is not valid');
      alert('Please enter all mandatory fields in the right format')
    }
  }

  createUserDetails()
  {
    return<UserData>
    {
        username:this.userForm.get('username')?.value,
        password:this.userForm.get('password')?.value,
        email:this.userForm.get('email')?.value,
        idcountry:this.userForm.get('idcountry')?.value,
        phonecountrycode:this.userForm.get('phonecountrycode')?.value,
        phonenumber:this.userForm.get('phonenumber')?.value,
        usertype:this.userForm.get('usertype')?.value,
        usersource:'System'
    }

  }


  onBlur(field: string) 
  {
    const control = this.userForm.get(field);

    if (control) 
    {
      control.markAsTouched();
      console.log(field,' marked as touched')
    }
  }

  resetForm() 
  {
    this.router.navigate(['/home']);
  }


  //-------------- Getters ------------------

  get username()
  {
    return this.userForm.get('username');
  }

  get password()
  {
    return this.userForm.get('password');
  }

  get confirmPassword()
  {
    return this.userForm.get('confirmPassword');
  }

  get email()
  {
    return this.userForm.get('email');
  }

  get  idcountry()
  {
    return this.userForm.get('idcountry');
  }

  get phonecountrycode()
  {
    return this.userForm.get('phonecountrycode');
  }


  get phonenumber()
  {
    return this.userForm.get('phonenumber');
  }

  get usertype()
  {
    return this.userForm.get('usertype');
  }

  togglePasswordVisibility()
  {
    this.showPassword=!this.showPassword;
  }

  toggleConfirmPasswordVisibility()
  {
    this.showConfirmPassword=!this.showConfirmPassword;
  }

}
