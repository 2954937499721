import { Component,OnInit } from '@angular/core';
import { VendorSummaryData,VendorID,CountryData,ExchangeRateData, CurrencyData  } from '../../../models/datamodels';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { UserServiceService } from '../../../services/user-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from '../../usermgmt/sign-in/sign-in.component';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GobackComponent } from '../../goback/goback.component';

@Component({
  selector: 'app-weddingvenueslisting',
  standalone: true,
  imports: [MatCardModule,CommonModule,RouterModule,FormsModule,GobackComponent],
  templateUrl: './weddingvenueslisting.component.html',
  styleUrl: './weddingvenueslisting.component.css'
})
export class WeddingvenueslistingComponent implements OnInit
{
  vendorsListOriginal:VendorSummaryData[]=[];
  vendorsList:VendorSummaryData[]=[];
  serverpath= environment.middletierhost+'/uploads';  //---serverpath for images----      
  currentIndex: number = 0; 
  countriesList:CountryData[] =[];  
  countriesListFiltered: CountryData[] =[]; 
  cityList:string[]=[];
  favourites:VendorID[]=[];  
  iduser=0;  

  selectedVendor:VendorSummaryData|null=null;
  selectedCountry:CountryData|null=null;
  selectedCity:string|null=null;

  showSearch:boolean=false;
  searchButtonText:string='Search Venues';

  fxList:ExchangeRateData[]=[];
  selectedCurrency:CurrencyData|null=null;

  titleText:string='Amazing Venues';
  subTitleText:string='Best destinations at best prices';

  constructor(private router:Router,public dialog: MatDialog,private vendorDataService:VendorDataServiceService , private environmentDataService : EnvironmentDataServiceService,private masterDataService:MasterdataserviceService,private userDataService:UserServiceService){}

  ngOnInit(): void 
  {

    this.environmentDataService.currentCurrency.subscribe(data=>
    {
          this.selectedCurrency=data;

          this.masterDataService.getExchangeRates().subscribe(data=>
          {
            this.fxList=data;
            console.log('Exchange rates fetched :',this.fxList);
          })
      });

      this.vendorDataService.getVenues().subscribe
      (data=>
        {
          this.vendorsListOriginal=data;       
          console.log('Hotels list revieved with number ' , data.length);
          console.log('Hotels list copied with number ' , this.vendorsListOriginal.length);
  
          this.vendorsList = this.vendorsListOriginal;
  
          this.masterDataService.getCountries().subscribe
          (
              data=>
              {
                this.countriesList=data;
  
                // Extract unique idcountry from vendorsList
                const vendorCountries = new Set(this.vendorsList.map(vendor => vendor.idcountry));
  
                // Filter countriesList based on idcountry in vendorCountries
                this.countriesListFiltered = this.countriesList.filter(country => vendorCountries.has(country.idcountry));
  
                this.cityList= this.vendorsList.map(vendor => vendor.city);
  
              })
          
        }) 


        

      //--------- get user favourites -------------

      this.environmentDataService.currentUser.subscribe(user=>
      {
          if (user)
          {
              this.iduser=user.iduser;

              this.userDataService.getUserFavourites(this.iduser).subscribe
              (data=>
               {
                  this.favourites=data;
              })
          }
       })
      
      

  }

  updateDisplayedVendors(): void 
  {
    //this.vendorsList = this.vendorsListOriginal.slice(this.currentIndex, this.currentIndex + 3);
    this.vendorsList = this.vendorsListOriginal;

    if(this.selectedCountry)
      {
        this.vendorsList=this.vendorsList.filter(v=>v.idcountry==this.selectedCountry?.idcountry);
        this.cityList= this.vendorsList.map(vendor => vendor.city);
  
       
      }
  
      if(this.selectedCity)
      {
        this.vendorsList=this.vendorsList.filter(v=>v.city==this.selectedCity);
      }
  
      if(this.selectedVendor)
      {
        this.vendorsList=this.vendorsList.filter(v=>v.idvendor==this.selectedVendor?.idvendor);
      }

  }

  getCountryName(idcountry: number): string 
  {
    const country = this.countriesList.find(c => c.idcountry === idcountry);
    return country ? country.countryName : '';
  }

  nextSlide(): void 
  {
    if (this.currentIndex + 3 < this.vendorsListOriginal.length) 
    {
      this.currentIndex += 3;
      this.updateDisplayedVendors();
    }
  }

  previousSlide(): void 
  {
    if (this.currentIndex - 3 >= 0) 
    {
      this.currentIndex -= 3;
      this.updateDisplayedVendors();
    }
  }

  //============== Function to updateselected country ============================
  onVendorSelect(selected: VendorSummaryData): void 
  {
  
    this.vendorDataService.changeData(selected);
    
    this.environmentDataService.changeCountryData(this.countriesList.find(c=>c.idcountry=selected.idcountry));
    
        
       
  }

  getStars(rating:string):string
  {
    if (rating=='5 star')
      return '*****'
      else if (rating=='4 star')
      return '****'
      else if (rating=='3 star')
        return '***'
      else if (rating=='2 star')
        return '**'
      else if (rating=='1 star')
        return '*'
      else
        return '';
  }

  toggleFavourites(idvendor:number)
  {


    //-------- Ask to sign in if not signed in --------------------

    this.environmentDataService.currentUser.subscribe(user=>
      {
        if (user)
        {
            this.iduser=user.iduser;
        }
        else
        {
          //-------- If not logged in, ask for user to log-in ------------------
          const dialogRef = this.dialog.open(SignInComponent, 
          {
            width: '250px'
          });
      
          dialogRef.afterClosed().subscribe(result => 
          {
            this.router.navigate(['/home']);
            console.log('The dialog was closed');
          });

        }
      }
    )

    if(this.iduser>0)
    {
        //--------------- Add or remove user favourites as applicable -------------------
    if(!this.favourites.find(c=>c.idvendor==idvendor))
      {
        //this.favourites.push(idvendor);
        this.userDataService.addUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
      else
      {
        //this.favourites=this.favourites.filter(n=>n!=idvendor)
        //this.favourites.push(idvendor);
        this.userDataService.deleteUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
    }

    

    
  }

  isFavourite(idvendor:number):boolean
  {
    if(this.favourites.find(c=>c.idvendor==idvendor))
      return true;
    else
    return false;
  }


   //============== Function to updateselected country ============================
   onVendorSelected(selected: VendorSummaryData): void 
   {
     this.selectedVendor = selected;
     this.updateDisplayedVendors();
      
   }

   //============== Function to updateselected country ============================
   onCountrySelected(selected: CountryData): void 
   {
     this.selectedCountry = selected;
     this.selectedVendor=null;
     this.selectedCity=null;

     this.updateDisplayedVendors();
      
   }

   //============== Function to updateselected country ============================
   onCitySelected(selected: string): void 
   {
     this.selectedCity = selected;
     this.updateDisplayedVendors();
      
   }

   //============Clear filters ====================================

   clearFilters()
   {
    this.selectedCountry = null;
    this.selectedVendor=null;
    this.selectedCity=null;

    this.updateDisplayedVendors();
   }

   enableSearch()
   {
     console.log('Enable Search Clicked');
     this.showSearch=!this.showSearch;
 
     if(this.showSearch)
       this.searchButtonText="Hide search panel"
     else
       this.searchButtonText="Search Venues"
     
   }

   convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
     // console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

        const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


      //    console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

        //    console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }
}
