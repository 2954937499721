<app-header2></app-header2>

   
   <div class=" d-block d-md-none">
      <div class="row pt-16 pb-3">
         <img mat-card-image src="../../assets/images/PageImages/venues.jpg">
      </div>
   </div>

   <div class=" d-none d-md-block">
      <div class="row pt-28 pl-5 pb-3">
         <img mat-card-image src="../../assets/images/PageImages/venues.jpg">
      </div>
   </div>
 
   <div class="row pt-10 ml-10 pb-10">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <h1>{{message}}</h1>
        </div>

        
   </div>
   


         
    <app-footersection></app-footersection>
