<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

<div class="container-fluid ">
    
    <div class="row pt-3">
        <div class="col-md-12">
            <span class="flex justify-center soulsWedHeadingFont">Chartered Flights</span>
        </div>
    </div>


    <div  class="d-none d-md-block row">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4 ">
                <span class="soulsWedSubheadingFont flex justify-start ">Let the journey begin</span>
            </div>
           
            <div class="col-md-4 d-flex justify-content-end pl-4">
                <p class="clickable soulsWedMenuFont" routerLink="/charters">View more charters >></p>
            </div>

        </div>

    </div>

    <div  class="d-block d-md-none row">
       <div class="row pt-3">
        <div class="col-12 ">
            <span class="soulsWedSubheadingFont flex justify-start ">Let the journey begin</span>
        </div>
       </div>

       <div class="row">
        <div class="col-12 pt-3">
            <p class="clickable soulsWedMenuFont flex justify-center" routerLink="/charters"> View more charters >></p>
        </div>
       </div>
    </div>



    <div class="row position-relative" >        

        <div class=" d-none d-md-block col-md-4" *ngFor="let vendor of vendorsList">
            <div class="flex justify-center"  >
                <mat-card>
                    <mat-card-content>
                        <img mat-card-image [src]="serverpath + vendor.imagespath" class="clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)" >
                        <div class="fav-icon-container">
                            <div class="col-md-6 text-right" *ngIf="!isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-notfavourite clickable" data-toggle="tooltip" data-placement="top" title="Add to favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
                            <div class="col-md-6 text-right" *ngIf="isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-favourite clickable" data-toggle="tooltip" data-placement="top" title="Remove from favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
                        </div>
                          
                          <div class="pt-2 text-left">
                            <div class="row">
                                <div class="col-md-9">
                                    <div class="soulsWedSubTitleFont clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{vendor.vendorname}}</div>
                                </div>
                                <div class="col-md-3 text-right">
                                    <div class="soulsWedStarFont pt-2 clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{getStars(vendor.vendorrating)}}</div>
                                </div>
                            </div>
                           
                            <div class="row clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">
                                <div class="col-md-12 soulsWedNormalFont pt-1 pb-1">{{vendor.city ? vendor.city + ', ' : ''}}{{getCountryName(vendor.idcountry)}}</div>
                            </div>
                           
                            <div class="row">
                                <div class=" col-md-6 soulsWedPriceFont2 pt-2">from {{selectedCurrency?.currencycode}} {{convertCurrency(vendor.currency,selectedCurrency?.currencycode!,vendor.startingprice) | number:'1.0-0'}} {{vendor.pricebasis}}</div>
                           </div>
                           
                            
                                                       
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
         </div>
     </div>


     <!--   For Smaller Screens -->

     <div class="row py-2 position-relative" >        

        <div class=" d-block d-md-none col-md-10" *ngFor="let vendor of vendorsList">
            <div class="flex justify-center" >
                <mat-card>
                    <mat-card-content>
                        <img mat-card-image [src]="serverpath + vendor.imagespath" class="clickable"  routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">
                        <div class="fav-icon-container">
                            <div class="col-md-6 text-right" *ngIf="!isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-notfavourite clickable" data-toggle="tooltip" data-placement="top" title="Add to favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
                            <div class="col-md-6 text-right" *ngIf="isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-favourite clickable" data-toggle="tooltip" data-placement="top" title="Remove from favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
                        </div>

                        <div class="pt-2 text-left">
                            <div class="row">
                                <div class="col-md-9">
                                    <div class="soulsWedSubTitleFont clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{vendor.vendorname}}</div>
                                </div>
                                <div class="col-md-3 text-right">
                                    <div class="soulsWedStarFont pt-2 clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{getStars(vendor.vendorrating)}}</div>
                                </div>
                            </div>
                           
                            <div class="row">
                                <div class="col-md-12 soulsWedNormalFont pt-1 pb-1 clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{vendor.city ? vendor.city + ', ' : ''}}{{getCountryName(vendor.idcountry)}}</div>
                            </div>
                           
                            <div class="row">
                                 <div class=" col-md-6 soulsWedPriceFont2 pt-2 clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">from {{selectedCurrency?.currencycode}} {{convertCurrency(vendor.currency,selectedCurrency?.currencycode!,vendor.startingprice) | number:'1.0-0'}} {{vendor.pricebasis}}</div>
                            </div>
                                                       
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
         </div>
     </div>


    

 </div>
