import { Component,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserServiceService } from '../../services/user-service.service';
import { Header2Component } from '../header2/header2.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-resetpassword',
  standalone: true,
  imports: [Header2Component, FootersectionComponent,FormsModule],
  templateUrl: './resetpassword.component.html',
  styleUrl: './resetpassword.component.css'
})

export class ResetpasswordComponent implements OnInit
{

  newPassword: string = '';
  confirmPassword:string='';

  token: string = '';
  message: string = '';

  showPassword:boolean=false;
  showConfirmPassword:boolean=false;

  constructor(private route: ActivatedRoute,private userservice:UserServiceService) {}

  ngOnInit(): void 
  {
    this.route.queryParams.subscribe(params => 
    {
      this.token = params['token'];
    });
      
  }

  validatePassword():boolean
   {
    
    const hasNumber = /[0-9]/.test(this.newPassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(this.newPassword);
    const hasNoQuotes = !/['"]/.test(this.newPassword);
    const isValidLength = this.newPassword.length >= 8;

    if (hasNumber && hasSpecialChar && hasNoQuotes && isValidLength)
    {
      return false;
    } else 
    {
      return true;
    }
  }

  resetPassword()
  {
    if (this.newPassword.length<=0)
    {
        alert('Please enter new password');
        return;
    }

    if(this.validatePassword())
    {
      alert('Please enter a valid password in specified format');
      return;
    }

    if (this.confirmPassword.length<=0)
    {
          alert('Please enter password in second box');
          return;
    }

    

    if (this.newPassword==this.confirmPassword)
    {
          this.userservice.resetPassword(this.token,this.newPassword).subscribe(data=>
          {
            alert(data.message);
          }
          )
    }
    else
    {
        alert('Passwords do not match');
    }
  }

  togglePasswordVisibility()
  {
    this.showPassword=!this.showPassword;
  }

  toggleConfirmPasswordVisibility()
  {
    this.showConfirmPassword=!this.showConfirmPassword;
  }
}
