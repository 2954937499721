import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { HotelslistingComponent } from './hotelslisting/hotelslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import {MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-hotels',
  standalone: true,
  imports: [GobackComponent,Header2Component,HotelslistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './hotels.component.html',
  styleUrl: './hotels.component.css'
})
export class HotelsComponent {

}
