import { Component,OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Header2Component } from '../header2/header2.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-termsnconditions',
  standalone: true,
  imports: [Header2Component,FootersectionComponent,GobackComponent],
  templateUrl: './termsnconditions.component.html',
  styleUrl: './termsnconditions.component.css'
})
export class TermsnconditionsComponent implements OnInit
{
  externalHtml: string | null = null;

constructor(private http:HttpClient)
{}

ngOnInit(): void 
{
  this.loadExternalHtml();
}

  async loadExternalHtml(): Promise<void> 
  {
    this.http.get("../../assets/policydocuments/tnc.html", { responseType: 'text' })
      .subscribe(data => {
        this.externalHtml = data;
      });

   }


}

