import { Component } from '@angular/core';
import { Header2Component } from '../header2/header2.component';
import { DecoratorslistingComponent } from './decoratorslisting/decoratorslisting.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import {MatCardModule} from '@angular/material/card'
import { GobackComponent } from '../goback/goback.component';

@Component({
  selector: 'app-decorators',
  standalone: true,
  imports: [GobackComponent,Header2Component,DecoratorslistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './decorators.component.html',
  styleUrl: './decorators.component.css'
})
export class DecoratorsComponent {

}
