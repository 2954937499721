

<div class="container-fluid px-3 ">
 
    
    <div class="row">
        <div class="col-md-12">
            <app-header2></app-header2>
         </div>
    </div>

    <div class="row pt-28 pl-5 pb-3">
      <img mat-card-image src="../../assets/images/PageImages/venues.jpg">
   </div>

   <div class="row pt-2 pl-3">
      <app-goback></app-goback>
   </div>

    <div class="row pt-3 pb-3 pl-5 ">

      <div class="d-none d-md-block">

         <div class="row ml-2">
            <div class="col-md-4">
               <div class="row soulsWedHeadingFont pt-4"> {{currentVendor?.vendorname}}</div>
               <div class="row soulsWedNormalFont pt-2"> {{currentVendor?.cityname}}</div>
               <div class="row soulsWedNormalFont pt-2"> {{currentVendor?.countryname}}</div>

               <div class="row soulsWedNormalFont pt-4 text-justify wrap-text">{{currentVendor?.description}}</div>  
            </div>

            <div class="col-md-5 flex justify-end ">
               
               <google-map height="300px"

               width="400px"
               [center]="center"
               [zoom]="zoom">

               <map-marker [position]="center"></map-marker>
               

             </google-map>
            </div>

            <div class="col-md-3 flex justify-items-center ">
               <div class="ml-10 pt-2" >
                   <div class="text-center">
                       <div class="soulsWedSubheadingFont2"><b>From : {{currency}} {{ basePrice|number:'1.0-0' }} {{ currentVendor?.pricebasis }}</b></div> 
                   </div>
                   <br><br>
                    <div class="row justify-center mb-3">                        
                           <button class="p-2 button-style-2"  (click)="payandbook()">Pay and Book</button>        
                   </div>          
               </div>

            </div>


         </div>
        
    
      </div>
        
      <div class="d-block d-md-none">
         <div class="col-12">
            <div class="soulsWedHeadingFont py-4"> {{currentVendor?.vendorname}}</div>
            <div class="soulsWedNormalFont text-justify wrap-text">{{currentVendor?.description}}</div>     
         </div>
         <div class="col-12">
            <google-map height="300px"

               width="350px"
               [center]="center"
               [zoom]="zoom">

               <map-marker [position]="center"></map-marker>
               

             </google-map>    
         </div>
      </div>
       
    </div>

    <!--===================Section to show images and price--------------------------->
    <div class="row items-center py-3 pl-5 " *ngIf="vendorImages.length>0">
        
        <div class="col-md-12">
         <div class="row soulsWedSubTitleFont pt-2"> Check out our images</div>
         <div class="row pt-2">
             <div class="col-md-4 p-1 fixed-height-row" *ngFor="let image of vendorImages">
                 <img class="img-fluid img-fit" [src]="imagesPath + image.imagename">
             </div>
         </div>
     </div>

      <!--===================Section to show videos--------------------------->
     <div class="row items-center py-3 pl-5 " *ngIf="vendorVideos.length > 0">
      <div class="col-md-12">
          <div class="row soulsWedSubTitleFont pt-2">Check out our videos</div>
          <div class="row pt-2">
              <div class="col-md-4 p-1 fixed-height-row" *ngFor="let video of vendorVideos">
                  <video class="img-fluid video-fit" controls [src]="videosPath + video.videoname">
                      Your browser does not support the video tag.
                  </video>
              </div>
          </div>
      </div>
   </div>
  

         
       
    <!--====================== Vendor Faq Information -------------------------------->    
    <div *ngIf="currentVendor?.idcategory==3 && hasFaqInfo">
        <hr>

            <div class="col-md-12 soulsWedSubheadingFont">
                 <h1> Basic Information</h1>
            </div>
    
            <div class="row">

                <div class="col-md-4 ">
                    <div *ngIf="currentVendorDetails?.venueFaq?.venue_primaryDescription" class="row">
                       <p><b>Venue Description</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_primaryDescription}}</p> 
                    </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_venueTypeOptions" class="row">
                        <p><b>Venue Type</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_venueTypeOptions}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_startyear" class="row">
                        <p><b>Started in</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_startyear}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_alchoholpolicy" class="row">
                        <p><b>Alcohol Policy</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_alchoholpolicy}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_cateringpolicy"  class="row">
                        <p><b>Catering Policy</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_cateringpolicy}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_onsiteaccomodation" class="row flex justify-center">
                        <p><b>Onsite accomodation available</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_onsiteaccomodation}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_venueType" class="row flex justify-center">
                        <p><b>Is there rental cost alongwith per plate cost?</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_venueType}}</p> 
                     </div>
    
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_roomsavailable" class="row flex justify-center">
                         <p><b>Total rooms available</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_roomsavailable}}</p> 
                      </div>
                     
                </div>
                <div class="col-md-1"></div>
    
                <div class="col-md-3 ">
                   
                     
                      <div *ngIf="currentVendorDetails?.venueFaq?.venue_roomsprice" class="row flex justify-center">
                         <p><b>Room starting Price</b> :&nbsp;{{currency}}{{currentVendorDetails?.venueFaq?.venue_roomsprice}}</p> 
                      </div>
                      <div *ngIf="currentVendorDetails?.venueFaq?.venue_vegStartPrice" class="row flex justify-center">
                         <p><b>Veg menu starting price</b> :&nbsp;{{currency}}{{currentVendorDetails?.venueFaq?.venue_vegStartPrice}}</p> 
                      </div>
                      <div *ngIf="currentVendorDetails?.venueFaq?.venue_nonvegStartPrice" class="row">
                        <p><b>Non-veg menu starting price</b> :&nbsp;{{currency}}{{currentVendorDetails?.venueFaq?.venue_nonvegStartPrice}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_djpolicy" class="row">
                        <p><b>DJ Policy</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_djpolicy}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_decorpolicy" class="row">
                        <p><b>Decor Policy</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_decorpolicy}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_decorprice" class="row">
                        <p><b>Approx price for venue decoration</b> :&nbsp;{{currency}}{{currentVendorDetails?.venueFaq?.venue_decorprice}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_smallgatherings" class="row">
                        <p><b>Small size gatherings (&lt;50) allowed ?</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_smallgatherings}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_parking" class="row">
                        <p><b>Parking Avalable? :</b> &nbsp;{{currentVendorDetails?.venueFaq?.venue_parking}}</p> 
                     </div>
                    
    
                </div>

                <div class="col-md-1"></div>
    
                <div class="col-md-3 ">
    
                     
                      <div *ngIf="currentVendorDetails?.venueFaq?.venue_USP" class="row">
                         <p><b>USP</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_USP}}</p> 
                      </div>
                      <div *ngIf="currentVendorDetails?.venueFaq?.venue_weeksadvance" class="row">
                         <p><b>How many weeks in advance should a booking be made?</b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_weeksadvance}}</p> 
                      </div>
                      <div *ngIf="currentVendorDetails?.venueFaq?.venue_allapplicable" class="row">
                        <p><b>Venue features </b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_allapplicable}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_cancellationpolicyuser" class="row">
                        <p><b>Cancellation policy (if customer cancels) </b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_cancellationpolicyuser}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_cancellationpolicyvendor" class="row">
                        <p><b>Cancellation policy (if vendor cancels) </b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_cancellationpolicyvendor}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.venueFaq?.venue_cancellationtnc" class="row">
                        <p><b>Cancellation Terms and Conditions </b> :&nbsp;{{currentVendorDetails?.venueFaq?.venue_cancellationtnc}}</p> 
                     </div>

                    
                </div>
               
            </div>
        
         

    </div>

     <!--====================== Hotel Faq Information -------------------------------->    
     <div *ngIf="currentVendor?.idcategory==4 && hasFaqInfo">
        <hr>
       
            <div class="col-md-12 soulsWedSubheadingFont">
                 <h1> Basic Information</h1>
            </div>  
    
            <div class="row">
                <div *ngIf="currentVendorDetails?.hotelsFaq?.couple_policy" class="col-md-4 ">
                    <div class="row">
                       <p><b>Couple policy :</b> &nbsp;{{currentVendorDetails?.hotelsFaq?.couple_policy}}</p> 
                    </div>
                     <div *ngIf="currentVendorDetails?.hotelsFaq?.smoking_policy" class="row">
                        <p><b>Smoking Policy :</b> &nbsp;{{currentVendorDetails?.hotelsFaq?.smoking_policy}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.hotelsFaq?.parking_available" class="row">
                        <p><b>Is parking available for guests? </b> &nbsp;{{currentVendorDetails?.hotelsFaq?.parking_available}}</p> 
                     </div>                
                </div>

                <div class="col-md-1"></div>
    
                <div class="col-md-4 ">
                   
                    <div *ngIf="currentVendorDetails?.hotelsFaq?.extra_beds" class="row flex justify-center">
                        <p><b>Are extra beds available on request? </b> &nbsp;{{currentVendorDetails?.hotelsFaq?.extra_beds}}</p> 
                     </div>
    
                     <div *ngIf="currentVendorDetails?.hotelsFaq?.days_cancellation" class="row flex justify-center">
                         <p><b>Number of days in advance for free cancellation? </b> &nbsp;{{currentVendorDetails?.hotelsFaq?.days_cancellation}}</p> 
                      </div>

                      <div *ngIf="currentVendorDetails?.hotelsFaq?.pets_policy" class="row flex justify-center">
                         <p><b>Are pets allowed?</b> &nbsp;{{currentVendorDetails?.hotelsFaq?.pets_policy}}</p> 
                      </div>
                     
                </div>
    
                <div class="col-md-3 ">

                    <div *ngIf="currentVendorDetails?.hotelsFaq?.creditcards_policy" class="row flex justify-center">
                         <p><b>Are credit cards accepted at the property? </b> &nbsp;{{currentVendorDetails?.hotelsFaq?.creditcards_policy}}</p> 
                      </div>
                      
                      <div *ngIf="currentVendorDetails?.hotelsFaq?.otherpayment_methods" class="row flex justify-center">
                         <p><b>Other payment methods accepted :</b> &nbsp;{{currentVendorDetails?.hotelsFaq?.otherpayment_methods}}</p> 
                      </div>
                </div>
                <div class="col-md-1"></div>
            </div>

    </div>

    <!--====================== Planner Faq Information -------------------------------->    
    <div *ngIf="currentVendor?.idcategory==5 && hasFaqInfo">
        <hr>
       
            <div class="col-md-12 soulsWedSubheadingFont">
                 <h1> Basic Information</h1>
            </div>  
    
            <div class="row">
                <div class="col-md-4 ">
                    <div *ngIf="currentVendorDetails?.plannerFaq?.planner_USP" class="row">
                       <p><b>Planner USP :</b> &nbsp;{{currentVendorDetails?.plannerFaq?.planner_USP}}</p> 
                    </div>
                     <div *ngIf="currentVendorDetails?.plannerFaq?.planner_hotelslist" class="row">
                        <p><b>Hotels/venues where this vendor is enlisted :</b> &nbsp;{{currentVendorDetails?.plannerFaq?.planner_hotelslist}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.plannerFaq?.planner_cities" class="row">
                        <p><b>Cities where this vendor has planned weddings/parties :</b> &nbsp;{{currentVendorDetails?.plannerFaq?.planner_cities}}</p> 
                     </div>                
                </div>

                <div class="col-md-1"></div>
    
                <div class="col-md-3 ">
                   
                    <div *ngIf="currentVendorDetails?.plannerFaq?.planner_cities" class="row flex justify-center">
                        <p><b>Prominent clients this vendor has worked with :</b> &nbsp;{{currentVendorDetails?.plannerFaq?.planner_cities}}</p> 
                     </div>
    
                     <div *ngIf="currentVendorDetails?.plannerFaq?.planner_startingPackage" class="row flex justify-center">
                         <p><b>Approximate starting price for a 3-day wedding :</b> &nbsp;{{currency}}{{currentVendorDetails?.plannerFaq?.planner_startingPackage}}</p> 
                      </div>
                      <div *ngIf="currentVendorDetails?.plannerFaq?.planner_decorPolicy" class="row flex justify-center">
                         <p><b>Decor Policy :</b> &nbsp;{{currentVendorDetails?.plannerFaq?.planner_decorPolicy}}</p> 
                      </div>
                     
                </div>
    
                <div class="col-md-4 ">
    
                     <div *ngIf="currentVendorDetails?.plannerFaq?.planner_commercialPlan" class="row">
                         <p><b>Commercial Plan :</b> &nbsp;{{currentVendorDetails?.plannerFaq?.planner_commercialPlan}}</p> 
                         <div *ngIf="currentVendorDetails?.plannerFaq?.planner_commercialPlan=='We charge a percentage of the wedding cost'">
                                <span>&nbsp;<b>Approx (negotiable) :</b> {{currentVendorDetails?.plannerFaq?.planner_commercialPlanPerc}}</span>
                         </div>
                      </div>  
                </div>
                <div class="col-md-1"></div>
            </div>

    </div>

    <!--====================== Photographer Faq Information -------------------------------->    
    <div *ngIf="currentVendor?.idcategory==7 && hasFaqInfo">
        <hr>
       
            <div class="col-md-12 soulsWedSubheadingFont">
                 <h1> Basic Information</h1>
            </div>  
    
            <div class="row">
                <div class="col-md-4 ">
                    <div *ngIf="currentVendorDetails?.photographerFaq?.ph_mostBookedPackageValue" class="row">
                       <p><b>Most booked package rate :</b> &nbsp;{{currency}}{{currentVendorDetails?.photographerFaq?.ph_mostBookedPackageValue}}</p> 
                    </div>
                     <div *ngIf="currentVendorDetails?.photographerFaq?.ph_mostBookedPackageDays" class="row">
                        <p><b>Number of days included in above package :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_mostBookedPackageDays}}</p> 
                     </div>
                     <div *ngIf="currentVendorDetails?.photographerFaq?.ph_mostBookedPackageServices" class="row">
                        <p><b>Services included in above package :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_mostBookedPackageServices}}</p> 
                     </div>   
                     <div *ngIf="currentVendorDetails?.photographerFaq?.ph_cancellationPolicyUser" class="row">
                        <p><b>Cancellation policy(cancellation initiated by customer) :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_cancellationPolicyUser}}</p> 
                     </div>
                      <div *ngIf="currentVendorDetails?.photographerFaq?.ph_cancellationPolicySelf" class="row">
                         <p><b>Cancellation policy(cancellation initiated by vendor) :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_cancellationPolicySelf}}</p> 
                      </div>
                      <div *ngIf="currentVendorDetails?.photographerFaq?.ph_cancellationPolicyTermsnConditions" class="row">
                         <p><b>Cancellation terms and conditions :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_cancellationPolicyTermsnConditions}}</p> 
                      </div>    
                      <div *ngIf="currentVendorDetails?.photographerFaq?.ph_photographyDescription" class="row">
                        <p><b>Work description :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_photographyDescription}}</p> 
                     </div>             
                </div>

                <div class="col-md-1"></div>
    
                <div class="col-md-3 ">
                   
                    <div *ngIf="currentVendorDetails?.photographerFaq?.ph_citiesNumber" class="row">
                        <p><b>Number of cities where wedding was covered by vendor till date:</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_citiesNumber}}</p> 
                     </div>
                      <div *ngIf="currentVendorDetails?.photographerFaq?.ph_loveBecause" class="row">
                         <p><b>We love wedding photography because :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_loveBecause}}</p> 
                      </div>
                      <div *ngIf="currentVendorDetails?.photographerFaq?.ph_smallEventprice" class="row">
                         <p><b>Price for covering a small event like engagement or roka (< 50 guests , 4 hrs max) :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_smallEventprice}}</p> 
                      </div>   
                      <div *ngIf="currentVendorDetails?.photographerFaq?.ph_advanceBookingWeeks" class="row">
                         <p><b>Weeks in advance booking needs to be made :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_advanceBookingWeeks}}</p> 
                      </div>
                       <div *ngIf="currentVendorDetails?.photographerFaq?.ph_startYear" class="row">
                          <p><b>Year wedding photography started :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_startYear}}</p> 
                       </div>
                       <div *ngIf="currentVendorDetails?.photographerFaq?.ph_weeksToDeliver" class="row">
                          <p><b>Time to deliver photos (in weeks) :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_weeksToDeliver}}</p> 
                       </div>    
                       <div *ngIf="currentVendorDetails?.photographerFaq?.ph_services" class="row">
                         <p><b>Main service type :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_services}}</p> 
                      </div>          
                     
                </div>
    
                <div class="col-md-4 ">

                    <div *ngIf="currentVendorDetails?.photographerFaq?.ph_specialityType" class="row">
                        <p><b>Speciality:</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_specialityType}}</p> 
                     </div>
                      <div *ngIf="currentVendorDetails?.photographerFaq?.ph_specialityNumber" class="row">
                         <p><b>Speciality Pax Number :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_specialityNumber}}</p> 
                      </div>
                      <div *ngIf="currentVendorDetails?.photographerFaq?.ph_paymentTerms" class="row">
                         <p><b>Payment terms :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_paymentTerms}}</p> 
                      </div>   
                      <div *ngIf="currentVendorDetails?.photographerFaq?.ph_travelLodgingCosts" class="row">
                         <p><b>Travel and lodging costs policy :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_travelLodgingCosts}}</p> 
                      </div>
                       <div *ngIf="currentVendorDetails?.photographerFaq?.ph_candidTraditionalOneday" class="row">
                          <p><b>Price for candid photography and traditional photography one day package for wedding day :</b> &nbsp;{{currency}}{{currentVendorDetails?.photographerFaq?.ph_candidTraditionalOneday}}</p> 
                       </div>    
                       <div *ngIf="currentVendorDetails?.photographerFaq?.ph_candidTraditionalCinematic" class="row">
                         <p><b>Price for candid photography ,traditional photography and cinematic video one day package for wedding day :</b> &nbsp;{{currency}}{{currentVendorDetails?.photographerFaq?.ph_candidTraditionalCinematic}}</p> 
                      </div>          
                     
                </div>
                <div class="col-md-1"></div>
            </div>

    </div>

    <!--====================== Decor Faq Information -------------------------------->    
    <div *ngIf="currentVendor?.idcategory==8 && hasFaqInfo">
      <hr>
     
          <div class="col-md-12 soulsWedSubheadingFont">
               <h1> Basic Information</h1>
          </div>  
  
          <div class="row">
              <div class="col-md-4 ">
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_enlistedHotels" class="row">
                     <p><b>Hotels/ banquets where vendor is enlisted :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_enlistedHotels}}</p> 
                  </div>
                   <div *ngIf="currentVendorDetails?.decorFaq?.dr_serviceTypes" class="row">
                      <p><b>Main Service Type :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_serviceTypes}}</p> 
                   </div>
                   <div *ngIf="currentVendorDetails?.decorFaq?.dr_indoorPriceRange" class="row">
                      <p><b>Price range for indoor banquet function (approx 250 pax) :</b> &nbsp;{{currency}}{{currentVendorDetails?.decorFaq?.dr_indoorPriceRange}}</p> 
                   </div>   
                   <div *ngIf="currentVendorDetails?.decorFaq?.dr_outdoorPriceRange" class="row">
                      <p><b>Price range for outdoor banquet function (approx 250 pax) :</b> &nbsp;{{currency}}{{currentVendorDetails?.decorFaq?.dr_outdoorPriceRange}}</p> 
                   </div>
                    <div *ngIf="currentVendorDetails?.decorFaq?.dr_celebrityWeddings" class="row">
                       <p><b>Celebrity weddings done by the vendor :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_celebrityWeddings}}</p> 
                    </div>
                              
              </div>

              <div class="col-md-1"></div>
  
              <div class="col-md-3 ">
                 
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_USP" class="row">
                      <p><b>USP:</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_USP}}</p> 
                   </div>
                    <div *ngIf="currentVendorDetails?.decorFaq?.dr_mostExperiencedIn" class="row">
                       <p><b>Most experienced in :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_mostExperiencedIn}}</p> 
                    </div>
                    <div *ngIf="currentVendorDetails?.decorFaq?.dr_cancellationPolicyUser" class="row">
                       <p><b>Cancellation policy on user initiated cancellation :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_cancellationPolicyUser}}</p> 
                    </div>   
                    <div *ngIf="currentVendorDetails?.decorFaq?.dr_cancellationPolicySelf" class="row">
                       <p><b>Cancellation policy on vendor initiated cancellations :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_cancellationPolicySelf}}</p> 
                    </div>
                     <div *ngIf="currentVendorDetails?.decorFaq?.dr_cancelationTermsnConsitions" class="row">
                        <p><b>Cancellations terms and conditions :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_cancelationTermsnConsitions}}</p> 
                     </div>       
                   
              </div>
  
              <div class="col-md-4 ">

                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_startYear" class="row">
                      <p><b>Started in:</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_startYear}}</p> 
                   </div>
                    <div *ngIf="currentVendorDetails?.decorFaq?.dr_advanceBookingWeeks" class="row">
                       <p><b>Weeks in advance bookings need to be done :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_advanceBookingWeeks}}</p> 
                    </div>
                    <div *ngIf="currentVendorDetails?.decorFaq?.dr_indoorStartingPrice" class="row">
                       <p><b>Starting price for indoor decor :</b> &nbsp;{{currency}}{{currentVendorDetails?.decorFaq?.dr_indoorStartingPrice}}</p> 
                    </div>   
                    <div *ngIf="currentVendorDetails?.decorFaq?.dr_homefunctionStartingPrice" class="row">
                       <p><b>Starting price for home decor (flowers and lights for haldi,dinners etc) </b> &nbsp;{{currency}}{{currentVendorDetails?.decorFaq?.dr_homefunctionStartingPrice}}</p> 
                    </div>
                        
                   
              </div>
              <div class="col-md-1"></div>
          </div>

  </div>

   <!--====================== Charter Faq Information -------------------------------->    
   <div *ngIf="currentVendor?.idcategory==9 && hasFaqInfo">
      <hr>
     
          <div class="col-md-12 soulsWedSubheadingFont">
               <h1> Basic Information</h1>
          </div>  
  
          <div class="row">
              <div class="col-md-4 ">
                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_primaryServiceType" class="row">
                     <p><b>Primary Service Type :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_primaryServiceType}}</p> 
                  </div>
                   <div *ngIf="currentVendorDetails?.charterFaq?.ch_rentalCostYesNo" class="row">
                      <p><b>Rental cost present? :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_rentalCostYesNo}}</p> 
                   </div>
                   <div *ngIf="currentVendorDetails?.charterFaq?.ch_startYear" class="row">
                      <p><b>Year started :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_startYear}}</p> 
                   </div>   
                   <div *ngIf="currentVendorDetails?.charterFaq?.ch_aircraftTypes" class="row">
                      <p><b>Aircrat types operated :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_aircraftTypes}}</p> 
                   </div>
                    
                              
              </div>

              <div class="col-md-1"></div>
  
              <div class="col-md-3 ">
                 
                    <div *ngIf="currentVendorDetails?.charterFaq?.ch_startingPrice" class="row">
                       <p><b>Starting price for renting :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_startingPrice}}</p> 
                    </div>
                    <div *ngIf="currentVendorDetails?.charterFaq?.ch_startingPriceBookingnTechnician" class="row">
                       <p><b>Starting price for booking along with technician assistance :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_startingPriceBookingnTechnician}}</p> 
                    </div>   
                    <div *ngIf="currentVendorDetails?.charterFaq?.ch_partialRoundsYesNo" class="row">
                       <p><b>Partial rounds available? :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_partialRoundsYesNo}}</p> 
                    </div>
                     <div *ngIf="currentVendorDetails?.charterFaq?.ch_alcoholPolicy" class="row">
                        <p><b>Alcohol policy :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_alcoholPolicy}}</p> 
                     </div>       
                   
              </div>
  
              <div class="col-md-4 ">

                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_recognitions" class="row">
                      <p><b>Recognitions :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_recognitions}}</p> 
                   </div>
                    <div *ngIf="currentVendorDetails?.charterFaq?.ch_registeredYesNo" class="row">
                       <p><b>Services registered with the local authorities and government? :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_registeredYesNo}}</p> 
                    </div>
                  
                        
                   
              </div>
              <div class="col-md-1"></div>
          </div>

  </div>

  <!--====================== Makeup Artists Faq Information -------------------------------->    
  <div *ngIf="currentVendor?.idcategory==10 && hasFaqInfo">
   <hr>
  
       <div class="col-md-12 soulsWedSubheadingFont">
            <h1> Basic Information</h1>
       </div>  

       <div class="row">
           <div class="col-md-4 ">
               <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_hotelspanels" class="row">
                  <p><b>Hotels/ banquets where vendor is enlisted  :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_hotelspanels}}</p> 
               </div>
                <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_usp" class="row">
                   <p><b>USP :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_usp}}</p> 
                </div>
                <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_startyear" class="row">
                   <p><b>Year started :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_startyear}}</p> 
                </div>   
                <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_cities" class="row">
                   <p><b>Number of cities where wedding was covered by vendor till date :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_cities}}</p> 
                </div>
                <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_prominentclients" class="row">
                  <p><b>Celebrity weddings done by the vendor  :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_prominentclients}}</p> 
               </div>
               <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_startingrate" class="row">
                  <p><b>Starting price for a wedding makeup engagement :</b> &nbsp;{{currency}}{{currentVendorDetails?.makeupartistFaq?.mk_startingrate}}</p> 
               </div>   
               <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_addguests" class="row">
                  <p><b>Additional guests apart from bride and groom included in the above package :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_addguests}}</p> 
               </div>
                 
                           
           </div>

           

           <div class="col-md-4 ">
              
                
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_costpolicy" class="row">
                     <p><b>Travel and lodging costs policy  :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_costpolicy}}</p> 
                  </div>   
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_daysadvance" class="row">
                   <p><b>Weeks in advance booking needs to be made :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_daysadvance}}</p> 
                </div>
                 <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_cancellationuser" class="row">
                    <p><b>Cancellation policy(cancellation initiated by customer)  :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_cancellationuser}}</p> 
                 </div>
                 <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_cancellationvendor" class="row">
                  <p><b>Cancellation policy(cancellation initiated by vendor)  :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_cancellationvendor}}</p> 
               </div>    
               <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_servicesoffered" class="row">
                  <p><b>Services Offerred :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_servicesoffered}}</p> 
               </div>  
               <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_products" class="row">
                  <p><b>Products used for makeup :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_products}}</p> 
               </div> 
               <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_traveltovenue" class="row">
                  <p><b>Vendor travels to the venue? :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_traveltovenue}}</p> 
               </div>    
                
           </div>

           <div class="col-md-4 ">

               <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_travelprice" class="row">
                   <p><b>Amount charged for events outside hometown :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_travelprice}}</p> 
                </div>
                 <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_priceincludes" class="row">
                    <p><b>Briddal make-up price includes  :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_priceincludes}}</p> 
                 </div>
                 <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_airbrushservice" class="row">
                  <p><b>Air-brush service available?  :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_airbrushservice}}</p> 
                 </div>
                 <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_trialspolicy" class="row">
                  <p><b>Trials policy  :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_trialspolicy}}</p> 
                 </div>
                 <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_awards" class="row">
                  <p><b>Awards won  :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_awards}}</p> 
                 </div>
               
                     
                
           </div>
      
       </div>

</div>
    

    
             

    <!--====================== Vendor Amenities -------------------------------->
    
    <hr>

    <div class="row " *ngIf="amenities.length>0">
        
        <div class="col-md-12 soulsWedSubheadingFont">
             <h1> Amenities</h1>
        </div>

        <div class="row">
            <div class="col-md-3 flex items-center pt-2" *ngFor="let amenity of amenities">
                <div>
                    <span class="material-icons green-tick">check</span>{{amenity.amenityName}}
                </div>
            </div>
        </div>
              
    
     </div>

     
 
         


   <!--==================================== Reviews Section ====================================-->
    <hr>

    <div class="row">
      <div class="col-md-2">
         <h1> Reviews</h1>
      </div>
      <div class="col-md-2">
         <button class="border button-style-5" (click)="addReview()" >Write a review</button>
      </div>

   </div>

   <div *ngIf="showAddBlock">

      <div class="row">
         <div class="col-md-6">
            <label for="description" class="form-label soulsWedNormalFont">Description</label>
            <textarea class="form-control" id="description" [(ngModel)]="reviewText" maxlength="999" rows="4" ></textarea>
        </div>
        <div class="col-md-1 pt-4">
            <button class="border button-style-5" id="submitbutton" (click)="addReviewEntry()">Submit</button>
        </div>
   
        <div class="col-md-1 pt-4">
            <button class="border button-style-5" id="cancelbutton" (click)="cancelReview()">Cancel</button>
        </div>
      </div>
      

   </div>

   <div *ngIf="showEditBlock">

      <div class="row">
         <div class="col-md-6">
            <label for="description" class="form-label soulsWedNormalFont">Description</label>
            <textarea class="form-control" id="description" [(ngModel)]="reviewText" maxlength="999" rows="4" ></textarea>
        </div>
        <div class="col-md-1 pt-4">
            <button class="border button-style-5" id="submitbutton" (click)="editReviewEntry()">Submit</button>
        </div>
   
        <div class="col-md-1 pt-4">
            <button class="border button-style-5" id="cancelbutton" (click)="cancelEditReview()">Cancel</button>
        </div>
      </div>
      

   </div>

   <div class="row py-4 ml-2" *ngIf="reviews.length==0">
      &nbsp;&nbsp;No reviews available
   </div>
        
   


    <div class="row pt-2" *ngFor="let review of reviews" >
       
        <div class="row p-2">
            <b> <span class="material-icons user-icon">person</span> {{review.username}}&nbsp;</b>
         </div>

         <div class="row">
               <div class="col-md-4">
                  <p class="p-2">{{review.description}}</p>
               </div>

               <div class="col-md-8" *ngIf="review.idUser==currentUser?.iduser && review.idUser>0">
                  <div class="row">
                     <div class="col-md-1">
                        <button class="border button-style-4" (click)="editReview(review)" >Edit</button>
                     </div>
   
                     <div class="col-md-1">
                        <button class="border button-style-4" (click)="deleteReview(review)" >Delete</button>
                     </div>
                  </div>
                  
               </div>
        </div>
      </div>

    </div>

</div>

<!--
<button (click)="openModal()" class="btn btn-primary">Open Modal</button>-->

<app-paymodal *ngIf="showModal" [data]="modalData" (action)="handleModalAction($event)"></app-paymodal>


<!----------=========================Apply Footer =================================

<div class="row">
    <div class="col-md-12">
        <app-footersection></app-footersection>
     </div>
</div>
-->
<app-footersection></app-footersection>
 