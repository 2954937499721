import { Component,OnInit } from '@angular/core';
import {Header2Component} from '../header2/header2.component'
import { FootersectionComponent } from '../footersection/footersection.component';
import { FormBuilder, FormGroup, Validators,AbstractControl,FormArray } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'
import { MasterdataserviceService } from '../../services/masterdataservice.service';
import {CurrencyData, CountryData,CategoryData,phoneCountryCode,VendorDetails,VendorImages, VendorVideos, VendorAmenities, CityData } from '../../models/datamodels';
import { CommonModule } from '@angular/common';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { FormsModule } from '@angular/forms';
import { VendorDataServiceService } from '../../services/vendor-data-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from '../usermgmt/sign-in/sign-in.component';
import { Router } from '@angular/router';
import { GobackComponent } from '../goback/goback.component';
import { GoogleMapsModule } from '@angular/google-maps';

@Component({
  selector: 'app-registervendor',
  standalone: true,
  imports: [ GoogleMapsModule,GobackComponent,Header2Component,FootersectionComponent,ReactiveFormsModule,CommonModule,FormsModule,SignInComponent],
  templateUrl: './registervendor.component.html',
  styleUrl: './registervendor.component.css'
})

export class RegistervendorComponent implements OnInit
{
  selectedCurrency: string|null = null; // Default currency
  selectedPricebasis: String='Per Day';

  //------ For Google Map ------------------------------

  display: any;
  center: google.maps.LatLngLiteral = 
  {
        lat: 20.5937,//22.2736308,
        lng: 78.9629 //70.7512555
  };
  zoom = 5;

  locationChosen:boolean=false;

  //----------- Variables to store data ----------------
  vendorForm: FormGroup;
  countries :CountryData[]=[]; // Placeholder for countries data
  categories:CategoryData[] = []; // Placeholder for categories data
  cities:CityData[]=[];
  vendorAmenities:VendorAmenities[]=[];
  currencies = ['INR', 'USD', 'EUR'];
  priceBases = ['Per Day', 'Per Person','Per Hour', 'Per Engagement'];
  hotelcategories=['3 star','4 star','5 star','Not rated']
  phoneCountryCodes :phoneCountryCode[]=[]
  vendorImages:VendorImages[]=[];
  vendorImageFiles:File[]=[];
  vendorVideos:VendorVideos[]=[];
  vendorVideoFiles:File[]=[];

  selectedCountry:CountryData|null=null;
  selectedCategory:CategoryData|null=null;
  isCapacityApplicable:boolean=false; 
  isAmenitiesApplicable:boolean=false;
  isHotelCategoryApplicable:boolean=false;
  selectedCurrencyData:CurrencyData|null=null;

  //---------- User id of creater---
  iduser:number=0;

  //------ Flag for customised vendor input ----------
  isHotel:boolean=false;
  isVenue:boolean=false;
  isPlanner:boolean=false;
  isCharter:boolean=false;
  isPhotographer:boolean=false;
  isDecorator:boolean=false;
  isCaterer:boolean=false;
  isMakeupartist:boolean=false;

  isOnPage:boolean=false;

  //--------Venue FAQ --------------------
  primaryVenueDescriptions:string[]=[
    '5 Star Hotel with indoor banquets','5 Star Hotel with indoor banquets & outdoor lawn','Hotel with indoor banquets & outdoor lawn','Hotel with indoor banquets','Standalone Banquet hall','Standalone Banquet hall with outdoor area',
    'Resort for destination wedding','Restaurant / Lounge for Pre wedding events','Fort / Palace venue','Cultural Center / Club with Banquet capability',
    'Farmhouse with only outdoor area','Farmhouse with Indoor Banquet capability'];
  venueTypeOptions:string[]=['Yes','No'];
  onsiteaccomodations:string[]=['Yes','No'];
  venueTypeCheckOptions = [
    { name: 'Option 1', value: 'Poolside' },
    { name: 'Option 2', value: 'Outdoor' },
    { name: 'Option 3', value: 'Indoor with Outdoor' },
    { name: 'Option 4', value: 'Indoor' },
    { name: 'Option 5', value: 'Terrace' }
  ];
  cateringPolicyOptions:string[]=[
    'In-house catering, outside vendors not permitted','In-house catering, outside vendors allowed',
    'No in-house service, outside vendors allowed from panel','No in-house services, outside vendors allowed'
    ];
  alchoholPolicyOptions:string[]=[
      'In house alcohol available, outside alcohol permitted','In house alcohol available, outside alcohol not permitted',
      'In house alcohol not available, outside alcohol permitted','In house alcohol not available, outside alcohol not permitted'
      ];
  djPolicyOptions:string[]=[
       'In house DJ available, Outside DJ permitted','In house DJ available, Outside DJ not permitted',
       'In house DJ not available, Outside DJ permitted','In house DJ not available, Outside DJ not permitted'
        ];
  decorPolicyOptions:string[]=[
       'Decorators should be chosen only from enlisted Panel','Outside decorators permitted',
       'In-house décor'
           ];
  smallGatheringsOptions:string[]=['Yes','No'];
  venueparkingOptions:string[]=['There is sufficient parking available','Parking is available near the venue','No parking available'];
  venue_cancellationpolicyuseroptions:string[]=['Partial Refund Offered','No Refund Offered','No Refund Offered However Date Adjustment Can Be Done','Full Refund Offered'];
  venue_cancellationpolicyvendoroptions:string[]=['Partial Refund Offered','No Refund Offered','No Refund Offered However Date Adjustment Can Be Done','Full Refund Offered'];        
  venue_allapplicableoptions:string[]=['Venue is Wheel chair friendly','Venue has sufficient parking available','Venue is veg only (does not serve non veg)','Venue has a beach available','Venue requires complete buyout of all rooms to host a wedding ','Venue can host events for <50 people '];
  //------------------- Hotel FAQ ----------------------------------------
yesNoArray:string[]=['Yes','No'];
smokingOptions:string[]=['We have only non-smoking rooms','We have smoking and non-smoking rooms'];
couplePolicyOptions:string[]=['We allow only wedded couples', 'We allow non-wedded couples with id proof'];


//------------------- Planner FAQ ----------------------------------------
plannerDecorPolicyOptions:string[]=[
  "In-house decor available, outside decor permitted",
  "In-house decor available, outside decor not permitted",
  "In-house decor not available, outside decor permitted"
]

//-------------- Photographer FAQ ------------------------------------------
ph_daysOptions:string[]=['1 day','2 days','3 days','4 days'];
ph_packageServices:string[]=['Photo','Photo + video','Photo + video+ pre-wedding'];
ph_cancellationUserOptions:string[]=['Partial refund Offered','No refund Offered','No refund offered however date adjustment can be done','Full refund offered'];
ph_cancellationSelfOptions:string[]=['Partial refund offered','No refund offered','Full refund offered']
ph_servicesList:string[]=['Candid Photography','Wedding Films','Traditional Photography','Pre-wedding shoots','Albums','Maternity Shoots','Fashion Shoots','Pre-wedding Films','Traditional Videography'];
ph_specialityList:string[]=['Candid Specialist','Traditional + Candid specialist'];
ph_specialitynum:string[]=['< 300','300 - 500','500 - 700','> 700'];
ph_paymentTermsList:string[]=['Upto 25% advance','Approx 50% advance while booking','100% advance while booking'];
ph_travelLodgingCostsList:string[]=['Cost of stay borne by client,travel borne by us','Cost of stay and travel borne by client'];


//-------------- Decor FAQ ------------------------------------------
dr_decorServiceTypeList:string[]=['Floral Decor','Mandap Decor','Stage Decor','Photobooth'];
dr_mostExperiencedList:string[]=['Celebrity Weddings','Destination Weddings','International Weddings','Intimate Weddings','Local Weddings','Eco Friendly Weddings'];
dr_cancellationUserOptions:string[]=['Partial refund Offered','No refund Offered','No refund offered however date adjustment can be done','Full refund offered'];
dr_cancellationSelfOptions:string[]=['Partial refund offered','No refund offered','Full refund offered']

//------------- Charter FAQ-------------------------------------------
ch_serviceTypeOptions:string[]=['Charter operators','Air charter broker','Jet card','Online market place'];
ch_rentalCostOptions:string[]=['Yes','No'];
ch_partialRoundsOptions:string[]=['Yes','No'];
ch_alcoholPolicyOptions:string[]=[
  "In-house alcohol available, outside alcohol permitted",
  "In-house alcohol available, outside alcohol not permitted",
  "In-house alcohol not available, outside alcohol permitted",
  "In-house alcohol not available, outside alcohol not permitted"
];
ch_registeredOptions:string[]=['Yes','No'];

//--------------- Makeup artists FAQ ----------------------------------
mk_travelLodgingCostsList:string[]=['Cost of stay borne by client,travel borne by us','Cost of stay and travel borne by client'];
mk_cancellationUserOptions:string[]=['Partial refund offered','No refund offered','No refund offered, date adjustment possible','Full refund offered'];
mk_cancellationSelfOptions:string[]=['Partial refund offered','No refund offered','Full refund offered'];
mk_servicesofferedOptions:string[]=['Bridal Makeup','Airbrush Makeup','Party Makeup (for family)','Engagement makeup','Extensions']
mk_travelOptions:string[]=['Yes','No'];
mk_bridalmakeupincludeoptions:string[]=['Hair Styling','Draping','Nail Polish Change','Makeup','Extensions','False Lashe'];
mk_airbrushservicesOptions:string[]=['Yes','No'];
mk_trialsPolicyOptions:string[]=['Free Trial offered','Paid Trial offered','Paid Trial offered- Money is adjusted if booking takes place','Trial not Available'];

 //------------ Inject Form Builder into the Constructor ----------------
   constructor(private router:Router,private fb: FormBuilder,private masterDataService:MasterdataserviceService,private environmentDataService:EnvironmentDataServiceService,private vendorDataService:VendorDataServiceService,public dialog: MatDialog)
   {
        this.vendorForm = this.fb.group({});
   }

   ngOnInit(): void 
   {

      this.environmentDataService.currentUser.subscribe(user=>
        {
          if (user)
          {
              this.iduser=user.iduser;
              this.isOnPage=true;
          }
          else
          {
            if(!this.isOnPage)
            {
               //alert('Log in dialog invoked from ngoninit');
              //-------- If not logged in, ask for user to log-in ------------------
              const dialogRef = this.dialog.open(SignInComponent, 
              {
                width: '300px'
              });
          
              dialogRef.afterClosed().subscribe(result => 
              {
                this.router.navigate(['/home']);
                console.log('The dialog was closed');
              });

            }
            
           

          }
        }
      )

      
      
      this.vendorForm = this.fb.group(
        {
          country: ['', Validators.required],
          category: ['', Validators.required],
          vendorName: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          //countryCode: ['', Validators.required],
          //phone: ['', [Validators.required, Validators.pattern('[0-9]{9,10,11}$')]],
          //addressLine1: ['', Validators.required],
          //serviceDescription: ['', [Validators.required, Validators.maxLength(5000)]],
          //price: ['', [Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]],
          countryCode: [0],
          price: ['', [Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]],
          pricebasis: [''],
          vendorrating: [''],
          capacity:['', [Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]],
          phone: [0],
          addressLine1: [''],
          serviceDescription: ['', [Validators.maxLength(5000)]],
          tagline: [''],
          currency: [''],
          city:[''],

          //--------------------Google map coordinates ------------------
          latitude:this.center.lat,
          longitude:this.center.lng,

          //-------- controls for venue faq-------------------------
          venue_primaryDescription:[],
          venue_venueType:[''],
          venue_startyear:[],
          venue_onsiteaccomodation:[],
          venue_vegStartPrice:[],
          venue_nonvegStartPrice:[],
          venue_venueTypeOptions: this.fb.array([]) ,
          venue_roomsavailable:[],
          venue_roomsprice:[],
          venue_cateringpolicy:[''],
          venue_alchoholpolicy:[''],
          venue_djpolicy:[''],
          venue_decorpolicy:[''],
          venue_decorprice:[],
          venue_smallgatherings:[],
          venue_parking:[],
          venue_USP:[],
          venue_weeksadvance:[],
          venue_cancellationpolicyuser:[],
          venue_cancellationpolicyvendor:[],
          venue_cancellationtnc:[],
          venue_allapplicable:this.fb.array([]),

          //-------- controls for hotel faq-------------------------
          couple_policy:[''],
          smoking_policy:[''],
          parking_available:[''],
          extra_beds:[''],
          days_cancellation:[''],
          pets_policy:[''],
          creditcards_policy:[''],
          otherpayment_methods:[''],

          //-------- controls for planner faq-------------------------
          planner_USP:[],
          planner_hotelslist:[],
          planner_cities:[],
          planner_prominentClients:[],
          planner_startingPackage:[],
          planner_commercialPlan :[],
          planner_commercialPlanPerc:[],
          planner_decorPolicy:[],

          //---------- controls for photographer faq ----------------------
          ph_mostBookedPackageValue:[],
          ph_mostBookedPackageDays:[],
          ph_mostBookedPackageServices:[],
          ph_cancellationPolicyUser:[],
          ph_cancellationPolicySelf:[],
          ph_cancellationPolicyTermsnConditions:[],
          ph_photographyDescription:[],
          ph_citiesNumber:[],
          ph_loveBecause:[],
          ph_smallEventprice:[],
          ph_advanceBookingWeeks:[],
          ph_startYear:[],
          ph_weeksToDeliver:[],
          ph_services:this.fb.array([]),
          ph_specialityType:[],
          ph_specialityNumber:[],
          ph_paymentTerms:[],
          ph_travelLodgingCosts:[],
          ph_candidTraditionalOneday:[],
          ph_candidTraditionalCinematic:[],

           //---------- controls for decor faq ----------------------
          dr_enlistedHotels:[],
          dr_serviceTypes:[],
          dr_indoorPriceRange:[],
          dr_outdoorPriceRange:[],
          dr_celebrityWeddings:[],
          dr_USP:[],
          dr_mostExperiencedIn:[],
          dr_cancellationPolicyUser:[],
          dr_cancellationPolicySelf:[],
          dr_cancelationTermsnConsitions:[],
          dr_startYear:[],
          dr_advanceBookingWeeks:[],
          dr_indoorStartingPrice:[],
          dr_homefunctionStartingPrice:[],

          //----------controls for charter --------------------
          ch_primaryServiceType:[],
          ch_rentalCostYesNo:[],
          ch_startYear:[],
          ch_aircraftTypes:[],
          ch_startingPrice:[],
          ch_startingPriceBookingnTechnician:[],
          ch_partialRoundsYesNo:[],
          ch_alcoholPolicy:[],
          ch_recognitions:[],
          ch_registeredYesNo:[],

          //----------controls for makeup artists --------------------
          mk_hotelspanels:[],
          mk_usp:[],
          mk_startyear:[],
          mk_cities:[],
          mk_prominentclients:[],
          mk_startingrate:[],
          mk_addguests:[],
          mk_costpolicy:[],
          mk_daysadvance:[],
          mk_cancellationuser:[],
          mk_cancellationvendor:[],
          mk_servicesoffered:this.fb.array([]),
          mk_products:[],
          mk_traveltovenue:[],
          mk_travelprice:[],
          mk_priceincludes:this.fb.array([]),
          mk_airbrushservice:[],
          mk_trialspolicy:[],
          mk_awards:[]

          


        });

        
    // Simulate fetching data
    this.loadDropdownData();
   }

  setCurrency(currency: string): void 
  {
        this.selectedCurrency = currency;
  }

  resetForm() 
  {
    console.log('Reset form called')
    this.vendorForm.reset();

    //--------- Update currency after reset to avoid NULL currency code insertion ----------
    this.environmentDataService.currentCurrency.subscribe(data=>
      {
          this.selectedCurrencyData=data;
          this.vendorForm.get('currency')?.setValue(this.selectedCurrencyData?.currencycode);
      });
  }




  loadDropdownData()
  {
    // Simulate data fetching here
   //this.countries = this.masterDataService.getCountries();

   
   this.masterDataService.getCountries().subscribe
   (
     data=>this.countries=data
   );


   this.masterDataService.getCategories().subscribe
   (
    data=>this.categories=data
   );


   this.vendorAmenities=this.masterDataService.getVendorAmenitiesMasterList();

   this.phoneCountryCodes=this.masterDataService.getCountryPhoneCodes();

   this.environmentDataService.currentCurrency.subscribe(data=>
    {
        this.selectedCurrencyData=data;
        this.vendorForm.get('currency')?.setValue(this.selectedCurrencyData?.currencycode);
    })

  

   console.log('Called loadDropdownData');
   console.log ('Countries array length :',this.countries.length)

  }

  updateAvailability(index: number): void 
  {
    console.log("UpdateAvailability called for :",this.vendorAmenities[index].amenityName)
    this.vendorAmenities[index].available = !this.vendorAmenities[index].available;
    console.log("Availability changed to :",this.vendorAmenities[index].available)
  }

  onSubmit()
  {

    //console.log('Form Submitted', this.vendorForm.value);

    if (this.vendorForm.valid) 
    {

      
      console.log('Form Submitted', this.vendorForm.value);
      // Here you would typically send the data to the server

      const vendorDetails=this.createVendorDetails();

      console.log("Vendor Details : " , vendorDetails);

      //--------- Call the service to add vendor -----------------
      //--------- Call the service to add vendor -----------------
      this.vendorDataService.addVendor(vendorDetails,this.vendorImageFiles,this.vendorVideoFiles);

     // this.vendorDataService.uploadVendorImages(14,this.vendorImageFiles);
      //this.vendorDataService.uploadVendorVideos(14,this.vendorVideoFiles)

      
      this.resetForm();
      this.vendorImages=[];
      this.vendorVideos=[];
      this.vendorAmenities=[];
      this.vendorImageFiles=[];
      this.vendorVideoFiles=[];


    } else
    {
      console.log('Form is not valid');

      alert('Please enter all mandatory fields in the right format')

      console.log(this.vendorForm?.get('country')?.errors);
      console.log(this.vendorForm?.get('category')?.errors);
      console.log(this.vendorForm?.get('vendorName')?.errors);
      console.log(this.vendorForm?.get('email')?.errors);
      console.log(this.vendorForm?.get('countryCode')?.errors);
      console.log(this.vendorForm?.get('phone')?.errors);
      console.log(this.vendorForm?.get('addressLine1')?.errors);
      console.log(this.vendorForm?.get('addressLine2')?.errors);
      console.log(this.vendorForm?.get('addressLine3')?.errors);
      console.log(this.vendorForm?.get('serviceDescription')?.errors);
      console.log(this.vendorForm?.get('tagline')?.errors);
      console.log(this.vendorForm?.get('currency')?.errors);
      console.log(this.vendorForm?.get('price')?.errors);
      console.log(this.vendorForm?.get('priceBasis')?.errors);
      

    
  
  
    }
  }

  createVendorDetails():VendorDetails
  {
    if(!this.isAmenitiesApplicable)
    {
        this.vendorAmenities=[];
    }

    return<VendorDetails>
    {
        idvendor:10,
        vendorSummary:
        {
          idvendor:10,
          idcategory :this.vendorForm.get('category')?.value,
          idcountry: this.vendorForm.get('country')?.value,
          cityname: this.vendorForm.get('addressLine1')?.value,
          vendorname: this.vendorForm.get('vendorName')?.value,
          currency : this.vendorForm.get('currency')?.value,
          startingprice: this.vendorForm.get('price')?.value,
          pricebasis: this.vendorForm.get('pricebasis')?.value,
          discountedprice:this.vendorForm.get('price')?.value,
          capacity:this.vendorForm.get('capacity')?.value,
          email:this.vendorForm.get('email')?.value,
          phonecountrycode:this.vendorForm.get('countryCode')?.value,
          phonenumber:this.vendorForm.get('phone')?.value,
          vendorrating:this.vendorForm.get('vendorrating')?.value, 
          description: this.vendorForm.get('serviceDescription')?.value,
          reviewrating: 0,
          imagespath:'',
          videospath:'',
          test:false,
          active :true,
          iduser:this.iduser,
          city:this.vendorForm.get('city')?.value,
          latitude:this.center.lat,
          longitude:this.center.lng,
          countryname:this.selectedCountry?.countryName,
          ctrylat:this.selectedCountry?.latitude,
          ctrylng:this.selectedCountry?.longitude
        },
        vendorImages:this.vendorImages,
        vendorVideos:this.vendorVideos,
        vendorReviews:{},
        vendorAmenities:this.vendorAmenities,
        venueFaq:
        {
          venue_primaryDescription:this.vendorForm.get('venue_primaryDescription')?.value,
          venue_venueType:this.vendorForm.get('venue_venueType')?.value,
          venue_startyear:this.vendorForm.get('venue_startyear')?.value,
          venue_onsiteaccomodation:this.vendorForm.get('venue_onsiteaccomodation')?.value,
          venue_vegStartPrice:this.vendorForm.get('venue_vegStartPrice')?.value,
          venue_nonvegStartPrice:this.vendorForm.get('venue_nonvegStartPrice')?.value,
          venue_venueTypeOptions: this.vendorForm.get('venue_venueTypeOptions')?.value.join(';') ,
          venue_roomsavailable:this.vendorForm.get('venue_roomsavailable')?.value,
          venue_roomsprice:this.vendorForm.get('venue_roomsprice')?.value,
          venue_cateringpolicy:this.vendorForm.get('venue_cateringpolicy')?.value,
          venue_alchoholpolicy:this.vendorForm.get('venue_alchoholpolicy')?.value,
          venue_djpolicy:this.vendorForm.get('venue_djpolicy')?.value,
          venue_decorpolicy:this.vendorForm.get('venue_decorpolicy')?.value,
          venue_decorprice:this.vendorForm.get('venue_decorprice')?.value,
          venue_smallgatherings:this.vendorForm.get('venue_smallgatherings')?.value,
          venue_parking:this.vendorForm.get('venue_parking')?.value,
          venue_USP:this.vendorForm.get('venue_USP')?.value,
          venue_weeksadvance:this.vendorForm.get('venue_weeksadvance')?.value,
          venue_cancellationpolicyuser:this.vendorForm.get('venue_cancellationpolicyuser')?.value,
          venue_cancellationpolicyvendor:this.vendorForm.get('venue_cancellationpolicyvendor')?.value,
          venue_cancellationtnc:this.vendorForm.get('venue_cancellationtnc')?.value,
          venue_allapplicable: this.vendorForm.get('venue_allapplicable')?.value.join(';') ,
        },
        hotelsFaq:
        {
          couple_policy:this.vendorForm.get('couple_policy')?.value,
          smoking_policy:this.vendorForm.get('smoking_policy')?.value,
          parking_available:this.vendorForm.get('parking_available')?.value,
          extra_beds:this.vendorForm.get('extra_beds')?.value,
          days_cancellation:this.vendorForm.get('days_cancellation')?.value,
          pets_policy:this.vendorForm.get('pets_policy')?.value,
          creditcards_policy:this.vendorForm.get('creditcards_policy')?.value,
          otherpayment_methods:this.vendorForm.get('otherpayment_methods')?.value
        },
        plannerFaq:
        {
          planner_USP:this.vendorForm.get('planner_USP')?.value,
          planner_hotelslist:this.vendorForm.get('planner_hotelslist')?.value,
          planner_cities:this.vendorForm.get('planner_cities')?.value,
          planner_prominentClients:this.vendorForm.get('planner_prominentClients')?.value,
          planner_startingPackage:this.vendorForm.get('planner_startingPackage')?.value,
          planner_commercialPlan :this.vendorForm.get('planner_commercialPlan')?.value,
          planner_commercialPlanPerc:this.vendorForm.get('planner_commercialPlanPerc')?.value,
          planner_decorPolicy:this.vendorForm.get('planner_decorPolicy')?.value
        },
        photographerFaq:
        {
          ph_mostBookedPackageValue:this.vendorForm.get('ph_mostBookedPackageValue')?.value,
          ph_mostBookedPackageDays:this.vendorForm.get('ph_mostBookedPackageDays')?.value,
          ph_mostBookedPackageServices:this.vendorForm.get('ph_mostBookedPackageServices')?.value,
          ph_cancellationPolicyUser:this.vendorForm.get('ph_cancellationPolicyUser')?.value,
          ph_cancellationPolicySelf:this.vendorForm.get('ph_cancellationPolicySelf')?.value,
          ph_cancellationPolicyTermsnConditions:this.vendorForm.get('ph_cancellationPolicyTermsnConditions')?.value,
          ph_photographyDescription:this.vendorForm.get('ph_photographyDescription')?.value,
          ph_citiesNumber:this.vendorForm.get('ph_citiesNumber')?.value,
          ph_loveBecause:this.vendorForm.get('ph_loveBecause')?.value,
          ph_smallEventprice:this.vendorForm.get('ph_smallEventprice')?.value,
          ph_advanceBookingWeeks:this.vendorForm.get('ph_advanceBookingWeeks')?.value,
          ph_startYear:this.vendorForm.get('ph_startYear')?.value,
          ph_weeksToDeliver:this.vendorForm.get('ph_weeksToDeliver')?.value,
          ph_services: this.vendorForm.get('ph_services')?.value.join(';') ,
          ph_specialityType:this.vendorForm.get('ph_specialityType')?.value,
          ph_specialityNumber:this.vendorForm.get('ph_specialityNumber')?.value,
          ph_paymentTerms:this.vendorForm.get('ph_paymentTerms')?.value,
          ph_travelLodgingCosts:this.vendorForm.get('ph_travelLodgingCosts')?.value,
          ph_candidTraditionalOneday:this.vendorForm.get('ph_candidTraditionalOneday')?.value,
          ph_candidTraditionalCinematic:this.vendorForm.get('ph_candidTraditionalCinematic')?.value
        },
        decorFaq:
        {
          dr_enlistedHotels:this.vendorForm.get('dr_enlistedHotels')?.value,
          dr_serviceTypes:this.vendorForm.get('dr_serviceTypes')?.value,
          dr_indoorPriceRange:this.vendorForm.get('dr_indoorPriceRange')?.value,
          dr_outdoorPriceRange:this.vendorForm.get('dr_outdoorPriceRange')?.value,
          dr_celebrityWeddings:this.vendorForm.get('dr_celebrityWeddings')?.value,
          dr_USP:this.vendorForm.get('dr_USP')?.value,
          dr_mostExperiencedIn:this.vendorForm.get('dr_mostExperiencedIn')?.value,
          dr_cancellationPolicyUser:this.vendorForm.get('dr_cancellationPolicyUser')?.value,
          dr_cancellationPolicySelf:this.vendorForm.get('dr_cancellationPolicySelf')?.value,
          dr_cancelationTermsnConsitions:this.vendorForm.get('dr_cancelationTermsnConsitions')?.value,
          dr_startYear:this.vendorForm.get('dr_startYear')?.value,
          dr_advanceBookingWeeks:this.vendorForm.get('dr_advanceBookingWeeks')?.value,
          dr_indoorStartingPrice:this.vendorForm.get('dr_indoorStartingPrice')?.value,
          dr_homefunctionStartingPrice:this.vendorForm.get('dr_homefunctionStartingPrice')?.value
        },
        charterFaq:
        {
          ch_primaryServiceType:this.vendorForm.get('ch_primaryServiceType')?.value,
          ch_rentalCostYesNo:this.vendorForm.get('ch_rentalCostYesNo')?.value,
          ch_startYear:this.vendorForm.get('ch_startYear')?.value,
          ch_aircraftTypes:this.vendorForm.get('ch_aircraftTypes')?.value,
          ch_startingPrice:this.vendorForm.get('ch_startingPrice')?.value,
          ch_startingPriceBookingnTechnician:this.vendorForm.get('ch_startingPriceBookingnTechnician')?.value,
          ch_partialRoundsYesNo:this.vendorForm.get('ch_partialRoundsYesNo')?.value,
          ch_alcoholPolicy:this.vendorForm.get('ch_alcoholPolicy')?.value,
          ch_recognitions:this.vendorForm.get('ch_recognitions')?.value,
          ch_registeredYesNo:this.vendorForm.get('ch_registeredYesNo')?.value
        },
        makeupartistFaq:
        {
          mk_hotelspanels:this.vendorForm.get('mk_hotelspanels')?.value,
          mk_usp:this.vendorForm.get('mk_usp')?.value,
          mk_startyear:this.vendorForm.get('mk_startyear')?.value,
          mk_cities:this.vendorForm.get('mk_cities')?.value,
          mk_prominentclients:this.vendorForm.get('mk_prominentclients')?.value,
          mk_startingrate:this.vendorForm.get('mk_startingrate')?.value,
          mk_addguests:this.vendorForm.get('mk_addguests')?.value,
          mk_costpolicy:this.vendorForm.get('mk_costpolicy')?.value,
          mk_daysadvance:this.vendorForm.get('mk_daysadvance')?.value,
          mk_cancellationuser:this.vendorForm.get('mk_cancellationuser')?.value,
          mk_cancellationvendor:this.vendorForm.get('mk_cancellationvendor')?.value,
          mk_servicesoffered:this.vendorForm.get('mk_servicesoffered')?.value.join(';') ,
          mk_products:this.vendorForm.get('mk_products')?.value,
          mk_traveltovenue:this.vendorForm.get('mk_traveltovenue')?.value,
          mk_travelprice:this.vendorForm.get('mk_travelprice')?.value,
          mk_priceincludes:this.vendorForm.get('mk_priceincludes')?.value.join(';') ,
          mk_airbrushservice:this.vendorForm.get('mk_airbrushservice')?.value,
          mk_trialspolicy:this.vendorForm.get('mk_trialspolicy')?.value,
          mk_awards:this.vendorForm.get('mk_awards')?.value

          
        }
    }
  }


  onBlur(field: string) 
  {
    const control = this.vendorForm.get(field);

    if (control) 
    {
      control.markAsTouched();
      console.log(field,' marked as touched')
    }
  }

  //-------------- Code for image upload -------------------------
  onImageFileSelected(event: any): void 
  {
      const files: FileList = event.target.files;

     /* if (files.length > 6) 
      {
        alert('You can only upload a maximum of 6 images');
        return;
      }*/

     
      for (let i = 0; i < files.length; i++) 
      {
        this.vendorImageFiles.push(files[i])
        this.vendorImages.push({idvendorimages:0,idvendor:0,imagename:files[i].name});
      }

      
   }

    
    //------------- Code for video upload -----------------------

    onVideoSelected(event: any): void 
    {
      const file: File = event.target.files[0];

      if (file.type.split('/')[0] !== 'video') 
      {
        alert('Please select a valid video file');
        return;
      }

      
      this.vendorVideos.push({idvendorvideos:0,idvendor:0,videoname:file.name});
      this.vendorVideoFiles.push(file)

    }
  

  //============== Function to updateselected country ============================
  onCountrySelect(event:any): void 
  {
    console.log(this.vendorForm.get('country')?.value);

   this.selectedCountry = this.countries.filter(country=>country.idcountry==this.vendorForm.get('country')?.value)[0];

   console.log("country name is :",this.selectedCountry.countryName)

   // You can also call other functions here or perform further actions based on the selected country

    this.environmentDataService.changeCountryData(this.selectedCountry);

  
    this.center=
    {
      lat: this.selectedCountry.latitude,
      lng: this.selectedCountry.longitude
    }

    console.log("New center:",this.center);
    

    //--------POpulate city list for this coountry --------------
    this.masterDataService.getCitiesforCountry(this.selectedCountry.idcountry).subscribe(data=>
    {
      this.cities=data;
      
    })
  }

  
  
  //============== Function to updateselected category ============================
  onCategorySelect(event:any): void 
  {
    this.selectedCategory = this.categories.filter(category=>category.idcategory==this.vendorForm.get('category')?.value)[0];

    console.log("selected category is",this.selectedCategory.categoryName);

    this.isHotel=false;
    this.isVenue=false;
    this.isPlanner=false;
    this.isCharter=false;
    this.isPhotographer=false;
    this.isDecorator=false;
    this.isCaterer=false;
    this.isMakeupartist=false;

//----------- mark the correct category ------------------

    if(this.selectedCategory.idcategory==3)
      this.isVenue=true;
    else if(this.selectedCategory.idcategory==4)
      this.isHotel=true;
    else if(this.selectedCategory.idcategory==5)
      this.isPlanner=true;
    else if(this.selectedCategory.idcategory==6)
      this.isCaterer=true;
    else if(this.selectedCategory.idcategory==7)
      this.isPhotographer=true;
    else if(this.selectedCategory.idcategory==8)
      this.isDecorator=true
    else if(this.selectedCategory.idcategory==9)
      this.isCharter=true;
    else if(this.selectedCategory.idcategory==10)
      this.isMakeupartist=true;


    //--------- Check if capacity is applicable for this category ----------------
    if(this.selectedCategory.idcategory==3)
    {
      this.isCapacityApplicable=true
    }
    else
    {
      this.isCapacityApplicable=false
      this.vendorForm.get('capacity')?.setValue(0);
    }

     //--------- Check if amenities is applicable for this category ----------------
     if(this.selectedCategory.idcategory==3 || this.selectedCategory.idcategory==4)
      {
        this.isAmenitiesApplicable=true;
        this.vendorAmenities=this.masterDataService.getVendorAmenitiesMasterList();
      }
      else
      {
        this.isAmenitiesApplicable=false;
      }

      //--------- Check if hotelrating is applicable for this category ----------------
     if(this.selectedCategory.idcategory==3 || this.selectedCategory.idcategory==4)
      {
        this.isHotelCategoryApplicable=true;
      }
      else
      {
        this.isHotelCategoryApplicable=false;
      }


  }

   
  
  //------------Define getters for the form fields ----------------

  get vendorName() 
  {
    return this.vendorForm.get('vendorName');
  }

  get email() 
  {
     return this.vendorForm.get('email');
  }

  get phone() 
  {
     return this.vendorForm.get('phone');
  }

  get addressLine1()
  {
     return this.vendorForm.get('addressLine1')
  }

  get serviceDescription()
  {
    return this.vendorForm.get('serviceDescription')
  }

  get price()
  {
    return this.vendorForm.get('price');
  }

  get countryCode()
  {
    return this.vendorForm.get('countryCode');
  }

  get city()
  {
    return this.vendorForm.get('city');
  }

  get vendorrating()
  {
    return this.vendorForm.get('vendorrating');
  }

  
  get priceBasis()
  {
    return this.vendorForm.get('priceBasis');
  }

  
  get capacity()
  {
    return this.vendorForm.get('capacity');
  }

  get venue_primaryDescription()
  {
    return this.vendorForm.get('venue_primaryDescription');
  }

  get venue_venueType()
  {
    return this.vendorForm.get('venue_venueType');
  }

  get venue_startyear()
  {
    return this.vendorForm.get('venue_startyear');
  }

  get venue_onsiteaccomodation()
  {
    return this.vendorForm.get('venue_onsiteaccomodation');
  }

  get venue_vegStartPrice()
  {
    return this.vendorForm.get('venue_vegStartPrice');
  }

  get venue_nonvegStartPrice()
  {
    return this.vendorForm.get('venue_nonvegStartPrice');
  }

  onCheckboxChange(e: any) 
  {
    const venue_venueTypeOptions: FormArray = this.vendorForm.get('venue_venueTypeOptions') as FormArray;

    if (e.target.checked) 
    {
        venue_venueTypeOptions.push(this.fb.control(e.target.value));
    } 
    else 
    {
      const index = venue_venueTypeOptions.controls.findIndex(x => x.value === e.target.value);
      venue_venueTypeOptions.removeAt(index);
    }
  }

  

  get venue_venueTypeOptions() 
  {
    return this.vendorForm.get('venue_venueTypeOptions');
    
  }

  get venue_cateringpolicy()
  {
    return this.vendorForm.get('venue_cateringpolicy');
  }

  get venue_alchoholpolicy()
  {
    return this.vendorForm.get('venue_alchoholpolicy');
  }

  get venue_djpolicy()
  {
    return this.vendorForm.get('venue_djpolicy')
  }

  get venue_decorpolicy()
  {
    return this.vendorForm.get('venue_decorpolicy')
  }

  get venue_decorprice()
  {
    return this.vendorForm.get('venue_decorprice');
  }

 

  get venue_smallgatherings()
  {
     return this.vendorForm.get('venue_smallgatherings');
  } 
  
  get venue_parking()
  {
     return this.vendorForm.get('venue_parking');
  } 

  get venue_USP()
  {
     return this.vendorForm.get('venue_USP');
  } 

  get venue_weeksadvance()
  {
     return this.vendorForm.get('venue_weeksadvance');
  } 

  get venue_cancellationpolicyuser()
  {
     return this.vendorForm.get('venue_cancellationpolicyuser');
  } 

  get venue_cancellationpolicyvendor()
  {
     return this.vendorForm.get('venue_cancellationpolicyvendor');
  } 

  get venue_cancellationtnc()
  {
     return this.vendorForm.get('venue_cancellationtnc');
  }

  onAllApplicableCheckboxChange(e: any) 
  {
    const venue_allapplicable: FormArray = this.vendorForm.get('venue_allapplicable') as FormArray;

    if (e.target.checked) 
    {
      venue_allapplicable.push(this.fb.control(e.target.value));
    } 
    else 
    {
      const index = venue_allapplicable.controls.findIndex(x => x.value === e.target.value);
      venue_allapplicable.removeAt(index);
    }
  }
  
  get venue_allapplicable()
  {
     return this.vendorForm.get('venue_allapplicable');
  }

  //-------------- planner variables -------------------

  get planner_USP()
  {
    return this.vendorForm.get('planner_USP');
  }

  get planner_hotelslist()
  {
    return this.vendorForm.get('planner_hotelslist');
  }

  get planner_cities()
  {
    return this.vendorForm.get('planner_cities');
  }

  get planner_prominentClients()
  {
    return this.vendorForm.get('planner_prominentClients');
  }

  get planner_startingPackage()
  {
    return this.vendorForm.get('planner_startingPackage');
  }

  get planner_commercialPlan()
  {
    return this.vendorForm.get('planner_commercialPlan');
  }

  get planner_commercialPlanPerc()
  {
    return this.vendorForm.get('planner_commercialPlanPerc');
  }

  get planner_decorPolicy()
  {
    return this.vendorForm.get('planner_decorPolicy');
  }

//-------------------- Hotels variables ------------------------
  get couple_policy()
  {
    return this.vendorForm.get('couple_policy');
  }

  get extra_beds()
  {
    return this.vendorForm.get('extra_beds');
  }

  get smoking_policy()
  {
    return this.vendorForm.get('smoking_policy');
  }

  get parking_available()
  {
    return this.vendorForm.get('parking_available');
  }

  get pets_policy()
  {
    return this.vendorForm.get('pets_policy');
  }

  get creditcards_policy()
  {
    return this.vendorForm.get('creditcards_policy');
  }

  get otherpayment_methods()
  {
    return this.vendorForm.get('otherpayment_methods');
  }

  get days_cancellation()
  {
    return this.vendorForm.get('days_cancellation');
  }


  //-----------photographer faq ------------------------------
  get ph_mostBookedPackageValue()
  {
    return this.vendorForm.get('ph_mostBookedPackageValue');
  }

  get ph_mostBookedPackageDays()
  {
    return this.vendorForm.get('ph_mostBookedPackageDays');
  }

  get ph_mostBookedPackageServices()
  {
    return this.vendorForm.get('ph_mostBookedPackageServices');
  }

  get ph_cancellationPolicyUser()
  {
    return this.vendorForm.get('ph_cancellationPolicyUser');
  }

  get ph_cancellationPolicySelf()
  {
    return this.vendorForm.get('ph_cancellationPolicySelf');
  }

  get ph_cancellationPolicyTermsnConditions()
  {
    return this.vendorForm.get('ph_cancellationPolicyTermsnConditions');
  }

  get ph_photographyDescription()
  {
    return this.vendorForm.get('ph_photographyDescription');
  }

  get ph_citiesNumber()
  {
    return this.vendorForm.get('ph_citiesNumber');
  }
  
  get ph_loveBecause()
  {
    return this.vendorForm.get('ph_loveBecause');
  }

  get ph_smallEventprice()
  {
    return this.vendorForm.get('ph_smallEventprice');
  }

  get ph_advanceBookingWeeks()
  {
    return this.vendorForm.get('ph_advanceBookingWeeks');
  }

  get ph_startYear()
  {
    return this.vendorForm.get('ph_startYear');
  }
  
  get ph_weeksToDeliver()
  {
    return this.vendorForm.get('ph_weeksToDeliver');
  }

  get ph_services()
  {
    return this.vendorForm.get('ph_services');
  }

  onPhCheckboxChange(e: any) 
  {
    const ph_services: FormArray = this.vendorForm.get('ph_services') as FormArray;

    if (e.target.checked) 
    {
      ph_services.push(this.fb.control(e.target.value));
    } 
    else 
    {
      const index = ph_services.controls.findIndex(x => x.value === e.target.value);
      ph_services.removeAt(index);
    }
  }


  get ph_specialityType()
  {
    return this.vendorForm.get('ph_specialityType');
  }

  get ph_specialityNumber()
  {
    return this.vendorForm.get('ph_specialityNumber');
  }

  get ph_paymentTerms()
  {
    return this.vendorForm.get('ph_paymentTerms');
  }

  get ph_travelLodgingCosts()
  {
    return this.vendorForm.get('ph_travelLodgingCosts');
  }

  get ph_candidTraditionalOneday()
  {
    return this.vendorForm.get('ph_candidTraditionalOneday');
  }

  get ph_candidTraditionalCinematic()
  {
    return this.vendorForm.get('ph_candidTraditionalCinematic');
  }

  //-----------Decor variables -----------
  
  get dr_enlistedHotels()
  {
    return this.vendorForm.get('dr_enlistedHotels');
  }

  get dr_serviceTypes()
  {
    return this.vendorForm.get('dr_serviceTypes');
  }

  get dr_indoorPriceRange()
  {
    return this.vendorForm.get('dr_indoorPriceRange');
  }

  get dr_outdoorPriceRange()
  {
    return this.vendorForm.get('dr_outdoorPriceRange');
  }

  get dr_celebrityWeddings()
  {
    return this.vendorForm.get('dr_celebrityWeddings');
  }

  get dr_USP()
  {
    return this.vendorForm.get('dr_USP');
  }

  get dr_mostExperiencedIn()
  {
    return this.vendorForm.get('dr_mostExperiencedIn');
  }

  get dr_cancellationPolicyUser()
  {
    return this.vendorForm.get('dr_cancellationPolicyUser');
  }

  get dr_cancellationPolicySelf()
  {
    return this.vendorForm.get('dr_cancellationPolicySelf');
  }

 get dr_cancelationTermsnConsitions()
  {
    return this.vendorForm.get('dr_cancelationTermsnConsitions');
  }

  get dr_startYear()
  {
    return this.vendorForm.get('dr_startYear');
  }

  get dr_advanceBookingWeeks()
  {
    return this.vendorForm.get('dr_advanceBookingWeeks');
  }

  get dr_indoorStartingPrice()
  {
    return this.vendorForm.get('dr_indoorStartingPrice');
  }

  get dr_homefunctionStartingPrice()
  {
    return this.vendorForm.get('dr_homefunctionStartingPrice');
  }

 //---------- Charter Faq ----------------------------------

 get ch_primaryServiceType()
  {
    return this.vendorForm.get('ch_primaryServiceType');
  }

  get ch_rentalCostYesNo()
  {
    return this.vendorForm.get('ch_rentalCostYesNo');
  }

  get ch_startYear()
  {
    return this.vendorForm.get('ch_startYear');
  }

  get ch_aircraftTypes()
  {
    return this.vendorForm.get('ch_aircraftTypes');
  }

  get ch_startingPrice()
  {
    return this.vendorForm.get('ch_startingPrice');
  }

  get ch_startingPriceBookingnTechnician()
  {
    return this.vendorForm.get('ch_startingPriceBookingnTechnician');
  }

  get ch_partialRoundsYesNo()
  {
    return this.vendorForm.get('ch_partialRoundsYesNo');
  }

  get ch_alcoholPolicy()
  {
    return this.vendorForm.get('ch_alcoholPolicy');
  }

  get ch_recognitions()
  {
    return this.vendorForm.get('ch_recognitions');
  }

 get ch_registeredYesNo()
  {
    return this.vendorForm.get('ch_registeredYesNo');
  }

  get mk_hotelspanels()
  {
    return this.vendorForm.get('mk_hotelspanels');
  }
  
  get mk_usp()
  {
    return this.vendorForm.get('mk_usp');
  }

  get mk_startyear()
  {
    return this.vendorForm.get('mk_startyear');
  }

  get mk_cities()
  {
    return this.vendorForm.get('mk_cities');
  }

  get mk_prominentclients()
  {
    return this.vendorForm.get('mk_prominentclients');
  }


  get mk_startingrate()
  {
    return this.vendorForm.get('mk_startingrate');
  }

  get mk_addguests()
  {
    return this.vendorForm.get('mk_addguests');
  }

  get mk_costpolicy()
  {
    return this.vendorForm.get('mk_costpolicy');
  }

  get mk_daysadvance()
  {
    return this.vendorForm.get('mk_daysadvance');
  }

  get mk_cancellationuser()
  {
    return this.vendorForm.get('mk_cancellationuser');
  }

  get mk_cancellationvendor()
  {
    return this.vendorForm.get('mk_cancellationuser');
  }

   
  get mk_servicesoffered()
  {
    return this.vendorForm.get('mk_servicesoffered');
  }

  onmkservicesCheckboxChange(e: any) 
  {
    const mk_servicesoffered: FormArray = this.vendorForm.get('mk_servicesoffered') as FormArray;

    if (e.target.checked) 
    {
      mk_servicesoffered.push(this.fb.control(e.target.value));
    } 
    else 
    {
      const index = mk_servicesoffered.controls.findIndex(x => x.value === e.target.value);
      mk_servicesoffered.removeAt(index);
    }
  }

  get mk_products()
  {
    return this.vendorForm.get('mk_products');
  }

  


  get mk_traveltovenue()
  {
    return this.vendorForm.get('mk_traveltovenue');
  }

  get mk_travelprice()
  {
    return this.vendorForm.get('mk_travelprice');
  }

  get mk_priceincludes()
  {
    return this.vendorForm.get('mk_priceincludes');
  }

  onmkpriceinclCheckboxChange(e: any) 
  {
    const mk_priceincludes: FormArray = this.vendorForm.get('mk_priceincludes') as FormArray;

    if (e.target.checked) 
    {
      mk_priceincludes.push(this.fb.control(e.target.value));
    } 
    else 
    {
      const index = mk_priceincludes.controls.findIndex(x => x.value === e.target.value);
      mk_priceincludes.removeAt(index);
    }
  }

  get mk_airbrushservice()
  {
    return this.vendorForm.get('mk_airbrushservice');
  }

  get mk_trialspolicy()
  {
    return this.vendorForm.get('mk_trialspolicy');
  }

  get mk_awards()
  {
    return this.vendorForm.get('mk_awards');
  }
          

  moveMap(event: google.maps.MapMouseEvent) 
  {
    this.locationChosen=true;

    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) 
  {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }
 
}
