<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

<div class="container-fluid ">
    
    <!--
    <div class="row pt-3">
        <div class="col-md-12">
            <span class="flex justify-center soulsWedHeadingFont">Featured Hotels</span>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12 pt-3">
            <span class="soulsWedSubheadingFont flex justify-center ">Highly rated hotels with impeccable service</span>
        </div>
    </div>

-->

    <div class="row py-2 position-relative m-2" style="border-radius: 30px;" >        

        <app-search></app-search>
        
     </div>


    

    

 </div>

       